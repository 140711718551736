import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
	Col,
	// Divider,
	Input,
	Row,
	Select,
	Form,
	Divider,
	Button,
	Tooltip,
} from 'antd';
import moment from 'moment';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import AddInvoicePresentational from './add-invoice-presentational';
import { DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { getTheRoundOffValue } from 'helpers';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { API_STATUS } from 'constants/app-constants';
import AddItem from 'pages/items/add-item';
import { sendGetRequest } from 'redux/sagas/utils';

const itemDefaultRecord = {
	itemId: '',
	qty: 0,
	rate: 0,
	discount: 0,
	discountType: 'PERCENT',
	tax: 0,
	description: '',
	totalAmount: 0,
	id: uuidv4(),
	// index: 0,
};
// eslint-disable-next-line
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const calculateTaxRateTotalAmount = (record) => {
	let amount = 0;
	const total = parseFloat(record?.qty || 0) * parseFloat(record?.rate || 0);
	if (record?.discountType === 'PERCENT') {
		amount = total - total * ((record?.discount || 0) / 100);
	}
	if (record?.discountType === 'AMOUNT') {
		amount = total - (record?.discount || 0);
	}
	const taxRate = record?.tax || 0;
	const taxAmount = total * (taxRate / 100);
	const totalAmount = amount - taxAmount;
	return {
		taxRate,
		taxAmount,
		cgstRate: taxRate / 2,
		cgstAmount: taxAmount / 2,
		sgstRate: taxRate / 2,
		sgstAmount: taxAmount / 2,
		igstRate: 0,
		igstAmount: 0,
		totalAmount,
	};
};

const AddInvoiceFunctional = () => {
	const [tableData, setTableData] = useState([itemDefaultRecord]);
	const [selectedData, setSelectedData] = useState(null);
	const [invoiceInputDetails, setInvoiceInputDetails] = useState({
		shippingCharges: 0,
	});
	const [customerAddModal, setCustomerAddModal] = useState(false);
	const [invoiceDetails, setInvoiceDetails] = useState({
		data: null,
		loading: false,
	});
	const [itemAddModal, setItemAddModal] = useState(false);
	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const items = useSelector((state) => state.itemRedux?.items || []);
	const globalRedux = useSelector((state) => state.globalRedux);
	const projects = useSelector((state) => state?.projectRedux?.projects);
	const customers = useSelector((state) => state.customerRedux?.customers || []);
	const salesPersons = useSelector((state) => state?.globalRedux.salesPersons);
	const [form] = Form.useForm();
	const customerIdValue = Form.useWatch('customerId', form);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let { invoiceId } = useParams();

	const getInvoiceDetails = useCallback(
		async (invoiceId) => {
			await setInvoiceDetails({
				data: null,
				loading: true,
			});
			const data = await sendGetRequest(null, `${SERVER_IP}invoice/${invoiceId}?orgId=${globalRedux?.selectedOrganization?._id}`);
			await setInvoiceDetails({
				data: data?.data,
				loading: false,
			});
		},
		[globalRedux?.selectedOrganization?._id]
	);

	useEffect(() => {
		if (invoiceId) {
			getInvoiceDetails(invoiceId);
		}
	}, [invoiceId, getInvoiceDetails]);

	useEffect(() => {
		if (invoiceDetails?.data) {
			form.setFieldsValue({
				customerId: invoiceDetails?.data?.customerId,
				dueDate: moment(invoiceDetails?.data?.dueDate),
				invoiceDate: moment(invoiceDetails?.data?.invoiceDate),
				shippingCharges: invoiceDetails?.data?.shippingCharges,
				placeOfSupply: invoiceDetails?.data?.placeOfSupply,
			});
			generateTableData(invoiceDetails?.data);
		}
	}, [invoiceDetails?.data, form]);

	const { billingDetails, shippingDetails } = useMemo(() => {
		let billingDetails = null;
		let shippingDetails = null;
		if (customerIdValue) {
			billingDetails = customers?.find((customer) => customer?._id === customerIdValue)?.billingDetails?.[0] || {};
			shippingDetails = {};
		}
		return {
			billingDetails,
			shippingDetails,
		};
	}, [customerIdValue, customers]);

	const SortableItem = SortableElement((props) => <tr {...props} />);
	const SortableBody = SortableContainer((props) => <tbody {...props} />);

	const getItems = useCallback(() => {
		let url = `${SERVER_IP}item?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_ITEMS', url));
	}, [dispatch, globalRedux.selectedOrganization._id]);

	const getCustomers = useCallback(() => {
		let url = `${SERVER_IP}customer?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_CUSTOMERS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const getSalesPersons = useCallback(() => {
		let url = `${SERVER_IP}salesperson/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_SALES_PERSONS', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const getProjects = useCallback(() => {
		let url = `${SERVER_IP}project?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi('GET_PROJECTS', url));
	}, [dispatch, globalRedux.selectedOrganization._id]);

	useEffect(() => {
		getCustomers();
		getSalesPersons();
		getItems();
		getProjects();
	}, [getCustomers, getSalesPersons, getItems, getProjects]);

	const handleInputChange = useCallback(
		(label, value, rowId) => {
			let item = null;
			if (label === 'itemId') {
				item = items.find((item) => item._id === value);
			}
			const data = tableData.map((data) => {
				let newObj = {};
				if (data.id === rowId) {
					newObj = {
						...data,
						...(item && {
							itemId: item?._id || '',
							itemCode: item?.itemCode || '',
							itemName: item?.itemName || '',
							description: item?.description || '',
							rate: item?.sellingPrice || 0,
							hsnSac: item?.hsnSac || '',
							qty: item?.qty || 1,
							isSerial: item?.isSerial || false,
							disPercent: item?.disPercent || 0,
							disAmount: item?.disAmount || 0,
							serials: item?.serials || [],
							taxId: item?.taxId || null,
							tax: item?.taxRate || 0,
						}),
						[label]: value,
					};
					if (label === 'serials') {
						newObj['qty'] = value?.length || 0;
					}
					return {
						...newObj,
						// totalAmount: calculateTotalAmount(newObj),
						...calculateTaxRateTotalAmount(newObj),
					};
				} else {
					return data;
				}
			});
			setTableData([...data]);
		},
		[tableData, items]
	);

	const handleAddTableData = useCallback(() => {
		let data = [...tableData];
		data.push({
			...itemDefaultRecord,
			id: uuidv4(),
			index: tableData?.length,
		});
		setTableData(data);
	}, [tableData]);

	const handleRemove = (id) => {
		const data = tableData.filter((data) => data.id !== id);
		setTableData([...data]);
	};

	useEffect(() => {
		const filledList = tableData?.map((data) => data.itemId).filter((data) => data);
		if (tableData?.length === filledList.length) {
			handleAddTableData();
		}
	}, [tableData, handleAddTableData]);

	const columns = [
		{
			title: '#',
			dataIndex: 'sno',
			key: 'sno',
			width: '5%',
			align: 'center',
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Item Details',
			dataIndex: 'itemId',
			key: 'itemId',
			width: '40%',
			render: (value, record) => (
				<Row>
					<Col span={24}>
						<Select
							{...{
								...(value && { value }),
							}}
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())}
							style={{ width: '100%' }}
							placeholder="Select item"
							onChange={(value) => handleInputChange('itemId', value, record?.id)}
							dropdownRender={(menu) => (
								<div>
									{menu}
									<Divider />
									<div style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer' }} onClick={() => setShowAddItemModal(true)}>
										<a href style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block' }}>
											<PlusOutlined /> Item
										</a>
									</div>
								</div>
							)}>
							{items?.map((item) => (
								<Select.Option value={item?._id}>{item?.itemName}</Select.Option>
							))}
						</Select>
					</Col>

					<Col span={24} style={{ paddingTop: 10 }}>
						<Input.TextArea
							value={record?.description}
							onChange={({ target: { value } }) => handleInputChange('description', value, record?.id)}
							disabled={!record?.itemId}
							style={{ width: '100%' }}
							placeholder="Add a description to your item"
						/>
					</Col>
				</Row>
			),
		},
		{
			title: 'HSNSAC',
			dataIndex: 'hsnSac',
			align: 'left',
			width: '12%',
			render: (value, record) => (
				<Input
					value={value}
					onChange={({ target: { value } }) => handleInputChange('hsnSac', value, record?.id)}
					style={{ width: '100%' }}
					placeholder="HsnSac"
				/>
			),
		},
		{
			title: 'Qty',
			dataIndex: 'qty',
			key: 'qty',
			width: '12%',
			align: 'right',
			render: (value, record) =>
				record?.isSerial ? (
					<Tooltip style={{ width: '100%' }} title={(record?.serials || [])?.map((serial) => serial?.serial).join(',')}>
						<Button
							style={{ width: '100%', textAlign: 'right' }}
							onClick={() => setSelectedData(record)}
							disabled={!record?.itemId}
							value="default">
							{record?.serials?.length || 0}
						</Button>
					</Tooltip>
				) : (
					<Input
						type="number"
						pattern="^-?[0-9]\d*\.?\d*$"
						value={record?.qty}
						placeholder="qty"
						disabled={!record?.itemId}
						style={{ textAlign: 'right', width: '100%' }}
						className={`${record?.itemId && !value ? 'error' : ''}`}
						onChange={({ target: { value } }) => handleInputChange('qty', parseFloat(value), record?.id)}
					/>
				),
			// render: (value, record) => (
			// 	<Input
			// 		value={value}
			// 		onChange={({ target: { value } }) => handleInputChange('qty', parseFloat(value), record?.id)}
			// 		type="number"
			// 		pattern="^-?[0-9]\d*\.?\d*$"
			// 		className="textAlignRight"
			// 		style={{ width: '100%' }}
			// 		placeholder="Enter qty"
			// 	/>
			// ),
		},
		{
			title: 'Rate',
			dataIndex: 'rate',
			align: 'left',
			width: '12%',
			render: (value, record) => (
				<Input
					value={value}
					onChange={({ target: { value } }) => handleInputChange('rate', parseFloat(value), record?.id)}
					type="number"
					pattern="^-?[0-9]\d*\.?\d*$"
					className="textAlignRight"
					style={{ width: '100%' }}
					placeholder="Enter rate"
				/>
			),
		},
		{
			title: 'Discount',
			dataIndex: 'discount',
			align: 'left',
			width: '12%',
			render: (value, record) => (
				<Row style={{ flexWrap: 'nowrap' }}>
					<Col>
						<Input
							value={value}
							onChange={({ target: { value } }) => handleInputChange('discount', parseFloat(value), record?.id)}
							// onBlur={({ target: { value } }) => handleInputChange('discount', parseFloat(value), record?.id)}
							type="number"
							pattern="^-?[0-9]\d*\.?\d*$"
							className="textAlignRight"
							style={{ width: 60 }}
							placeholder="Enter discount"
						/>
					</Col>
					<Col>
						<Select
							defaultValue={'PERCENT'}
							style={{ width: 55 }}
							onChange={(value) => handleInputChange('discountType', value, record?.id)}>
							<Select.Option value="PERCENT">%</Select.Option>
							<Select.Option value="AMOUNT">₹</Select.Option>
						</Select>
					</Col>
				</Row>
			),
		},
		{
			title: 'Tax',
			dataIndex: 'tax',
			align: 'left',
			width: '12%',
			render: (value, record) => (
				<Select
					{...{
						...(value && { value }),
					}}
					placeholder="Select tax"
					style={{ width: 100 }}
					onChange={(value) => handleInputChange('tax', value, record?.id)}>
					<Select.Option value="0">GST 0%</Select.Option>
					<Select.Option value="5">GST 5%</Select.Option>
					<Select.Option value="12">GST 12%</Select.Option>
					<Select.Option value="18">GST 18%</Select.Option>
					<Select.Option value="28">GST 28%</Select.Option>
				</Select>
			),
		},
		{
			title: 'Amount',
			dataIndex: 'totalAmount',
			align: 'right',
			width: '12%',
			render: (value, record) => {
				return <span style={{ fontWeight: 'bold' }}>{parseFloat(value || 0).toFixed(2)}</span>;
			},
		},
		{
			title: 'Action',
			dataIndex: 'item',
			key: 'item',
			align: 'center',
			width: '3%',
			render: (value, record) =>
				tableData.length > 1 && record?.itemId ? <DeleteOutlined style={{ color: 'red' }} onClick={() => handleRemove(record.id)} /> : null,
		},
	];

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable(tableData.slice(), oldIndex, newIndex).filter((el) => !!el);
			setTableData(newData);
		}
	};

	const DraggableContainer = (props) => (
		<SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
	);

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = tableData.findIndex((x) => {
			return x.index === restProps['data-row-key'];
		});
		return <SortableItem index={index} {...restProps} />;
	};

	const { subTotal, roundOff, totalAmount } = useMemo(() => {
		const subTotal = parseFloat(tableData?.reduce((acc, data) => acc + data?.totalAmount, 0)).toFixed(2);
		// console.log(
		// 	'🚀 ~ file: add-invoice-functional.js:420 ~ const{subTotal,roundOff,totalAmount}=useMemo ~ invoiceInputDetails?.shippingCharges:',
		// 	invoiceInputDetails?.shippingCharges,
		// 	subTotal,
		// 	typeof invoiceInputDetails?.shippingCharges
		// );
		const roundOff = getTheRoundOffValue(parseFloat(subTotal) + parseFloat(invoiceInputDetails?.shippingCharges || 0)) || 0;
		const totalAmount = roundOff?.value || 0;
		// console.log('🚀 ~ file: add-invoice-functional.js:353 ~ const{subTotal,roundOff}=useMemo ~ roundOff:', roundOff, tableData);
		return { subTotal, roundOff: roundOff?.remain, totalAmount };
	}, [tableData, invoiceInputDetails?.shippingCharges]);

	const generateTableData = (editData) => {
		if (editData?.items?.length > 0) {
			const data = editData.items.map(
				({
					itemId,
					description,
					qty,
					rate,
					disPercent,
					disAmount,
					totalAmount,
					taxRate,
					_id,
					itemCode,
					itemName,
					isSerial,
					serials,
					hsnSac,
					taxId,
					taxAmount,
					cgstRate,
					cgstAmount,
					sgstRate,
					sgstAmount,
					igstRate,
					igstAmount,
					discountType,
					discount,
				}) => ({
					itemId,
					qty,
					rate,
					discount: disPercent || disAmount,
					discountType: disPercent > 0 ? 'PERCENT' : 'AMOUNT',
					tax: taxRate,
					description,
					totalAmount,
					id: uuidv4(),
				})
			);
			setTableData(data);
		}
	};

	const handleSubmit = (values) => {
		let data = {
			orgId: globalRedux?.selectedOrganization?._id,
			...values,
			projectId: values?.projectId || '',
			salesPersonId: values?.salesPersonId || '',
			billingDetails: {
				addressLine1: billingDetails?.addressLine1 || '',
				addressLine2: billingDetails?.addressLine2 || '',
				city: billingDetails?.city || '',
				pincode: billingDetails?.pincode || '',
			},
			shippingDetails: {},
			subTotal,
			roundOff,
			totalAmount,
			...invoiceInputDetails,
			items: tableData
				?.filter((data) => data?.itemId)
				.map(
					({
						itemId,
						itemCode,
						itemName,
						description,
						isSerial,
						serials,
						hsnSac,
						qty,
						rate,
						disPercent,
						disAmount,
						taxId,
						taxRate,
						taxAmount,
						cgstRate,
						cgstAmount,
						sgstRate,
						sgstAmount,
						igstRate,
						igstAmount,
						totalAmount,
						discountType,
						discount,
					}) => {
						if (discountType === 'PERCENT') {
							disPercent = discount;
						}
						if (discountType === 'AMOUNT') {
							disAmount = discount;
						}
						return {
							itemId,
							itemCode,
							itemName,
							description,
							isSerial,
							serials: serials?.map((serial) => serial?.serial) || [],
							hsnSac,
							qty,
							rate,
							disPercent,
							disAmount,
							taxId,
							taxRate,
							taxAmount,
							cgstRate,
							cgstAmount,
							sgstRate,
							sgstAmount,
							igstRate,
							igstAmount,
							totalAmount,
						};
					}
				),
		};
		dispatch(postApi(data, 'ADD_INVOICE'));
		// console.log("🚀 ~ file: add-invoice-functional.js:548 ~ handleSubmit ~ ADD_INVOICE:", data)
		// if (!editCustomer) {
		// dispatch(postApi(data, 'ADD_INVOICE'));
		// } else {
		// 	let url = `${SERVER_IP}customer/${editCustomer._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		// 	dispatch(putApi(data, 'EDIT_INVOICE', url));
		// }
	};

	useEffect(() => {
		// let doIt = false;
		if (globalRedux.apiStatus.ADD_INVOICE === 'SUCCESS') {
			dispatch(resetApiStatus('ADD_INVOICE'));
			navigate(-1);
			// doIt = true;
		}
		// if (doIt) {
		// 	getStudents();
		// }
	}, [globalRedux.apiStatus, dispatch, navigate]);

	const loading = useMemo(() => globalRedux.apiStatus.ADD_INVOICE === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return (
		<>
			<AddInvoicePresentational
				{...{
					columns,
					dataSource: tableData,
					customers,
					customerAddModal,
					setCustomerAddModal,
					getCustomers,
					itemAddModal,
					setItemAddModal,
					getItems,
					DraggableContainer,
					DraggableBodyRow,
					form,
					billingDetails,
					shippingDetails,
					customerIdValue,
					handleSubmit,
					subTotal,
					roundOff,
					totalAmount,
					projects,
					salesPersons,
					navigate,
					loading,
					invoiceDetails,
					invoiceInputDetails,
					setInvoiceInputDetails,
					selectedData,
					setSelectedData,
					tableData,
					setTableData,
					handleInputChange,
				}}
			/>
			<AddItem {...{ showAddItemModal, setShowAddItemModal }} />
		</>
	);
};

export default AddInvoiceFunctional;
