import React, { useEffect } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { postApi } from 'redux/sagas/postApiDataSaga';

const AddOrganization = ({ refreshList, closeModal }) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	const [addFormInstance] = Form.useForm();

	const FormCol = {
		labelCol: {
			span: 24,
		},
		wrapperCol: {
			span: 24,
		},
	};

	const onSubmit = (values) => {
		dispatch(postApi(values, 'ADD_ORGANIZATION'));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_ORGANIZATION === 'SUCCESS') {
			addFormInstance.resetFields();
			refreshList();
			dispatch(resetApiStatus('ADD_ORGANIZATION'));
		}
	}, [globalRedux.apiStatus, dispatch, addFormInstance, refreshList]);

	return (
		<Form
			style={{ padding: '0' }}
			className="custom-form"
			requiredMark={false}
			colon={false}
			layout="vertical"
			labelAlign="left"
			form={addFormInstance}
			onFinish={onSubmit}
			{...FormCol}>
			<Form.Item
				label="Organization Name"
				name="orgName"
				rules={[
					{
						required: true,
						message: 'This Field is Required!',
					},
				]}>
				<Input />
			</Form.Item>

			<Form.Item
				label="GSTIN"
				name="gstin"
				rules={[
					{
						required: true,
						message: 'This Field is Required!',
					},
				]}>
				<Input />
			</Form.Item>

			<Form.Item
				wrapperCol={{
					offset: 0,
					// span: 16,
				}}>
				<Row gutter={[10, 10]}>
					<Col span={12}>
						<Button
							loading={globalRedux.apiStatus.ADD_ITEM === 'PENDING'}
							disabled={globalRedux.apiStatus.ADD_ITEM === 'PENDING'}
							type="primary"
							style={{ width: '100%' }}
							htmlType="submit">
							Create
						</Button>
					</Col>
					<Col span={12}>
						<Button
							style={{ width: '100%' }}
							onClick={() => {
								closeModal();
								addFormInstance.resetFields();
							}}
							danger>
							Cancel
						</Button>
					</Col>
				</Row>
			</Form.Item>
		</Form>
	);
};

export default AddOrganization;
