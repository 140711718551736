import React from 'react';
import { Row, Col, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import ExpenseReport from './expense-report';
import AdditionalReport from './additional-report';
import AdditionalReportNew from './additional-report-new';
import SalesByItemReport from './sales-by-item-report';
import HSNSACReport from './hsnsac-report';
// import UnitReport from './unit-report';
// import PaymentReport from './payment-report';
// import DressReceived from './dress-received-report';
// import OutstandingReport from './outstanding-report';
// import DeliveryChecking from './delivery-checking-report';
// import StockReport from './stock-report';
// import ItemReport from './item-report';
// import CustomerReport from './customer-report';
// import ItemRateReport from './item-rate-report';
// import ItemRate from './item-rate';

const { TabPane } = Tabs;

const ADMIN_MASTER_TAB = [
	{
		tabName: 'Expense Report',
		component: ExpenseReport,
	},
	{
		tabName: 'Sales Report',
		component: AdditionalReport,
	},
	{
		tabName: 'Sales By Item',
		component: SalesByItemReport,
	},
	// {
	// 	tabName: 'Payment & Balance Report',
	// 	component: PaymentReport,
	// },
	// {
	// 	tabName: 'Outstanding Report',
	// 	component: OutstandingReport,
	// },
	// {
	// 	tabName: 'Unit Report',
	// 	component: UnitReport,
	// },
	// {
	// 	tabName: 'Dress Received Report',
	// 	component: DressReceived,
	// },
	// {
	// 	tabName: 'Delivery Checking',
	// 	component: DeliveryChecking,
	// },
	// {
	// 	tabName: 'Stock Checking',
	// 	component: StockReport,
	// },
	// {
	// 	tabName: 'Item Report',
	// 	component: ItemReport,
	// },
	// {
	// 	tabName: 'Item Rate Report',
	// 	component: ItemRateReport,
	// },
	// {
	// 	tabName: 'Customer Report',
	// 	component: CustomerReport,
	// },
	// {
	// 	tabName: 'Item Rate',
	// 	component: ItemRate,
	// },
];

const MASTER_TAB = [
	{
		tabName: 'Expense Report',
		component: ExpenseReport,
	},
	{
		tabName: 'Sales Report',
		component: AdditionalReport,
	},
	{
		tabName: 'Sales Report New',
		component: AdditionalReportNew,
	},
	{
		tabName: 'Sales By Item',
		component: SalesByItemReport,
	},
	{
		tabName: 'HSNSAC Report',
		component: HSNSACReport,
	},
	// {
	// 	tabName: 'Payment & Balance Report',
	// 	component: PaymentReport,
	// },
	// {
	// 	tabName: 'Outstanding Report',
	// 	component: OutstandingReport,
	// },
	// {
	// 	tabName: 'Unit Report',
	// 	component: UnitReport,
	// },
	// {
	// 	tabName: 'Dress Received Report',
	// 	component: DressReceived,
	// },
	// {
	// 	tabName: 'Delivery Checking',
	// 	component: DeliveryChecking,
	// },
	// {
	// 	tabName: 'Stock Checking',
	// 	component: StockReport,
	// },
];

const Reports = () => {
	const loginRedux = useSelector((state) => state.loginRedux);
	return (
		<Row style={{ padding: '20px 10px' }}>
			<Col xl={24}>
				<Tabs defaultActiveKey="0" destroyInactiveTabPane>
					{(loginRedux?.role === 'admin' ? ADMIN_MASTER_TAB : MASTER_TAB).map(({ tabName, component }, index) => {
						const Component = component;
						return (
							<TabPane tab={tabName} key={index}>
								<Component />
							</TabPane>
						);
					})}
				</Tabs>
			</Col>
		</Row>
	);
};

export default Reports;
