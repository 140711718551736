import React, { useCallback } from "react";
import moment from "moment";
import { Button, Select, Modal, Drawer, Row, Col, Input, Form } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// import TableComponent from "components/table-component";
import { DATE_FORMAT } from "constants/app-constants";
import AddSerialModal from "components/add-serial-modal";
import DatePicker from "components/date-picker";

const { confirm } = Modal;

const layer1FormCol = {
	labelCol: {
		span: 24,
	},
	wrapperCol: {
		span: 24,
	},
};

const AddInventoryPresentational = ({
	state,
	setState,
	handleSubmit,
	columns,
	loading,
	editData,
	form,
	isValid,
	handleInputChange,
	selectedData,
	setSelectedData,
	tableData,
	setTableData,
	serialType,
	items,
}) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: "Do you want to close this window?",
			icon: <ExclamationCircleOutlined />,
			content: "You will be lost all the details you have entered here.",
			onOk() {
				setState((state) => ({ ...state, visible: false }));
			},
			onCancel() {},
		});
	}, [setState]);

	return (
		<>
			<Drawer
				maskClosable={false}
				title={`${editData ? "Edit" : "Create"} Inventory`}
				placement="right"
				width={"30%"}
				open={state?.visible}
				destroyOnClose
				className="add_inventory"
				onClose={showConfirm}
				footer={
					<Row gutter={[10, 0]}>
						<Col span={12}>
							<Button
								style={{ width: "100%" }}
								onClick={() => setState({ ...state, visible: false })}
							>
								Cancel
							</Button>
						</Col>
						<Col span={12}>
							<Button
								style={{ width: "100%" }}
								// disabled={!isValid}
								loading={loading}
								type="primary"
								htmlType="submit"
								onClick={() => form.submit()}
							>
								{`${editData ? "Update" : "Submit"}`}
							</Button>
						</Col>
					</Row>
				}
			>
				<Row justify="space-between">
					<Col xl={24} md={24}>
						<Form
							// labelAlign="left"
							name="add-inventory"
							className="required_in_right"
							form={form}
							onFinish={handleSubmit}
							{...layer1FormCol}
						>
							<Row gutter={[20, 20]} className="add_inventory">
								<Col span={24}>
									<Form.Item
										label="Date"
										name="date"
										initialValue={moment()}
										rules={[
											{
												required: true,
												message: "This Field is required!",
											},
										]}
									>
										<DatePicker
											style={{ width: "100%" }}
											format={DATE_FORMAT.DD_MM_YYYY}
										/>
									</Form.Item>
									<Form.Item
										label="Item"
										name="itemId"
										rules={[
											{
												required: true,
												message: "This Field is Required!",
											},
										]}
									>
										<Select
											showSearch
											// optionFilterProp="children"
											filterOption={(input, option) =>
												option?.props?.id?.toLowerCase() ===
													input.toLowerCase() ||
												option?.props?.key
													?.toLowerCase()
													.includes(input.toLowerCase())
											}
											placeholder="Select item"
										>
											{items?.map((item) => (
												<Select.Option
													key={item?.itemName}
													value={item?._id}
													id={item?.barCode}
												>
													{item?.itemName} - {item?.barCode}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										label="Reason"
										name="reason"
										rules={[
											{
												required: true,
												message: "This Field is required!",
											},
										]}
									>
										<Select placeholder="Select Reason">
											{["Opening Stock", "Production Stock", "Damaged"]?.map(
												(value) => (
													<Select.Option value={value}>{value}</Select.Option>
												)
											)}
										</Select>
									</Form.Item>
									<Form.Item
										label="Inventory Type"
										name="inventoryType"
										rules={[
											{
												required: true,
												message: "This Field is required!",
											},
										]}
									>
										<Select placeholder="Select Inventory Type">
											{[
												{
													label: "Add",
													value: "Add",
												},
												{
													label: "Reduce",
													value: "Reduce",
												},
											]?.map((data) => (
												<Select.Option key={data?.value} value={data?.value}>
													{data?.label}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										label="Qty"
										name="qty"
										rules={[
											{
												required: true,
												message: "This Field is Required!",
											},
										]}
									>
										<Input
											pattern="^-?[0-9]\d*\.?\d*$"
											placeholder="qty"
											type="number"
										/>
									</Form.Item>
									<Form.Item
										label="Remarks"
										name="remarks"
										initialValue={""}
										rules={[
											{
												required: false,
												message: "This Field is required!",
											},
										]}
									>
										<Input.TextArea placeholder="Enter Remarks" />
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				{/* <Row justify="start">
					<Col xl={24} md={24} className="inventory_table_container">
						<TableComponent
							{...{
								columns,
								dataSource: tableData,
								pagination: false,
								title: () => (
									<Row justify="space-between">
										<Col xl={24} md={24}>
											<Form
												labelAlign="left"
												name="add-inventory"
												className="required_in_right"
												form={form}
												onFinish={handleSubmit}
												{...layer1FormCol}>
												<Row gutter={[20, 20]}>
													<Col xl={12} md={12}>
														<Form.Item
															label="Mode"
															name="inventoryMode"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select placeholder="Select Mode">
																{["Qty Adjustment", "Value Adjustment"]?.map((value) => (
																	<Select.Option value={value}>{value}</Select.Option>
																))}
															</Select>
														</Form.Item>
														<Form.Item
															label="Stock type"
															name="stockType"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select placeholder="Select Stock type">
																{['Add', 'Reduce']?.map((value) => (
																	<Select.Option value={value}>{value}</Select.Option>
																))}
															</Select>
														</Form.Item>
														<Form.Item
															label="Reason"
															name="reason"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Select placeholder="Select Reason">
																{['Opening Stock', 'Production Stock', 'Damaged']?.map((value) => (
																	<Select.Option value={value}>{value}</Select.Option>
																))}
															</Select>
														</Form.Item>
														<Form.Item
															label="Date"
															name="date"
															initialValue={moment()}
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
														</Form.Item>
													</Col>
													<Col xl={12} md={12}>
														<Form.Item
															label="Reference No"
															name="referenceNumber"
															rules={[
																{
																	required: false,
																	message: 'This Field is required!',
																},
															]}>
															<Input placeholder="Enter Reference No" />
														</Form.Item>
														<Form.Item
															label="Remarks"
															name="remarks"
															initialValue={''}
															rules={[
																{
																	required: false,
																	message: 'This Field is required!',
																},
															]}>
															<Input.TextArea placeholder="Enter Remarks" />
														</Form.Item>
													</Col>
												</Row>
											</Form>
										</Col>
									</Row>
								),
							}}
						/>
					</Col>
				</Row> */}
			</Drawer>
			<AddSerialModal
				visible={!!selectedData}
				handleInputChange={handleInputChange}
				selectedData={selectedData}
				setSelectedData={setSelectedData}
				tableData={tableData}
				setTableData={setTableData}
				serialType={serialType}
			/>
		</>
	);
};

export default AddInventoryPresentational;
