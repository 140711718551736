import React from "react";
import { Row, Col, Tabs } from "antd";
// import AccountBook from "pages/master-component/acc-book";
// import Voucher from "pages/master-component/Voucher";
// import Vehicles from "pages/master-component/Vehicles";
// import SalesPerson from "pages/master-component/sales-person";
import Categories from "./categories";
import UnitList from 'pages/units/unit-list';
// import Assets from 'pages/component-customer/Assets';
// import Vendors from 'pages/component-customer/Vendors';
// import Credentials from 'pages/component-customer/Credentials';
// import Items from '../items/Items';

const { TabPane } = Tabs;

const MASTER_TAB = [
	{
		tabName: "Categories",
		component: Categories,
	},
	{
		tabName: 'Units',
		component: UnitList,
	},
	// {
	// 	tabName: 'Vehicles',
	// 	component: Vehicles,
	// },
	// {
	// 	tabName: 'Vouchers',
	// 	component: Voucher,
	// },
	// {
	// 	tabName: 'Sales Persons',
	// 	component: SalesPerson,
	// },
];

const Master = () => (
	<Row style={{ padding: "20px 10px" }}>
		<Col xl={24}>
			<Tabs defaultActiveKey="0" destroyInactiveTabPane>
				{MASTER_TAB.map(({ tabName, component }, index) => {
					const Component = component;
					return (
						<TabPane tab={tabName} key={index}>
							<Component />
						</TabPane>
					);
				})}
			</Tabs>
		</Col>
	</Row>
);

export default Master;
