import React from 'react';
import { Row, Col, Button, Input, Form } from 'antd';
import { convertToIndianRupees } from 'helpers';

const layer1FormCol = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const DeliveryAndPaymentsListPresentational = ({
	getBillingItems,
	form,
	fetchingData,
	handleClear,
	form2,
	billDetails,
	updateStatusItems,
	updatingData,
}) => {
	return (
		<Row>
			<Col span={18}>
				<Row>
					<Col span={12} style={{ padding: 20 }}>
						<Form
							labelAlign="left"
							name="delivery-and-payments"
							className="required_in_right"
							form={form}
							onFinish={getBillingItems}
							{...layer1FormCol}>
							<Row gutter={[20, 0]}>
								<Col xl={24} md={24}>
									<Form.Item
										label="Bill No."
										name="fromBill"
										rules={[
											{
												required: true,
												message: 'This Field is required!',
											},
										]}>
										<Input style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col xl={24} md={24}>
									<Row gutter={[10, 0]}>
										<Col span={8}></Col>
										<Col span={16}>
											<Row gutter={[10, 0]}>
												<Col span={12}>
													<Button style={{ width: '100%' }} loading={fetchingData} type="primary" htmlType="submit">
														View
													</Button>
												</Col>
												<Col span={12}>
													<Button style={{ width: '100%' }} loading={fetchingData} danger onClick={handleClear}>
														Clear
													</Button>
												</Col>
											</Row>
											{billDetails ? (
												<>
													<Row gutter={[10, 10]} style={{ paddingTop: 10 }}>
														{[{
															label: 'Paid',
															value: "Paid"
														}, {
															label: 'Delivered',
															value: "Delivered"
														}, {
															label: 'Paid & Delivered',
															value: "PaidDelivered"
														}].map((status, index) => (
															<Col key={index} span={24}>
																<Button
																	style={{ width: '100%', height: 60 }}
																	loading={updatingData}
																	onClick={() => updateStatusItems(status?.value)}>
																	{status?.label}
																</Button>
															</Col>
														))}
													</Row>
													<Row gutter={[10, 10]}>
														<Col span={24}>
															<div className="delivery-status-amount">
																{convertToIndianRupees(billDetails?.totalAmount || 0)}
															</div>
														</Col>
													</Row>
												</>
											) : null}
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					</Col>
					<Col span={12} style={{ padding: 20 }}>
						<Form
							labelAlign="left"
							name="delivery-and-payments"
							className="required_in_right"
							form={form2}
							onFinish={getBillingItems}
							{...layer1FormCol}>
							<Row gutter={[20, 0]}>
								<Col xl={24} md={24}>
									<Form.Item label="Unit Status" name="unitStatus">
										<Input style={{ width: '100%' }} disabled />
									</Form.Item>
								</Col>
								<Col xl={24} md={24}>
									<Form.Item label="Payment Status" name="paymentStatus">
										<Input style={{ width: '100%' }} disabled />
									</Form.Item>
								</Col>
								<Col xl={24} md={24}>
									<Form.Item label="Delivery Status" name="deliveryStatus">
										<Input style={{ width: '100%' }} disabled />
									</Form.Item>
								</Col>
								<Col xl={24} md={24}>
									<Form.Item label="Total Qty" name="totalQty">
										<Input style={{ width: '100%' }} disabled />
									</Form.Item>
								</Col>
								<Col xl={24} md={24}>
									<Form.Item label="Customer" name="customer">
										<Input style={{ width: '100%' }} disabled />
									</Form.Item>
								</Col>
								<Col xl={24} md={24}>
									<Form.Item label="Mobile" name="mobile">
										<Input style={{ width: '100%' }} disabled />
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default DeliveryAndPaymentsListPresentational;
