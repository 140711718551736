import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Pagination, Row, Col, Button, Input } from 'antd';
import TableComponent from 'components/table-component';
import AddTask from 'pages/tasks/add-task';
// import UpdateStatusModal from './update-status-modal';

const TasksListPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	refreshList,
	tableLoading,
	state,
	setState,
	editData,
	setSearchKey,
	// rowSelection,
	// selectedRowKeys,
}) => {
	return (
		<Row style={{ padding: '20px 10px' }}>
			<Col xl={24} md={24}>
				<TableComponent
					loading={tableLoading}
					// rowSelection={rowSelection}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row justify="space-between">
							<Col span={12}>
								<Row gutter={[10, 10]}>
									<Col span={8}>
										<Input
											placeholder="Search"
											suffix={<AiOutlineSearch />}
											onChange={({ target: { value } }) => setSearchKey(value)}
										/>
									</Col>
									{/* {selectedRowKeys?.length > 0 && <UpdateStatusModal {...{ rowSelection, refreshList }} />} */}
								</Row>
							</Col>

							<Col>
								<Button
									type="primary"
									onClick={() =>
										setState({
											...state,
											visible: true,
										})
									}>
									Create Task
								</Button>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/>
			</Col>
			<AddTask {...{ state, setState, refreshList, editData }} />
		</Row>
	);
};

export default TasksListPresentational;
