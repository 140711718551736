import React, { forwardRef } from "react";
import { getDateFormat } from "services/Utils";
import { useSelector } from "react-redux";
import "../retail-billing-print.scss";

const RetailBillingPrint = forwardRef((props, ref) => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const loginRedux = useSelector((state) => state.loginRedux);
	const {
		// customerId: { mobile, displayName } = {},
		billNumber,
		billDate,
		items,
		totalQty,
		subTotal,
		// received,
		// balance,
	} = props?.data || {};
	const { contact, addressLine1, addressLine2 } =
		globalRedux?.selectedOrganization || {};

	return (
		<div className="retail-bill-receipt" ref={ref}>
			<table cellPadding="0" cellSpacing="0">
				<tr className="top">
					<td colSpan="2" style={{ padding: 0, borderColor: "#000" }}>
						<table className="bordered-bottom">
							<tr>
								<td className="title" style={{ padding: "10px 0" }}>
									{/* <div>{globalRedux?.selectedOrganization?.orgName}</div> */}
									<div>{globalRedux?.selectedOrganization?.orgName || ""}</div>
									<div>
										NO - {`${addressLine1 || ""}, ${addressLine2 || ""}`}
									</div>
									<div>{globalRedux?.selectedOrganization?.city || ""}</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
				<tr className="bill-information">
					<td colSpan="2">
						<table className="bordered-bottom">
							{/* <tr>
								<td
									colSpan="4"
									style={{
										paddingRight: 0,
										paddingLeft: 5,
									}}
								>
									<div className="row">
										<div className="label" style={{ width: "30%" }}>
											Customer:
										</div>
										<div
											className="value"
											style={{ padding: 0, width: "70%", textAlign: "left" }}
										>
											{displayName}
										</div>
									</div>
									<div className="row">
										<div className="label" style={{ width: "30%" }}>
											Mobile:
										</div>
										<div
											className="value"
											style={{ padding: 0, width: "70%", textAlign: "left" }}
										>
											{mobile}
										</div>
									</div>
								</td>
							</tr> */}
							<tr>
								<td
									colSpan="4"
									style={{
										paddingRight: 0,
										paddingLeft: 5,
									}}
								>
									<div className="row">
										<div
											className="label"
											style={{ width: "30%", fontSize: 13 }}
										>
											Bill No:
										</div>
										<div
											className="value"
											style={{ padding: 0, width: "70%", fontSize: 13 }}
										>
											{billNumber}
										</div>
									</div>
									<div className="row">
										<div className="label" style={{ width: "30%" }}>
											Date:
										</div>
										<div className="value" style={{ padding: 0, width: "70%" }}>
											{getDateFormat(billDate)}
										</div>
									</div>
									<div className="row">
										<div className="label" style={{ width: "30%" }}>
											User:
										</div>
										<div className="value" style={{ padding: 0, width: "70%" }}>
											{loginRedux?.firstName} {loginRedux?.lastName}
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="retail-bill-item-table"
										style={{
											width: "100%",
											border: "none",
										}}
									>
										<tr>
											<th colSpan={"3"} style={{ border: "none" }}>
												<div
													className="total-section"
													style={{
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "center",
														fontSize: "1rem",
													}}
												>
													{/* <span>Billing Items</span> */}
												</div>
											</th>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td
									colSpan="4"
									style={{
										borderBottom: "1px dashed",
									}}
								>
									<table
										className="retail-bill-item-table"
										style={{
											width: "100%",
											border: "none",
										}}
									>
										<tr>
											<th
												style={{
													width: "25%",
													borderStyle: "dashed",
													borderLeft: 0,
													borderRight: 0,
												}}
											>
												Particulars
											</th>
											<th
												style={{
													width: "25%",
													borderStyle: "dashed",
													borderLeft: 0,
													borderRight: 0,
												}}
											>
												Qty
											</th>
											<th
												style={{
													width: "25%",
													borderStyle: "dashed",
													borderLeft: 0,
													borderRight: 0,
												}}
											>
												Rate
											</th>
											<th
												style={{
													width: "25%",
													borderStyle: "dashed",
													borderLeft: 0,
													borderRight: 0,
												}}
											>
												Total
											</th>
										</tr>
										{items?.map((item, index, arr) => (
											<tr key={index}>
												<td
													style={{
														width: "25%",
														border: 0,
														borderBottom: 0,
														// ...(index === items?.length - 1 && {
														// 	borderBottom: '1px solid #000',
														// }),
													}}
												>
													{item?.itemId?.itemName}
												</td>
												<td
													style={{
														width: "25%",
														border: 0,
														borderBottom: 0,
														// ...(index === items?.length - 1 && {
														// 	borderBottom: '1px solid #000',
														// }),
													}}
												>
													{item?.qty}
												</td>
												<td
													style={{
														width: "25%",
														border: 0,
														borderBottom: 0,
														// ...(index === items?.length - 1 && {
														// 	borderBottom: '1px solid #000',
														// }),
													}}
												>
													{parseFloat(item?.rate).toFixed(2)}
												</td>
												<td
													style={{
														width: "25%",
														border: 0,
														borderBottom: 0,
														// ...(index === items?.length - 1 && {
														// 	borderBottom: '1px solid #000',
														// }),
													}}
												>
													{parseFloat(item?.totalAmount).toFixed(2)}
												</td>
											</tr>
										))}
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="4">
									<table
										className="retail-bill-item-table"
										style={{
											width: "100%",
											border: "none",
										}}
									>
										<tr>
											<th colSpan={"3"} style={{ border: "none" }}>
												<div
													className="total-section"
													style={{
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "space-between",
														fontSize: "1rem",
													}}
												>
													<div>
														<span
															style={{
																marginRight: 10,
															}}
														>
															Total Qty
														</span>
														<span
															style={{
																width: "80px",
															}}
														>
															{totalQty || 0.0}
														</span>
													</div>
													<div>
														<span
															style={{
																marginRight: 10,
															}}
														>
															Total Rs
														</span>
														<span
															style={{
																width: "80px",
															}}
														>
															{parseFloat(subTotal).toFixed(2) || 0.0}
														</span>
													</div>
												</div>
											</th>
										</tr>
										<tr>
											<th colSpan={"3"} style={{ border: "none" }}>
												<div
													className="total-section"
													style={{
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "space-between",
														fontSize: "1rem",
													}}
												>
													<div>
														<span
															style={{
																marginRight: 10,
															}}
														>
															Total Items
														</span>
														<span
															style={{
																width: "80px",
															}}
														>
															{items?.length || 0}
														</span>
													</div>
													<div>
														{/* <span
															style={{
																marginRight: 10,
															}}
														>
															Received
														</span>
														<span
															style={{
																width: "80px",
															}}
														>
															{parseFloat(received).toFixed(2) || 0.0}
														</span> */}
													</div>
												</div>
											</th>
										</tr>
										{/* <tr>
											<th colSpan={"3"} style={{ border: "none" }}>
												<div
													className="total-section"
													style={{
														flexDirection: "row",
														alignItems: "center",
														justifyContent: "space-between",
														fontSize: "1rem",
													}}
												>
													<div></div>
													<div>
														<span
															style={{
																marginRight: 10,
															}}
														>
															Balance
														</span>
														<span
															style={{
																width: "80px",
															}}
														>
															{parseFloat(balance).toFixed(2) || 0.0}
														</span>
													</div>
												</div>
											</th>
										</tr> */}

										{/* <tr>
											<th style={{ width: '30%', border: 'none' }}>
												<div className="total-section">
													<span>Total Qty</span>
													<span>{totalQty}</span>
												</div>
											</th>
											<th style={{ width: '40%', borderTop: 0, borderBottom: 0 }}>
												<div className="discount-section">
													<span>Discount:</span>
													<span>Net Amount:</span>
												</div>
											</th>
											<th style={{ width: '30%', border: 'none' }}>
												<div className="discount-value-section">
													<span>{parseFloat(discount).toFixed(2)}</span>
													<span>{parseFloat(totalAmount).toFixed(2)}</span>
												</div>
											</th>
										</tr> */}
									</table>
								</td>
							</tr>
							{/* <tr>
								<td colSpan="4">
									<table
										className="retail-bill-item-table"
										style={{
											width: '100%',
											border: 'none',
										}}>
										<tr>
											<th style={{ width: '30%', borderLeft: 0, borderRight: 0, borderBottom: 0, borderTop: 0 }}>
												<div className="total-section">
													<span>Delivery Date: After 15 days</span>
												</div>
											</th>
										</tr>
									</table>
								</td>
							</tr> */}

							<tr>
								<td colSpan="4">
									<table
										className="retail-bill-item-table"
										style={{
											width: "100%",
											border: "none",
										}}
									>
										<tr>
											<th
												style={{
													width: "30%",
													borderLeft: 0,
													borderRight: 0,
													borderBottom: 0,
													borderStyle: "dashed",
													textAlign: "left",
												}}
											>
												<div
													style={{
														fontWeight: "initial",
														flexDirection: "row",
														justifyContent: "space-around",
													}}
												>
													{/* <div style={{ fontWeight: "bold", width: "80%" }}>
														NO -{" "}
														{`${addressLine1 || ""}, ${addressLine2 || ""}`}
													</div> */}
													<div style={{ fontWeight: "bold" }}>
														PH - {contact || ""}
													</div>
												</div>
											</th>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default RetailBillingPrint;
