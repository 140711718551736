import React from 'react';

const TicketTable = ({ tableData }) => {
	return (
		<table className="bordered-bottom" id="payments">
			<tr>
				<th>
					<span style={{ paddingRight: 5 }}>Username</span>
				</th>
				<th>Open</th>
				<th>Completed</th>
				<th>Accepted</th>
				<th>Pending</th>
				<th>InProgress</th>
			</tr>
			{tableData?.map((ticket) => {
				const style = {
					textAlign: 'center',
					...(ticket?.Username === 'Unassigned' && {
						fontWeight: 'bold',
						color: "#c68288",
					}),
				};
				return (
					<tr>
						<td
							{...{
								...(ticket?.Username === 'Unassigned' && {
									style: { fontWeight: 'bold', color: "#c68288" },
								}),
							}}>
							{ticket?.Username}
						</td>
						<td style={{ ...style }}>{ticket?.Open}</td>
						<td style={{ ...style }}>{ticket?.Completed}</td>
						<td style={{ ...style }}>{ticket?.Accepted}</td>
						<td style={{ ...style }}>{ticket?.Pending}</td>
						<td style={{ ...style }}>{ticket?.InProgress}</td>
					</tr>
				);
			})}
		</table>
	);
};

export default TicketTable;
