import React, { useRef, useEffect, useMemo } from 'react';
import { Row, Col, Spin, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiOutlineShopping } from 'react-icons/ai';
import { FiArrowUpRight } from 'react-icons/fi';
import AnimatedNumber from 'components/animated-number';
import { DATE_FORMAT } from 'constants/app-constants';
import DatePicker from 'components/date-picker';
import { RechartsLineChart } from 'components/charts/recharts-line-chart';
import TableComponent from 'components/table-component';
import TicketTable from './ticket-table';
import ExcelDownload from 'components/excel-download';
import { convertToIndianRupees } from 'helpers';

const columns = [
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
	},
	{
		title: 'Sales Val.',
		dataIndex: 'totalAmount',
		key: 'address',
		align: 'right',
		sorter: (a, b) => a.totalAmount - b.totalAmount,
		render: (text) => <span>₹{convertToIndianRupees(text)}</span>,
	},
];

const CardTable = ({ title, dataSource, columns, loading, getDashboardData }) => {
	const exportData = useMemo(() => {
		const data = {
			headers: columns?.map((col) => col?.title) || [],
			data: [
				...(dataSource.map((stock, index) => ({
					[columns[0]?.title]: stock?.date,
					[columns[1]?.title]: parseFloat(stock?.totalAmount).toFixed(2),
				})) || []),
			],
		};
		return data;
	}, [dataSource, columns]);

	return (
		<Col span={24}>
			<Row>
				<Col
					style={{
						fontWeight: 'bold',
						fontSize: '1rem',
						padding: 10,
						backgroundColor: '#fff',
						borderRadius: 5,
					}}
					span={24}>
					<Row
						gutter={[10, 10]}
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<Col>{title}</Col>
						<Col>
							<ExcelDownload {...{ ...exportData }} iconOnly />
							<Button
								type="primary"
								icon={<ReloadOutlined />}
								loading={loading}
								onClick={getDashboardData}
								style={{ marginLeft: 10 }}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<TableComponent
						className="custom-table"
						dataSource={dataSource}
						columns={columns}
						pagination={false}
						style={{
							width: '100%',
						}}
						scroll={{ y: 344 }}
					/>
				</Col>
			</Row>
		</Col>
	);
};

const DashboardPresentational = ({
	dashboardData,
	tableData,
	loginRedux,
	branches,
	setSelectedBranchId,
	specificDate,
	loading,
	setSpecificDate,
	getDashboardData,
}) => {
	// const [open, setOpen] = useState(false);
	const ref1 = useRef(null);
	const ref2 = useRef(null);
	//   const ref3 = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			// setOpen(true);
		}, 500);
	}, []);

	// const steps = [
	// 	{
	// 		title: 'Upload File',
	// 		description: 'Put your files here.',
	// 		cover: <img alt="tour.png" src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png" />,
	// 		target: () => ref1.current,
	// 	},
	// 	{
	// 		title: 'Save',
	// 		description: 'Save your changes.',
	// 		target: () => ref2.current,
	// 	},
	// ];
	return (
		<Row className="dashboard_container">
			{loading && (
				<div className="dashboard_container_loader">
					<Spin />
				</div>
			)}
			<Col xl={24}>
				<Row gutter={[10, 10]}>
					<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
						<Row>
							<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
								<h4>
									Hi there, {loginRedux?.firstName} {loginRedux?.lastName}! 👋
								</h4>
								<div>
									<DatePicker
										style={{ width: 150 }}
										format={DATE_FORMAT.DD_MM_YYYY}
										value={specificDate}
										onChange={(date, dateString) => setSpecificDate(date)}
									/>
									<Button
										type="primary"
										icon={<ReloadOutlined />}
										loading={loading}
										onClick={getDashboardData}
										style={{ marginLeft: 10 }}
									/>
								</div>
								{/* <div>
									{generateGreetings()}! {loginRedux?.firstName} {loginRedux?.lastName}
								</div> */}
							</Col>
							{/* <Col span={12} style={{ textAlign: 'right' }}>
								<Select
									placeholder="Select Branch"
									allowClear
									style={{ width: 150 }}
									onChange={(value) => setSelectedBranchId?.(value)}>
									{branches?.map((branch) => (
										<Select.Option key={branch?._id} value={branch?._id}>
											{branch?.areaName}
										</Select.Option>
									))}
								</Select>
							</Col> */}
						</Row>
					</Col>

					<Col span={24} ref={ref1}>
						<Row gutter={[10, 10]}>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.totalCustomers} />
														</div>
														<div className="name">Total Customers</div>
													</Col>
													<Col span={5} className="icon">
														<BsFillPersonFill />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.totalItems} />
														</div>
														<div className="name">Total Items</div>
													</Col>
													<Col span={5} className="icon red">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.todayExpenses || 0} />
														</div>
														<div className="name">Today Expenses</div>
													</Col>
													<Col span={5} className="icon red">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.currentMonthSales || 0} />
														</div>
													</Col>
													<Col span={5} className="icon">
														<FiArrowUpRight />
													</Col>
													<div className="name">Current Month Sales</div>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row gutter={[10, 10]} style={{ marginTop: 10 }}>
									<Col span={7}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.todaySales || 0} />
														</div>
														<div className="name">Today Sales</div>
													</Col>
													<Col span={5} className="icon ">
														<FiArrowUpRight />
													</Col>
													<Row
														style={{
															width: '100%',
															paddingTop: 10,
														}}>
														<Col span={8} className="amount">
															<div className="label">Cash</div>
															<div>{convertToIndianRupees(dashboardData?.todaySales_paymode?.totalCash || 0)}</div>
														</Col>
														<Col span={8} className="amount">
															<div className="label">UPI</div>
															<div>{convertToIndianRupees(dashboardData?.todaySales_paymode?.totalUpi || 0)}</div>
														</Col>
														<Col span={8} className="amount">
															<div className="label">Card</div>
															<div>{convertToIndianRupees(dashboardData?.todaySales_paymode?.totalCard || 0)}</div>
														</Col>
													</Row>
												</Row>
											</Col>
										</Row>
									</Col>
									{/* <Col span={5}>
								<Row className="dashboard_card">
									<Col span={24}>
										<Row align="top">
											<Col span={19}>
												<div className="value">
													<AnimatedNumber value={dashboardData?.totalTickets?.length} />
												</div>
												<div className="name">Total Tickets</div>
											</Col>
											<Col span={5} className="icon">
												<PieChartOutlined />
											</Col>
										</Row>
									</Col>
									<Col span={24} className="desc">
										Tickets in this app
									</Col>
								</Row>
							</Col> */}
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									{dashboardData?.orders?.map((order, index) => (
										<Col key={index} span={4}>
											<Row className="dashboard_card">
												<Col span={24}>
													<Row align="top">
														<Col span={19}>
															<div className="value">
																<AnimatedNumber value={order?.count || 0} />
															</div>
															<div className="name">{order?._id || ''}</div>
														</Col>
														<Col span={5} className="icon">
															<AiOutlineShopping />
														</Col>
													</Row>
												</Col>
												{/* <Col span={24} className="desc">
										Customers in this app
									</Col> */}
											</Row>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					</Col>
					{/* <Col span={24} ref={ref1}>
						<Row gutter={[10, 10]}>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.unit_Pending} />
														</div>
														<div className="name">Unit Pending</div>
													</Col>
													<Col span={5} className="icon">
														<FiArrowDownLeft />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.unit_Sent} />
														</div>
														<div className="name">Unit Sent</div>
													</Col>
													<Col span={5} className="icon">
														<FiArrowUpRight />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									<Col span={4}>
										<Row className="dashboard_card">
											<Col span={24}>
												<Row align="top">
													<Col span={19}>
														<div className="value">
															<AnimatedNumber value={dashboardData?.deliveryPending} />
														</div>
														<div className="name">Delivery Pending</div>
													</Col>
													<Col span={5} className="icon">
														<FiArrowDownLeft />
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
							<Col span={24}>
								<Row gutter={[10, 10]}>
									{dashboardData?.orders?.map((order, index) => (
										<Col key={index} span={4}>
											<Row className="dashboard_card">
												<Col span={24}>
													<Row align="top">
														<Col span={19}>
															<div className="value">
																<AnimatedNumber value={order?.count || 0} />
															</div>
															<div className="name">{order?._id || ''}</div>
														</Col>
														<Col span={5} className="icon">
															<AiOutlineShopping />
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					</Col> */}

					{/* <Col className="title" span={24} style={{ fontWeight: 'normal' }}>
						<Row>
							<Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div>Tickets</div>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Row gutter={[10, 10]}>
							{dashboardData?.totalTickets?.map((data) => (
								<Col span={5}>
									<Row className="dashboard_card">
										<Col span={24}>
											<Row align="top">
												<Col span={19}>
													<div className="value">
														<AnimatedNumber value={data?.count} />
													</div>
													<div className="name">{data?._id} Tickets</div>
												</Col>
												<Col span={5} className="icon">
													<PieChartOutlined />
												</Col>
											</Row>
										</Col>
										<Col span={24} className="desc">
											{data?._id} Tickets in this app
										</Col>
									</Row>
								</Col>
							))}
						</Row>
					</Col> */}

					{(tableData || [])?.length > 0 ? (
						<Col xl={24} style={{ paddingTop: 20 }} ref={ref2}>
							<Row gutter={[10, 10]}>
								<Col xl={24} style={{ paddingTop: 20 }}>
									<Row gutter={[10, 10]}>
										<Col className="title" span={24} style={{ fontWeight: 'normal' }}>
											<div>Tickets</div>
										</Col>
									</Row>
								</Col>
								<Col className="title" span={14}>
									<TicketTable tableData={tableData} />
								</Col>
							</Row>
						</Col>
					) : null}
					<Col span={24} style={{ paddingTop: 20 }}>
						<Row gutter={[10, 10]}>
							<Col span={12}>
								<div className="chart_container" style={{ marginLeft: 0 }}>
									<div className="chart_title">Daily Sales</div>
									<RechartsLineChart data={dashboardData?.dailySalesSummary || []} />
								</div>
							</Col>
							<Col span={6}>
								<CardTable
									title="Daily Sales Summary"
									dataSource={(dashboardData?.dailySalesSummary || [])?.sort((a, b) => {
										return new Date(b.date) - new Date(a.date);
									})}
									columns={columns}
									loading={loading}
									getDashboardData={getDashboardData}
								/>
							</Col>
							<Col span={6}>
								<CardTable
									title="Monthly Sales Summary"
									dataSource={(dashboardData?.monthlySalesSummary || [])?.sort((a, b) => {
										return new Date(b.date) - new Date(a.date);
									})}
									columns={columns}
									loading={loading}
									getDashboardData={getDashboardData}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			{/* <Tour open={open} onClose={() => setOpen(false)} steps={steps} /> */}
		</Row>
	);
};

export default DashboardPresentational;
