import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import { sendGetRequest, sendPostRequest } from 'redux/sagas/utils';
import { formQueryStringFromObject } from 'helpers';
import HSNSACReportReportPresentational from './hsnsac-report-list-presentational';
import HSNSACReportReportToPrint from './hsnsac-report-to-print';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const HSNSACReportReportFunctional = React.memo(() => {
	const [form] = Form.useForm();
	const [type, setType] = useState('BILL');
	const fromDateValue = Form.useWatch('formDate', form);
	const toDateValue = Form.useWatch('toDate', form);
	const [fetchingData, setFetchingData] = useState(false);
	const [updatingData, setUpdatingData] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [state, setState] = useState({
		visible: false,
	});
	const componentRef = React.useRef();
	const [searchKey, setSearchKey] = useState('');
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const items = useSelector((state) => state.itemRedux?.items || []);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
	}, [tableData, searchKey]);

	const column = useMemo(
		() => [
			{
				title: '#',
				dataIndex: 'itemName',
				key: 'itemName',
				fixed: 'left',
				render: (value, row, index) => index + 1,
			},
			{
				title: 'Bill Date',
				dataIndex: 'billDate',
				key: 'billDate',
				render: (value) => (
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={value || ''}
					/>
				),
			},
			{
				title: 'HSN/SAC',
				dataIndex: 'hsnSac',
				key: 'hsnSac',
				render: (value) => (
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={(value || '-')?.toString() || ''}
					/>
				),
			},
			{
				title: 'Total Qty',
				dataIndex: 'totalQty',
				key: 'totalQty',
				render: (value) => (
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={(value || '-')?.toString() || ''}
					/>
				),
			},
			{
				title: 'Total Amount',
				dataIndex: 'totalAmount',
				key: 'totalAmount',
				align: 'right',
				render: (value) => (
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={(value || '-').toFixed(2) || ''}
					/>
				),
			},
		],
		[searchKey]
	);

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnWidth: '5%',
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	useEffect(() => {
		selectedRow &&
			setState((state) => ({
				...state,
				visible: true,
			}));
	}, [selectedRow]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const getBillingItems = useCallback(
		async (values) => {
			const string = formQueryStringFromObject({
				fromDate: (values?.fromDate).format(DATE_FORMAT.YYYY_MM_DD),
				toDate: (values?.toDate).format(DATE_FORMAT.YYYY_MM_DD),
				...(values?.itemId && {
					itemId: values?.itemId,
				}),
			});
			await setFetchingData(true);
			const res = await sendGetRequest(null, `${SERVER_IP}report/getHsnSalesReport?orgId=${globalRedux?.selectedOrganization?._id}&${string}`);
			await setTableData(res?.data || []);
			await setFetchingData(false);
		},
		[setFetchingData, globalRedux?.selectedOrganization?._id]
	);

	const updateStatusItems = useCallback(
		async (values) => {
			console.log('🚀 ~ file: unit-sections-list-functional.js:255 ~ values:', values);
			await setUpdatingData(true);
			const res = await sendPostRequest({
				url: `${SERVER_IP}bill/status`,
				body: {
					countryCode: '+91',
				},
			});
			await setTableData(res?.data?.data || []);
			await setUpdatingData(false);
		},
		[setUpdatingData]
	);

	const handleClear = () => {
		setTableData([]);
		form.resetFields();
	};

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_BILLINGS === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const customHeader = useMemo(
		() => [
			{
				title: '#',
			},
			{
				title: 'From Date',
			},
			{
				title: moment(fromDateValue).format(DATE_FORMAT.DD_MM_YYYY),
			},
			{
				title: 'To Date',
			},
			{
				title: moment(toDateValue).format(DATE_FORMAT.DD_MM_YYYY),
			},
		],
		[fromDateValue, toDateValue]
	);
	const exportData = useMemo(() => {
		const data = {
			headers: column?.map((col) => col?.title) || [],
			data: [
				// {
				// 	[column[0]?.title]: 'S.No',
				// 	[column[1]?.title]: 'Bill Date',
				// 	[column[2]?.title]: 'HSN/SAC',
				// 	[column[3]?.title]: 'Total Qty',
				// 	[column[4]?.title]: 'Total Amount',
				// },
				...((filteredData || [])?.map((stock, index) => ({
					[column[0]?.title]: index + 1,
					[column[1]?.title]: moment(stock?.billDate).format(DATE_FORMAT.DD_MM_YYYY),
					[column[2]?.title]: stock?.hsnSac || '-',
					[column[3]?.title]: stock?.totalQty?.toString(),
					[column[4]?.title]: parseFloat(stock?.totalAmount).toFixed(2),
				})) || []),
			],
		};
		return data;
	}, [filteredData, column, fromDateValue, toDateValue]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<HSNSACReportReportToPrint ref={componentRef} data={selectedRecordToPrint} {...form.getFieldsValue()} />
			</div>
			<HSNSACReportReportPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					// refreshList: getOrders,
					tableLoading,
					state,
					setState,
					editData: selectedRow,
					setSearchKey,
					rowSelection,
					selectedRowKeys,
					selectedViewRow,
					setSelectedViewRow,
					getBillingItems,
					form,
					fetchingData,
					handleClear,
					updateStatusItems,
					updatingData,
					setUpdatingData,
					setSelectedRecordToPrint,
					type,
					setType,
					exportData,
					items,
				}}
			/>
		</>
	);
});

export default HSNSACReportReportFunctional;
