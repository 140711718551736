import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getDateFormat } from "services/Utils";
import { DATE_FORMAT } from "constants/app-constants";
import "./additional-report-to-print.scss";

const AdditionalReportToPrint = forwardRef((props, ref) => {
	// const globalRedux = useSelector((state) => state.globalRedux);
	const loginRedux = useSelector((state) => state.loginRedux);
	// const { voucherHeads = [] } = globalRedux;
	const { data, fromBill, toBill, toDate, fromDate } = props?.data || {};
	// const voucherHead = voucherHeads?.find((voucherHead) => voucherHead?._id === voucherHeadId);

	return (
		<div className="invoice-pdf-container" ref={ref}>
			<div style={{ fontWeight: "bold", textAlign: "center" }}>
				Sales Report
			</div>
			<table cellPadding="0" cellSpacing="0">
				<tr>
					<td
						colSpan="3"
						style={{
							borderLeftWidth: 0,
						}}
					>
						<table border="1" className="top-heading">
							<tr>
								<td>
									<div className="content">
										<div style={{}}>
											<div className="title" style={{ width: "50%" }}>
												From Bill:{" "}
												<span style={{ fontWeight: "normal" }}>
													{fromBill || ""}
												</span>
											</div>
											<div className="title" style={{ width: "50%" }}>
												To Bill:{" "}
												<span style={{ fontWeight: "normal" }}>
													{toBill || ""}
												</span>
											</div>
										</div>
									</div>
								</td>
								<td className="value_container">
									<div className="content">
										<div style={{}}>
											<div className="title" style={{ width: "50%" }}>
												From Date:{" "}
												<span style={{ fontWeight: "normal" }}>
													{fromDate
														? moment(fromDate).format(DATE_FORMAT.DD_MM_YYYY)
														: ""}
												</span>
											</div>
											<div className="title" style={{ width: "50%" }}>
												To Date:{" "}
												<span style={{ fontWeight: "normal" }}>
													{toDate
														? moment(toDate).format(DATE_FORMAT.DD_MM_YYYY)
														: ""}
												</span>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr
								style={{
									fontSize: "0.8rem",
									fontWeight: "bold",
								}}
							>
								<td colSpan="3" style={{ padding: 0, borderLeftWidth: 0 }}>
									<table>
										<tr>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													// borderLeftWidth: 0,
													textAlign: "center",
													borderLeftWidth: 1,
												}}
											>
												Bill.No
											</td>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													textAlign: "center",
												}}
											>
												Bill Date
											</td>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderRightWidth: 0,
													textAlign: "right",
												}}
											>
												<div style={{ paddingRight: 5 }}>Bill Amount</div>
											</td>
										</tr>
										{(data || [])?.map((item, dataIndex, dataArr) => (
											<tr>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 1,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														// borderLeftWidth: 0,
														textAlign: "center",
														height: 30,
														borderLeftWidth: 1,
													}}
												>
													{item?.billNumber}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 1,
														// borderBottomWidth: index + 1 === 10 ? 0 : 1,
														textAlign: "center",
														height: 30,
													}}
												>
													{item?.billDate
														? moment(item?.billDate).format(
																DATE_FORMAT.MM_DD_YYYY
														  )
														: ""}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderRightWidth: 0,
														borderBottomWidth: 1,
														textAlign: "right",
														height: 30,
													}}
												>
													<div style={{ textAlign: "right", paddingRight: 10 }}>
														{item?.totalAmount}
													</div>
												</td>
											</tr>
										))}
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="2">
									<div className="">
										<div
											style={{
												textAlign: "right",
												fontWeight: "bold",
												paddingRight: 5,
												fontSize: "1rem",
											}}
										>
											Total Amount:{" "}
											<span style={{ marginLeft: 10 }}>
												{data?.reduce(
													(acc, prev) => acc + prev?.totalAmount,
													0
												)}
											</span>
										</div>
									</div>
								</td>
							</tr>
							<tr
								style={{
									fontSize: "0.8rem",
									fontWeight: "bold",
								}}
							>
								<td style={{ width: "50%" }}>
									<div className="content" style={{}}>
										<div style={{ textAlign: "right" }}>
											Printed by {loginRedux?.firstName} {loginRedux?.lastName}
										</div>
									</div>
								</td>
								<td style={{ width: "50%" }}>
									<div className="content" style={{}}>
										<div>Printed on {getDateFormat(moment())}</div>
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default AdditionalReportToPrint;
