import React, { Suspense } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import { Row, Col } from 'antd';
import PageLayout from 'components/page-layout';
import Register from 'pages/pre_login_pages/register';
import Login from 'pages/pre_login_pages/login';
import CompleteYourProfile from 'pages/profile/complete_your_profile';
import OrganizationList from 'pages/organization';
import NoAccess from 'components/no-access';
import { ROUTES } from 'constants/app-constants';
import LottieComponent from 'components/lottie-component';
import LottieFile from 'assets/lottie-files';
import ProtectedRoute from './protected-route';

const Routes = () => {
	return (
		<div
			className="app_container"
			style={
				{
					// overflowY: 'auto',
					// overflowX: 'hidden',
					// minHeight: '100vh',
				}
			}>
			<Suspense fallback={<Row className={`loader`} style={{}} align="middle">
				<Col span={24}>
					<LottieComponent file={LottieFile.Loading} width="15%" height="15%" />
				</Col>
			</Row>}>
				<Switch>
					<Route element={<PageLayout />}>
						{ROUTES.map(({ route, Component }, index) => {
							return (
								<Route
									key={index}
									exact
									path={route}
									element={
										<ProtectedRoute>
											<Component />
										</ProtectedRoute>
									}
								/>
							);
						})}
						{/* <Route path="/loading" element={<Master />} /> */}
						<Route exact path="*" element={<NoAccess />} />
					</Route>
					<Route exact path="/login" element={<Login />} />
					<Route exact path="/register" element={<Register />} />
					<Route
						exact
						path={"/organization"}
						element={<OrganizationList />
						}
					/>
					<Route
						exact
						path={"/complete-your-profile"}
						element={<CompleteYourProfile />
						}
					/>
					<Route exact path="*" element={<NoAccess />} />
				</Switch>
			</Suspense>
		</div>
	);
};

export default Routes;
