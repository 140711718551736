import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
	Button,
	Select,
	Modal,
	Drawer,
	Row,
	Col,
	Input,
	Form,
	Divider,
} from "antd";
// import { useWindowSize } from "react-use";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import TableComponent from "components/table-component";
import { convertToIndianRupees } from "helpers";
import { DATE_FORMAT, PAYMENT_MODES } from "constants/app-constants";
import MiniBillingsList from "pages/billings/mini-billings-list";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

const layer1FormCol = {
	labelCol: {
		span: 10,
	},
	wrapperCol: {
		span: 14,
	},
};

const layer1FormCol1 = {
	wrapperCol: {
		span: 24,
	},
};

const NewBillingPresentational = ({
	state,
	setState,
	handleSubmit,
	columns,
	loading,
	editData,
	form,
	customers,
	totalAmount,
	setCustomerAddModal,
	handleItemAdd,
	tableData,
	totalQty,
	totalItem,
	billItemLoading,
	items,
	inputRef,
	receivedAmountForm,
	receivedModal,
	setReceivedModal,
	receivedValue,
	cashValue,
	gpayValue,
	cardValue,
	paymentModeValue,
	handleManualAdd,
	searchKey,
	setSearchKey,
}) => {
	const buttonRef = React.useRef();
	const [dateTime, setDateTime] = useState(moment());
	const [selectedItem, setSelectedItem] = useState(null);
	const loginRedux = useSelector((state) => state.loginRedux);
	const handle = useFullScreenHandle();
	// const { height } = useWindowSize();
	// const scrollHeight = height - 240 - 292;
	// console.log(
	// 	"🚀 ~ file: add-billing-presentational.js:72 ~ scrollHeight:",
	// 	scrollHeight
	// );
	const navigate = useNavigate();
	const showConfirm = useCallback(() => {
		confirm({
			title: "Do you want to close this window?",
			icon: <ExclamationCircleOutlined />,
			content: "You will be lost all the details you have entered here.",
			onOk() {
				navigate(-1);
				// setState((state) => ({ ...state, visible: false }));
			},
			onCancel() {},
		});
	}, [navigate]);

	useEffect(() => {
		setInterval(() => setDateTime(moment()), 1000);
	}, []);

	// useEffect(() => {
	// 	state?.visible && buttonRef?.current?.click();
	// }, [buttonRef, state?.visible]);

	const filteredData = useMemo(() => {
		if (searchKey === "") {
			return items;
		}
		return items
			.filter((item) => {
				return (
					(item?.barCode || "")?.toLowerCase() === searchKey.toLowerCase() ||
					(item?.itemName || "")
						?.toString()
						.toLowerCase()
						.includes(searchKey.toLowerCase())
				);
			})
			?.sort((a, b) => parseInt(a?.barCode, 10) - parseInt(b?.barCode, 10));
	}, [items, searchKey]);

	return (
		<Drawer
			maskClosable={false}
			title={`New Bill - ${moment(dateTime).format(
				DATE_FORMAT.MM_DD_YYYY_HH_MM_SS
			)}`}
			placement="right"
			width={"100%"}
			open={true}
			destroyOnClose
			className="add_billing"
			onClose={showConfirm}
			getContainer={false}
			footer={
				<Row>
					<Col
						xl={{
							span: 9,
							offset: 15,
						}}
						md={12}
					>
						<Row gutter={[10, 10]} style={{ width: "100%" }} justify="end">
							<Col>
								<Button onClick={() => navigate(-1)}>Cancel</Button>
							</Col>
							<Col>
								<Button
									loading={loading}
									type="primary"
									// onClick={()=>setReceivedModal(true)}
									onClick={() => form?.submit()}
									// onClick={showPropsConfirm}
								>
									{`${editData ? "Update" : "Submit"}`}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			}
		>
			<FullScreen handle={handle}>
				<Row gutter={[20, 10]} justify="start">
					<Col xl={18} md={18}>
						<Row
							gutter={[20, 10]}
							style={
								{
									// height: "calc(100vh - 150px)",
									// overflowY: "scroll",
								}
							}
						>
							<Col span={24}>
								<Button
									style={{
										display: "none",
									}}
									ref={buttonRef}
									onClick={handle?.enter}
									// onClick={isFullScreenEnabled ? handle?.enter : handle?.exit}
									// onClick={() => {
									// 	setIsFullScreenEnabled(!isFullScreenEnabled)
									// }}
								>
									Enter Fullscreen
								</Button>
								{/* <Input placeHolder="Enter Item/Code" /> */}
								<Select
									placeholder="Enter Barcode or Itemname"
									style={{
										width: "100%",
										fontSize: "1rem !important",
										height: 50,
										fontWeight: "bold",
									}}
									defaultActiveFirstOption={false}
									showSearch
									optionFilterProp="children"
									autoFocus
									className="barcode_input"
									ref={inputRef}
									filterOption={false}
									value={selectedItem}
									onInputKeyDown={handleManualAdd}
									onSearch={setSearchKey}
									// onChange={console.log}
									// onSelect={console.log}
									onChange={(value) => {
										handleItemAdd(value);
										setSelectedItem(null);
									}}
								>
									{filteredData?.map((item) => (
										<Select.Option
											key={item?.barCode}
											value={item?._id}
											id={item?.itemName}
										>
											<section>
												<Col
													style={{
														fontWeight: "bold",
														fontSize: "1.1rem",
													}}
												>{`${item?.itemName} - ${item?.barCode || ""}`}</Col>
												<Col style={{ fontWeight: "bold" }}>
													{convertToIndianRupees(item?.rate)}
												</Col>
											</section>
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col
								span={24}
								style={
									{
										// height: "53vh",
										// overflowY: "scroll",
									}
								}
							>
								<TableComponent
									{...{
										columns,
										dataSource: tableData,
										pagination: false,
										loading: billItemLoading,
									}}
								/>
							</Col>
							{/* <Col span={24}>
								<MiniBillingsList />
							</Col> */}
						</Row>
					</Col>
					<Col xl={6} md={6}>
						<Row justify="space-between">
							<Row
								className="total_container"
								justify="space-between"
								style={{ marginBottom: 20, padding: 10 }}
							>
								<Col
									className="bold"
									style={{
										fontSize: "2rem",
									}}
								>
									₹
								</Col>
								<Col
									className="bold"
									style={{
										fontSize: "2rem",
									}}
								>
									{convertToIndianRupees(totalAmount, false)}
								</Col>
							</Row>
							<Col xl={24} md={24}>
								<Form
									labelAlign="left"
									name="add_billing_form"
									className="required_in_right"
									form={form}
									onFinish={(values) =>
										paymentModeValue === "Cash"
											? setReceivedModal(true)
											: handleSubmit(values)
									}
									{...layer1FormCol}
								>
									<Row gutter={[20, 0]}>
										<Col xl={24} md={24}>
											<Form.Item
												label="Payment Mode"
												name="paymentMode"
												initialValue="Cash"
												rules={[
													{
														required: true,
														message: "This Field is required!",
													},
												]}
											>
												<Select placeholder="Select Payment Mode">
													{PAYMENT_MODES?.map((paymentMode) => (
														<Select.Option
															value={paymentMode?.value}
															key={paymentMode?.value}
															id={paymentMode?.value}
														>
															{paymentMode?.label}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Customer"
												name="customerId"
												rules={[
													{
														required: false,
														message: "This Field is required!",
													},
												]}
											>
												<Select
													showSearch
													optionFilterProp="children"
													filterOption={(input, option) => {
														return (
															option.id
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0 ||
															option.key
																?.toLowerCase()
																.indexOf(input?.toLowerCase()) >= 0
														);
													}}
													disabled={!!editData}
													placeholder="Select Customer"
													dropdownRender={(menu) => (
														<div>
															{menu}
															<Divider />
															<div
																style={{
																	display: "flex",
																	flexWrap: "nowrap",
																	cursor: "pointer",
																}}
																onClick={() => setCustomerAddModal(true)}
															>
																<a
																	href
																	style={{
																		flex: "none",
																		color: "#188dfa",
																		padding: "8px",
																		display: "block",
																	}}
																>
																	Add new Customer
																</a>
															</div>
														</div>
													)}
												>
													{customers?.map((customer) => (
														<Select.Option
															value={customer?._id}
															key={customer?.mobile}
															id={customer?.displayName}
														>
															<section>
																<Col style={{ padding: "0 0" }}>
																	<Col>{`${customer?.displayName}`}</Col>
																	{/* <Col>{`${customer?.mobile}`}</Col> */}
																</Col>
															</section>
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col xl={24} md={24} style={{ paddingBottom: 10 }}>
											<Divider />
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Bill No"
												name="billNo"
												rules={[
													{
														required: false,
														message: "This Field is required!",
													},
												]}
											>
												<Input
													style={{ width: "100%", textAlign: "right" }}
													disabled
													className="billing_disabled_input"
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Bill Date"
												name="date"
												initialValue={moment().format(DATE_FORMAT.DD_MM_YYYY)}
												rules={[
													{
														required: false,
														message: "This Field is required!",
													},
												]}
											>
												<Input
													style={{ width: "100%", textAlign: "right" }}
													disabled
													className="billing_disabled_input"
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Cashier"
												name="cashier"
												initialValue={`${loginRedux?.firstName} ${loginRedux?.lastName}`}
												rules={[
													{
														required: false,
														message: "This Field is required!",
													},
												]}
											>
												<Input
													style={{ width: "100%", textAlign: "right" }}
													disabled
													className="billing_disabled_input"
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Location"
												name="location"
												rules={[
													{
														required: false,
														message: "This Field is required!",
													},
												]}
											>
												<Input
													style={{ width: "100%", textAlign: "right" }}
													disabled
													className="billing_disabled_input"
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Terminal"
												name="terminal"
												rules={[
													{
														required: false,
														message: "This Field is required!",
													},
												]}
											>
												<Input
													style={{ width: "100%", textAlign: "right" }}
													disabled
													className="billing_disabled_input"
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24} style={{ paddingBottom: 10 }}>
											<Divider />
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Total Items"
												name="totalItem"
												rules={[
													{
														required: false,
														message: "This Field is required!",
													},
												]}
											>
												<Input
													style={{ width: "100%", textAlign: "right" }}
													placeHolder="0"
													value={totalItem}
													disabled
													className="billing_disabled_input"
												/>
											</Form.Item>
										</Col>
										<Col xl={24} md={24}>
											<Form.Item
												label="Total Qty"
												name="totalQty"
												rules={[
													{
														required: false,
														message: "This Field is required!",
													},
												]}
											>
												<Input
													style={{ width: "100%", textAlign: "right" }}
													placeHolder="0"
													value={totalQty}
													disabled
													className="billing_disabled_input"
												/>
											</Form.Item>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Col>
				</Row>
			</FullScreen>
			<Modal
				width="30%"
				open={receivedModal}
				title={"Cash Details"}
				onOk={receivedAmountForm?.submit}
				onCancel={() => setReceivedModal(false)}
				getContainer={false}
				className="cash_details_modal"
			>
				<Form
					labelAlign="left"
					name="add-inventory"
					className="required_in_right"
					form={receivedAmountForm}
					onFinish={() => {
						setReceivedModal(false);
						handleSubmit({
							...form?.getFieldsValue(),
							...receivedAmountForm?.getFieldsValue(),
						});
					}}
					{...layer1FormCol1}
				>
					<Row gutter={[20, 0]}>
						<Col xl={24} md={24}>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Mobile:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: "#fff",
										padding: 0,
									}}
								>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="mobile"
										rules={[
											{
												required: false,
												message: "This Field is required!",
											},
										]}
									>
										<Input
											maxLength={10}
											style={{
												width: "100%",
												fontSize: "1.2rem",
											}}
											autoFocus
											placeHolder="Mobile No"
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Bill Amount:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: "#1f3667",
									}}
								>
									{parseFloat(totalAmount)?.toFixed(2)}
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Cash:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: "#fff",
										padding: 0,
									}}
								>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="cash"
										rules={[
											{
												required: true,
												message: "This Field is required!",
											},
										]}
									>
										<Input
											style={{
												width: "100%",
												fontSize: "1.2rem",
												textAlign: "right",
											}}
											placeHolder="0"
											autoFocus
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Gpay:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: "#fff",
										padding: 0,
									}}
								>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="gpay"
										rules={[
											{
												required: false,
												message: "This Field is required!",
											},
										]}
									>
										<Input
											style={{
												width: "100%",
												fontSize: "1.2rem",
												textAlign: "right",
											}}
											placeHolder="0"
											autoFocus
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Card:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: "#fff",
										padding: 0,
									}}
								>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="card"
										rules={[
											{
												required: false,
												message: "This Field is required!",
											},
										]}
									>
										<Input
											style={{
												width: "100%",
												fontSize: "1.2rem",
												textAlign: "right",
											}}
											placeHolder="0"
											autoFocus
										/>
									</Form.Item>
								</Col>
							</Row>
							{/* <Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Received:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: "#fff",`
										padding: 0,
									}}
								>
									<Form.Item
										label=""
										className="hideFormLabel"
										name="received"
										rules={[
											{
												required: true,
												message: "This Field is required!",
											},
										]}
									>
										<Input
											style={{
												width: "100%",
												fontSize: "1.2rem",
												textAlign: "right",
											}}
											disabled
											placeHolder="0"
											value={(cashValue || 0) + (gpayValue || 0) + (cardValue || 0)}
										/>
									</Form.Item>
								</Col>
							</Row> */}
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Received:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: "#1f3667",
									}}
								>
									{eval(parseFloat(cashValue || 0).toFixed(2) || 0) +
										eval(parseFloat(gpayValue || 0).toFixed(2) || 0) +
										eval(parseFloat(cardValue || 0).toFixed(2) || 0)}
								</Col>
							</Row>
							<Row align="middle" className="label_value_container">
								<Col span={12} className="label">
									Returned:
								</Col>
								<Col
									span={12}
									className="value"
									style={{
										background: "##51882E",
									}}
								>
									{parseFloat(
										Math.abs(
											(totalAmount || 0) -
												(eval(parseFloat(cashValue || 0).toFixed(2) || 0) +
													eval(parseFloat(gpayValue || 0).toFixed(2) || 0) +
													eval(parseFloat(cardValue || 0).toFixed(2) || 0))
										)
									).toFixed(2)}
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Modal>
		</Drawer>
	);
};

export default NewBillingPresentational;
