import { DATE_FORMAT } from 'constants/app-constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react'

const Timer = () => {
	const [dateTime, setDateTime] = useState(moment());

	useEffect(() => {
		setInterval(() => setDateTime(moment()), 1000);
	}, []);
    return (
        <>
            {`New Bill - ${moment(dateTime).format(DATE_FORMAT.MM_DD_YYYY_HH_MM_SS)}`}
        </>
    )
}

export default Timer