import React, { useCallback } from 'react';
import { Button, Form, Select, Modal, Drawer, Row, Col, InputNumber, Divider } from 'antd';
import moment from 'moment';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import DatePicker from 'components/date-picker';
import { DATE_FORMAT } from 'constants/app-constants';
import AddDrawer from 'components/common-drawer';

const { confirm } = Modal;

const formItemLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 22 },
};

const AddVoucherPresentational = ({
	state,
	setState,
	form,
	handleSubmit,
	loading,
	handleEdit,
	accountBooks,
	voucherHeads,
	voucherHeadModal,
	setVoucherHeadModal,
	getVoucherHeads,
	accBookAddModal,
	setAccBookAddModal,
	getAccountBooks,
}) => {
	const showConfirm = useCallback(() => {
		confirm({
			title: 'Do you want to close this window?',
			icon: <ExclamationCircleOutlined />,
			content: 'You will be lost all the details you have entered here.',
			onOk() {
				setState((state) => ({
					...state,
					visible: false,
				}));
			},
			onCancel() {},
		});
	}, [setState]);

	const isEditMode = !!state?.selectedRow;

	return (
		<>
			<AddDrawer {...{ voucherHeadModal, setVoucherHeadModal, getVoucherHeads }} />
			<AddDrawer {...{ accBookAddModal, setAccBookAddModal, getAccountBooks }} />
			<Drawer
				maskClosable={false}
				title={`${isEditMode ? 'Edit' : 'Add New'} Voucher`}
				placement="right"
				width={'30%'}
				open={state?.visible}
				destroyOnClose
				className="add_student"
				onClose={showConfirm}
				footer={
					<Row>
						<Col
							xl={{
								span: 12,
								offset: 12,
							}}
							md={{
								span: 12,
								offset: 12,
							}}>
							<Row gutter={[10, 10]} style={{ width: '100%' }} justify="end">
								<Col>
									<Button
										onClick={() =>
											setState({
												...state,
												visible: false,
											})
										}>
										Cancel
									</Button>
								</Col>
								<Col>
									<Button loading={loading} type="primary" htmlType="submit" onClick={() => form.submit()}>
										{isEditMode ? 'Update' : 'Submit'}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				}>
				<Row justify="center">
					<Col xl={24} md={24}>
						<Form
							form={form}
							className="required_in_right"
							{...formItemLayout}
							onFinish={(values) => (state?.selectedRow ? handleEdit(values) : handleSubmit(values))}>
							<Row>
								<Col xl={24} md={24}>
									<Form.Item initialValue={moment()} label="Voucher Date" name="voucherDate" rules={[{ required: true }]}>
										<DatePicker style={{ width: '100%' }} format={DATE_FORMAT.DD_MM_YYYY} />
									</Form.Item>
								</Col>
								<Col xl={24} md={24}>
									<Form.Item label="Voucher name" rules={[{ required: true }]} name="voucherheadId">
										<Select
											placeholder="select voucher head"
											dropdownRender={(menu) => (
												<div>
													{menu}
													<Divider />
													<div
														style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer' }}
														onClick={() => setVoucherHeadModal(true)}>
														<a href style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block' }}>
															<PlusOutlined /> Voucher Head
														</a>
													</div>
												</div>
											)}>
											{voucherHeads.map((voucherHead) => (
												<Select.Option value={voucherHead?._id}>{voucherHead?.voucherheadName}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col xl={24} md={24}>
									<Form.Item label="Transaction Type" rules={[{ required: true }]} name="transactionType">
										<Select placeholder="select transaction type">
											<Select.Option value={'Credit'}>Credit</Select.Option>
											<Select.Option value={'Debit'}>Debit</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xl={24} md={24}>
									<Form.Item label="Account Book" rules={[{ required: true }]} name="accbookId">
										<Select
											placeholder="select account book"
											dropdownRender={(menu) => (
												<div>
													{menu}
													<Divider />
													<div
														style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer' }}
														onClick={() => setAccBookAddModal(true)}>
														<a href style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block' }}>
															<PlusOutlined /> Account Book
														</a>
													</div>
												</div>
											)}>
											{accountBooks.map((accountBook) => (
												<Select.Option value={accountBook?._id}>{accountBook?.accbookName}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col xl={24} md={24}>
									<Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
										<InputNumber style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								{/* <Col xl={24} md={24}>
									<Form.Item label="Particulars" name="particulars" rules={[{ required: true }]}>
										<Input.TextArea />
									</Form.Item>
								</Col> */}
							</Row>
						</Form>
					</Col>
				</Row>
			</Drawer>
		</>
	);
};

export default AddVoucherPresentational;
