import React from 'react';
import { Button, Col, Divider, Form, Input, Row, Select, Spin } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { TbFileInvoice } from 'react-icons/tb';
import TableComponent from 'components/table-component';
import DatePicker from 'components/date-picker';
import AddDrawer from 'components/drawer-component';
import { DATE_FORMAT, PLACE_OF_SUPPLY } from 'constants/app-constants';
import AddCustomer from 'pages/customers/add-customer';
import { inWords } from 'services/Utils';
import SalesPersonModal from 'components/sales-person-modal';
import ProjectModal from 'components/project-modal';
import AddSerialModal from 'components/add-serial-modal';

const layer1FormCol = {
	labelCol: {
		span: 12,
	},
	wrapperCol: {
		span: 12,
	},
};

const AddInvoicePresentational = ({
	columns,
	dataSource,
	customers,
	customerAddModal,
	setCustomerAddModal,
	getCustomers,
	itemAddModal,
	setItemAddModal,
	getItems,
	form,
	billingDetails,
	shippingDetails,
	customerIdValue,
	handleSubmit,
	subTotal,
	roundOff,
	totalAmount,
	projects,
	salesPersons,
	navigate,
	loading,
	invoiceDetails,
	invoiceInputDetails,
	setInvoiceInputDetails,
	selectedData,
	setSelectedData,
	tableData,
	setTableData,
	handleInputChange,
	// DraggableContainer,
	// DraggableBodyRow,
}) => {
	if (invoiceDetails?.loading)
		return (
			<Row justify="center" align="middle" style={{ height: '100%' }}>
				<Col>
					<Spin tip="Loading invoice..." />
				</Col>
			</Row>
		);

	return (
		<>
		<AddSerialModal
			visible={!!selectedData}
			handleInputChange={handleInputChange}
			selectedData={selectedData}
			setSelectedData={setSelectedData}
			tableData={tableData}
			setTableData={setTableData}
		/>
			<Row className="new_invoice_container">
				<Col span={24}>
					<Col span={24} className="header">
						<TbFileInvoice className="icon" />
						<span className="title">{invoiceDetails?.data ? 'Invoice Details' : 'New Invoice'}</span>
					</Col>
				</Col>
				<Col span={24}>
					<Form
						onFinish={handleSubmit}
						name="add-vehicle"
						scrollToFirstError={{
							behavior: 'smooth',
							block: 'center',
							inline: 'center',
						}}
						colon={false}
						labelAlign="left"
						className="required_in_right"
						form={form}
						{...layer1FormCol}>
						<Row>
							<Col span={12}>
								<Form.Item
									wrapperCol={{
										span: 12,
									}}
									labelCol={{
										span: 8,
									}}
									// className="ele_padding"
									label="Customer Name"
									name="customerId"
									style={{
										paddingTop: 20,
									}}
									// initialValue={editVehicle?.vehicleName}
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<Select
										placeholder="Select Customer"
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) =>
											optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
										}
										dropdownRender={(menu) => (
											<div>
												{menu}
												<Divider />
												<div
													style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer' }}
													onClick={() => setCustomerAddModal(true)}>
													<a href style={{ flex: 'none', color: '#188dfa', padding: '8px', display: 'block' }}>
														<PlusOutlined /> Customer
													</a>
												</div>
											</div>
										)}>
										{customers?.map((customer) => (
											<Select.Option value={customer?._id}>{customer?.displayName}</Select.Option>
										))}
									</Select>
								</Form.Item>
								{/* <Form.Item
								label="Invoice #"
								name="invoiceNumber"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								// initialValue={editVehicle?.vehicleType}
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="enter invoice number" />
							</Form.Item> */}
								<Form.Item
									label="Invoice date"
									name="invoiceDate"
									wrapperCol={{
										span: 12,
									}}
									labelCol={{
										span: 8,
									}}
									initialValue={moment()}
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} placeholder="enter invoice date" />
								</Form.Item>
								<Form.Item
									label="Due date"
									name="dueDate"
									wrapperCol={{
										span: 12,
									}}
									labelCol={{
										span: 8,
									}}
									initialValue={moment()}
									rules={[
										{
											required: false,
											message: 'This Field is required!',
										},
									]}>
									<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} placeholder="enter due date" />
								</Form.Item>
								{/* <Form.Item
								label="Order Number"
								name="orderNumber"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								// initialValue={editVehicle?.vehicleType}
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input placeholder="enter order number" />
							</Form.Item> */}
								<Form.Item
									wrapperCol={{
										span: 12,
									}}
									labelCol={{
										span: 8,
									}}
									label="Sales Person"
									name="salesPersonId"
									getValueFromEvent={(data) => data}
									rules={[
										{
											required: false,
											message: 'This Field is Required!',
										},
									]}>
									<SalesPersonModal />
								</Form.Item>
								<Form.Item
									wrapperCol={{
										span: 12,
									}}
									labelCol={{
										span: 8,
									}}
									label="Project"
									name="projectId"
									getValueFromEvent={(data) => data}
									rules={[
										{
											required: false,
											message: 'This Field is Required!',
										},
									]}>
									<ProjectModal />
								</Form.Item>
								{/* <Form.Item
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								label="Project #"
								name="projectId"
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Select
									placeholder="Select Project"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) => optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())}>
									{projects?.map((project) => (
										<Select.Option value={project?._id}>{project?.title}</Select.Option>
									))}
								</Select>
							</Form.Item> */}
								<Form.Item
									label="Place of Supply"
									name="placeOfSupply"
									initialValue={PLACE_OF_SUPPLY[0]}
									wrapperCol={{
										span: 12,
									}}
									labelCol={{
										span: 8,
									}}
									rules={[
										{
											required: true,
											message: 'This Field is required!',
										},
									]}>
									<Select placeholder="Place of Supply">
										{PLACE_OF_SUPPLY.map((type) => (
											<Select.Option key={type} value={type}>
												{type}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
								{/* <Form.Item
								label="Shipping Charges"
								name="shippingCharges"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								rules={[
									{
										required: true,
										message: 'This Field is required!',
									},
								]}>
								<InputNumber style={{ width: '100%' }} placeholder="Shipping Charges" />
							</Form.Item> */}
								{/* <Form.Item
								label="Customer Notes"
								name="notes"
								wrapperCol={{
									span: 12,
								}}
								labelCol={{
									span: 8,
								}}
								// initialValue={editVehicle?.vehicleType}
								rules={[
									{
										required: false,
										message: 'This Field is required!',
									},
								]}>
								<Input.TextArea placeholder="enter your notes if any.." />
							</Form.Item> */}
							</Col>
							{customerIdValue && (
								<Col span={12}>
									<Form.Item
										label="Billing Address"
										name="billingDetails"
										wrapperCol={{
											span: 12,
										}}
										labelCol={{
											span: 8,
										}}
										// initialValue={editVehicle?.vehicleType}
										rules={[
											{
												required: false,
												message: 'This Field is required!',
											},
										]}>
										<Row>
											<Col span={24}>{billingDetails?.addressLine1}</Col>
											<Col span={24}>{billingDetails?.addressLine2}</Col>
											<Col span={24}>{billingDetails?.city}</Col>
											<Col span={24}>{billingDetails?.pincode}</Col>
										</Row>
									</Form.Item>
									<Form.Item
										label="Shipping Address"
										name="shippingDetails"
										wrapperCol={{
											span: 12,
										}}
										labelCol={{
											span: 8,
										}}
										// initialValue={editVehicle?.vehicleType}
										rules={[
											{
												required: false,
												message: 'This Field is required!',
											},
										]}>
										<Row>
											<Col span={24}>{shippingDetails?.addressLine1}</Col>
											<Col span={24}>{shippingDetails?.addressLine2}</Col>
											<Col span={24}>{shippingDetails?.city}</Col>
											<Col span={24}>{shippingDetails?.pincode}</Col>
										</Row>
									</Form.Item>
								</Col>
							)}
						</Row>
						{/* <Form.Item
						wrapperCol={{
							offset: 0,
							span: 24,
						}}>
						<Row className="space-between" style={{ paddingTop: 20, width: '100%', margin: 0 }}>
							<Button danger style={{ width: '49%' }}>
								Cancel
							</Button>
							<Button loading={false} style={{ width: '49%' }} type="primary" htmlType="submit">
								Save
							</Button>
						</Row>
					</Form.Item> */}
						<Col span={18}>
							<TableComponent
								pagination={false}
								columns={columns}
								dataSource={dataSource}
								{...{
									footer: () => (
										<Row style={{ fontSize: 14 }}>
											<Col xl={18} md={18}>
												<Col xl={24} md={24} className="bold" style={{ paddingRight: 10 }}>
													Amount in words
												</Col>
												<Col className="inwords" xl={24} md={24}>
													{inWords(totalAmount)}
												</Col>
											</Col>
											<Col xl={6} md={6}>
												<Row>
													<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
														Sub Total
													</Col>
													<Col xl={1} md={1}>
														:
													</Col>
													<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
														{parseFloat(subTotal).toFixed(2)}
													</Col>
												</Row>
												<Row>
													<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
														Shipping Charges
													</Col>
													<Col xl={1} md={1}>
														:
													</Col>
													<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
														<Input
															style={{ width: '100%' }}
															type="number"
															pattern="^-?[0-9]\d*\.?\d*$"
															className="textAlignRight"
															onChange={({ target: { value } }) =>
																setInvoiceInputDetails({
																	shippingCharges: value,
																})
															}
															value={invoiceInputDetails?.shippingCharges}
															placeholder="0.00"
														/>
													</Col>
												</Row>
												<Row>
													<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
														Round Off (+/-)
													</Col>
													<Col xl={1} md={1}>
														:
													</Col>
													<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
														{roundOff || 0}
													</Col>
												</Row>
												<Row>
													<Col xl={14} md={14} style={{ textAlign: 'right', paddingRight: 10 }}>
														Grand Total ( ₹ )
													</Col>
													<Col xl={1} md={1}>
														:
													</Col>
													<Col xl={9} md={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
														{parseFloat(totalAmount).toFixed(2)}
													</Col>
												</Row>
											</Col>
										</Row>
									),
								}}
								// components={{
								// 	body: {
								// 		wrapper: DraggableContainer,
								// 		row: DraggableBodyRow,
								// 	},
								// }}
							/>
						</Col>
						{/* <Col span={24} style={{ paddingTop: 20 }}>
						<Form.Item
							label="Customer Notes"
							name="notes"
							wrapperCol={{
								span: 6,
							}}
							labelCol={{
								span: 4,
							}}
							// initialValue={editVehicle?.vehicleType}
							rules={[
								{
									required: false,
									message: 'This Field is required!',
								},
							]}>
							<Input.TextArea placeholder="enter your notes id any.." />
						</Form.Item>
					</Col> */}
					</Form>
				</Col>
				<Col span={24} className={'footer'}>
					{/* <Button type="ghost" style={{ marginRight: 10 }}>
					Save as Draft
				</Button> */}
					<Button disabled={invoiceDetails?.data} loading={loading} type="primary" style={{ marginRight: 10 }} onClick={form?.submit}>
						Proceed to Submit
					</Button>
					<Button onClick={() => navigate(-1)} type="ghost">
						Close
					</Button>
				</Col>
				<AddCustomer
					{...{ customerAddModal, setCustomerAddModal, refreshList: getCustomers, handleClose: () => setCustomerAddModal(false) }}
				/>
				<AddDrawer {...{ itemAddModal, setItemAddModal, getItems }} />
			</Row>
		</>
	);
};

export default AddInvoicePresentational;
