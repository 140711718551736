import React from 'react';
import { Button, Pagination, Row, Col, Input } from 'antd';
import TableComponent from 'components/table-component';
import AddVoucher from 'pages/voucher/add-voucher';
import { AiOutlineSearch } from 'react-icons/ai';

const VoucherListPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	refreshList,
	tableLoading,
	state,
	setState,
	setSearchKey
}) => {
	return (
		<Row style={{ padding: '20px 10px' }}>
			<Col xl={24}>
				<TableComponent
					loading={tableLoading}
					className="custom-table"
					columns={column}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row justify="space-between">
							<Col xl={3} md={3}>
								<Input
									placeholder="Search"
									suffix={<AiOutlineSearch />}
									style={{ height: '30px' }}
									onChange={({ target: { value } }) => setSearchKey(value)}
								/>
							</Col>

							<Col xl={3} md={3}>
								<Button
									type="primary"
									style={{ width: '100%' }}
									onClick={() => {
										setState({
											...state,
											visible: true,
										});
									}}>
									Add Voucher
								</Button>
							</Col>
						</Row>
					)}
					pagination={{
						current: currentPage,
						pageSize: pageSize,
						position: ['none', 'none'],
					}}
					footer={() => (
						<Row justify="space-between">
							<Col>
								<div>
									{!!filteredData?.length && `Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
								</div>
							</Col>
							<Col md={8}>
								<div style={{ textAlign: 'right' }}>
									<Pagination
										pageSizeOptions={intialPageSizeOptions}
										defaultPageSize={initialPageSize}
										showSizeChanger={true}
										total={filteredData?.length}
										onChange={handleTableChange}
										responsive
									/>
								</div>
							</Col>
						</Row>
					)}
					onChange={handleTableChange}
				/>
			</Col>
			<AddVoucher {...{ state, setState, refreshList }} />
		</Row>
	);
};

export default VoucherListPresentational;
