import React, { useState } from "react";
import { PageHeader as AntHeader } from "@ant-design/pro-layout";
import { Menu, Dropdown, Drawer, Avatar, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DownOutlined, MailOutlined } from "@ant-design/icons";
import { FiLogOut, FiUser } from "react-icons/fi";
import { getFirstLetterFromWords } from "helpers";
import { SIDE_MENUS } from "constants/app-constants";
import { setSelectedOrganization } from "../redux/reducers/globals/globalActions";
import { applicationLogout } from "../services/LoginServices";
import "./drawer.scss";
// import userLogo from '../assets/images/user.png';
// import { BsPerson } from 'react-icons/bs';

const PageHeader = () => {
	const globalRedux = useSelector((state) => state.globalRedux);
	const loginRedux = useSelector((state) => state.loginRedux);
	const [open, setOpen] = useState(false);
	const [placement] = useState("right");
	const navigate = useNavigate();

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const dispatch = useDispatch();

	// const profileMenu = (
	// 	<Menu style={{ width: '150px', marginTop: '10px' }}>
	// 		{/* <Menu.Item className="profile-btn" key="1">
	// 			Profile
	// 		</Menu.Item> */}
	// 		<Menu.Item
	// 			className="logout-button"
	// 			id="logout-button"
	// 			key="2"
	// 			onClick={() => {
	// 				applicationLogout();
	// 			}}>
	// 			Logout
	// 		</Menu.Item>
	// 	</Menu>
	// );

	const organizationMenu = (
		<Menu>
			{globalRedux?.organizations?.map((org, index) => (
				<Menu.Item
					key={org?._id}
					onClick={() => {
						dispatch(setSelectedOrganization(org));
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					}}
				>
					{org?.orgName}
				</Menu.Item>
			))}
		</Menu>
	);
	// console.log("🚀 ~ file: page-header.js:71 ~ organizationMenu ~ organizationMenu:", organizationMenu)

	// const LogoComponent = () => <div>Zopay</div>;
	// const LogoComponent = () => <img src={logo} alt="logo" height="40px" />;

	return loginRedux.isLogged ? (
		<>
			<AntHeader
				title={<div className="d-flex">{/* <LogoComponent /> */}</div>}
				style={{ backgroundColor: "#fff" }}
				extra={[
					<Dropdown overlay={organizationMenu} trigger={["click"]}>
						<button
							style={{
								color: "black",
								marginRight: "10px",
								backgroundColor: "#fff",
								border: "none",
							}}
							onClick={(e) => e.preventDefault()}
						>
							{globalRedux?.selectedOrganization?.orgName} <DownOutlined />
						</button>
					</Dropdown>,
					// <FiUsers className="pointer-icons page-header-tiles" />,
					// <Dropdown overlay={profileMenu} trigger={['click']} style={{}}>
					// 	<img
					// 		src={userLogo}
					// 		alt="profile"
					// 		className="pointer-icons"
					// 		style={{ borderRadius: '50px', marginRight: '20px' }}
					// 		height="23px"
					// 		width="23px"
					// 	/>
					// </Dropdown>,
					<div onClick={showDrawer}>
						{/* <img
							src={userLogo}
							alt="profile"
							className="pointer-icons"
							style={{ borderRadius: '50px', marginRight: '20px' }}
							height="23px"
							width="23px"
						/> */}

						<Avatar
							className="pointer-icons"
							style={{ verticalAlign: "middle", backgroundColor: "#21B478" }}
							size="default"
						>
							{getFirstLetterFromWords(
								`${loginRedux?.firstName} ${loginRedux?.lastName}`
							)}
						</Avatar>
					</div>,
				]}
			/>
			<Drawer
				rootClassName="app-drawer"
				title={
					<div className="title-area">
						<div
							data-view-component="true"
							className="AppHeader-logo position-relative"
						>
							{/* <img
								src="https://avatars.githubusercontent.com/u/30047638?v=4"
								alt=""
								height="32"
								width="32"
								data-view-component="true"
								class="avatar circle"
							/> */}
							<Avatar
								size="default"
								style={{ verticalAlign: "middle", backgroundColor: "#21B478" }}
							>
								{getFirstLetterFromWords(
									`${loginRedux?.firstName} ${loginRedux?.lastName}`
								)}
							</Avatar>
						</div>
						<div className="name_role">
							<div className="name">
								{loginRedux?.firstName} {loginRedux?.lastName}
							</div>
							<div className="role">{loginRedux?.mobile}</div>
						</div>
					</div>
				}
				placement={placement}
				// closable={false}
				width="20%"
				onClose={onClose}
				visible={open}
				key={placement}
			>
				<div className="drawer-container">
					{/* <ul>
						<li>
							<BsPerson />
							<span>Customers</span>
						</li>
						<li>
							<BsPerson />
							<span>Customers</span>
						</li>
						<li>
							<BsPerson />
							<span>Customers</span>
						</li>
					</ul> */}

					<Menu theme="light" mode="inline">
						<Menu.Item
							className="logout-button"
							id="logout-button"
							key="4"
							icon={<FiUser />}
							onClick={() => {
								onClose();
								navigate("/profile");
							}}
						>
							Profile
						</Menu.Item>
						<Divider />
						{SIDE_MENUS.map((menu, index) => {
							const Icon = menu?.icon || <MailOutlined />;
							return (
								<Menu.Item
									key={menu?.key}
									icon={Icon}
									onClick={() => {
										onClose();
										menu?.route && navigate(menu?.route);
									}}
								>
									{menu?.name}
								</Menu.Item>
							);
						})}
						<Divider />
						<Menu.Item
							className="logout-button"
							id="logout-button"
							key="5"
							icon={<FiLogOut />}
							onClick={() => {
								onClose();
								applicationLogout();
							}}
						>
							Logout
						</Menu.Item>
					</Menu>
				</div>
			</Drawer>
		</>
	) : null;
};

export default PageHeader;
