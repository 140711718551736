import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objToQs } from 'helpers';
import moment from 'moment';
import { SERVER_IP } from 'assets/Config';
import { ACTIONS, API_STATUS } from 'constants/app-constants';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { DATE_FORMAT } from 'constants/app-constants';
import DashboardPresentational from './dashboard-presentational';

const DashboardFunctional = () => {
	const [specificDate, setSpecificDate] = useState(moment());
	const [selectedBranchId, setSelectedBranchId] = useState('');
	const globalRedux = useSelector((state) => state.globalRedux);
	const loginRedux = useSelector((state) => state.loginRedux);
	const dashboardData = useSelector(({ dashboardRedux }) => dashboardRedux?.dashboard);
	console.log("🚀 ~ file: dashboard-functional.js:17 ~ DashboardFunctional ~ dashboardData:", dashboardData)
	const tableData = dashboardData?.totalTickets?.map((ticket) => ({
		Username:
			ticket?.userInfo?.firstName || ticket?.userInfo?.lastName
				? `${ticket?.userInfo?.firstName || ''} ${ticket?.userInfo?.lastName || ''}`
				: 'Unassigned',
		Open: ticket?.statusCount?.Open || 0,
		Completed: ticket?.statusCount?.Completed || 0,
		Accepted: ticket?.statusCount?.Accepted || 0,
		Pending: ticket?.statusCount?.Pending || 0,
		InProgress: ticket?.statusCount?.InProgress || 0,
	}));
	const branches = useSelector(({ globalRedux: { branches } }) => branches);

	const dispatch = useDispatch();

	const getDashboardData = useCallback(() => {
		let url = `${SERVER_IP}dashboard/?${objToQs({
			orgId: globalRedux?.selectedOrganization?._id,
			areaId: selectedBranchId,
			specificDate: moment(specificDate).format(DATE_FORMAT.YYYY_MM_DD),
		})}`;
		dispatch(getApi(ACTIONS.GET_DASHBOARD_DATA, url));
	}, [dispatch, globalRedux?.selectedOrganization?._id, selectedBranchId, specificDate]);

	useEffect(() => {
		specificDate && getDashboardData();
	}, [getDashboardData, selectedBranchId, specificDate]);

	const loading = useMemo(() => globalRedux.apiStatus.GET_DASHBOARD_DATA === API_STATUS.PENDING, [globalRedux.apiStatus]);

	return <DashboardPresentational {...{ dashboardData, tableData, loginRedux, branches, setSelectedBranchId, specificDate, setSpecificDate, loading, getDashboardData }} />;
};

export default DashboardFunctional;
