import React from 'react';
import { Row, Col, Tabs } from 'antd';
import InventoryTab from './inventory-tab';
import './preference.scss'
import ItemsTab from './items-tab';

const { TabPane } = Tabs;

// const UNWANTED_MENUS = ['Dashboard', 'Masters', 'Preferences'];

const MASTER_TAB = [
	{
		tabName: 'Inventory',
		component: InventoryTab,
	},
	{
		tabName: 'Items',
		component: ItemsTab,
	},
];

const Preferences = () => {
	return (
		<Row style={{ padding: 20 }}>
			<Col span={24} style={{ paddingBottom: 20 }}>
				<h4>Preferences</h4>
			</Col>
			<Col span={24}>
				{/* <Tabs
					rootClassName='preference_tab'
					// tabPosition={'left'}
					items={MENUS.filter((menu) => !UNWANTED_MENUS.includes(menu?.name)).map((menu, i) => {
						const id = String(i + 1);
						return {
							label: `${menu?.name}`,
							key: id,
							children: `Content of ${menu?.name}`,
						};
					})}
				/> */}
				<Tabs defaultActiveKey="0" rootClassName="preference_tab">
					{MASTER_TAB.map(({ tabName, component }, index) => {
						const Component = component;
						return (
							<TabPane tab={tabName} key={index}>
								<Component />
							</TabPane>
						);
					})}
				</Tabs>
			</Col>
		</Row>
	);
};

export default Preferences;
