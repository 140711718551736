import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getDateFormat } from 'services/Utils';
import { DATE_FORMAT } from 'constants/app-constants';
import './additional-report-new-to-print.scss';

const DATA = {
	data: [
		{
			date: '01-04-24',
			bills: [
				{
					billNumber: '1',
					billDate: '01-04-24',
					items: [
						{
							itemName: 'Tea',
							type: 'beverages',
							qty: 2,
							unitRate: 15,
							totalAmount: 30,
						},
						{
							itemName: 'Biscuit',
							type: 'biscuit',
							qty: 3,
							unitRate: 10,
							totalAmount: 30,
						},
						{
							itemName: 'Biscuit',
							type: 'biscuit',
							qty: 3,
							unitRate: 10,
							totalAmount: 30,
						},
					],
				},
				{
					billNumber: '2',
					billDate: '01-04-24',
					items: [
						{
							itemName: 'Tea',
							type: 'beverages',
							qty: 2,
							unitRate: 15,
							totalAmount: 30,
						},
						{
							itemName: 'Biscuit',
							type: 'biscuit',
							qty: 3,
							unitRate: 10,
							totalAmount: 30,
						},
					],
				},
			],
		},
		{
			date: '02-04-24',
			bills: [
				{
					billNumber: '1',
					billDate: '02-04-24',
					items: [
						{
							itemName: 'Tea',
							type: 'beverages',
							qty: 2,
							unitRate: 15,
							totalAmount: 30,
						},
						{
							itemName: 'Biscuit',
							type: 'biscuit',
							qty: 3,
							unitRate: 10,
							totalAmount: 30,
						},
					],
				},
			],
		},
	],
};

const AdditionalReportNewToPrint = forwardRef((props, ref) => {
	const { data = [], fromBill, toBill, toDate, fromDate } = props?.data || {};

	// Calculate Net Total of items
	let netTotal = 0;
	data.forEach((dateItem) => {
		dateItem.bills.forEach((bill) => {
			bill.items.forEach((item) => {
				netTotal += 1;
			});
		});
	});

	// Calculate Nat Qty of items
	let netQty = 0;
	data.forEach((dateItem) => {
		dateItem.bills.forEach((bill) => {
			bill.items.forEach((item) => {
				netQty += item.qty;
			});
		});
	});

	// Calculate Net Total of items
	let netTotalAmount = 0;
	data.forEach((dateItem) => {
		dateItem.bills.forEach((bill) => {
			bill.items.forEach((item) => {
				netTotalAmount += item.totalAmount;
			});
		});
	});
	return (
		<div className="invoice-pdf-container" ref={ref}>
			<div
				style={{
					fontWeight: 'bold',
					textAlign: 'center',
					border: '1px solid ',
					borderBottomWidth: 0,
					fontSize: '0.8rem',
					backgroundColor: '#c6f0ce',
				}}>
				Report From 01-04-2024 To 30-04-2024
			</div>
			<table cellPadding="0" cellSpacing="0">
				<tr>
					<td
						colSpan="3"
						style={{
							borderLeftWidth: 0,
						}}>
						<table border="1" className="top-heading">
							<tr
								style={{
									fontSize: '0.8rem',
									fontWeight: 'bold',
								}}>
								<td colSpan="3" style={{ padding: 0, borderLeftWidth: 0, borderRightWidth: 0 }}>
									<table>
										{(data || [])?.map((item, index) => (
											<>
												<tr key={index}>
													<td
														colSpan="8"
														style={{
															fontWeight: 'bold',
															borderTopWidth: 0,
															borderBottomWidth: 1,
															textAlign: 'center',
															borderLeftWidth: 1,
															backgroundColor: '#ffcc99',
														}}>
														{item.date}
													</td>
												</tr>
												<>
													{item.bills.map((bill, index) => (
														<>
															<tr
																style={{
																	backgroundColor: '#feffcd',
																}}>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		textAlign: 'left',
																		paddingLeft: 5,
																		borderLeftWidth: 1,
																	}}>
																	Bill.No
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		textAlign: 'left',
																		paddingLeft: 5,
																	}}>
																	Bill Date
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'left',
																		paddingLeft: 5,
																	}}>
																	<div style={{ paddingRight: 10 }}>Items & Particulars</div>
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'left',
																		paddingLeft: 5,
																	}}>
																	<div style={{ paddingRight: 10 }}>Group</div>
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}>
																	<div style={{ paddingRight: 10 }}>Qty</div>
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}>
																	<div style={{ paddingRight: 10 }}>Unit Rate</div>
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		textAlign: 'right',
																	}}>
																	<div style={{ paddingRight: 10 }}>Bill Value</div>
																</td>
															</tr>
															{bill.items.map((item, index) => (
																<tr key={index}>
																	<td
																		style={{
																			borderTopWidth: 0,
																			borderBottomWidth: 1,
																			textAlign: 'left',
																			paddingLeft: 5,
																			height: 30,
																			borderLeftWidth: 1,
																		}}>
																		{index === 0 ? bill?.billNumber : ''}
																	</td>
																	<td
																		style={{
																			borderTopWidth: 0,
																			borderBottomWidth: 1,
																			textAlign: 'left',
																			paddingLeft: 5,
																			height: 30,
																		}}>
																		{index === 0
																			? bill?.billDate
																				? moment(bill?.billDate).format(DATE_FORMAT.MM_DD_YYYY)
																				: ''
																			: ''}
																	</td>
																	<td
																		style={{
																			borderTopWidth: 0,
																			borderRightWidth: 0,
																			borderBottomWidth: 1,
																			textAlign: 'right',
																			height: 30,
																		}}>
																		<div style={{ textAlign: 'left', paddingLeft: 5, paddingRight: 10 }}>
																			{item?.itemName}
																		</div>
																	</td>
																	<td
																		style={{
																			borderTopWidth: 0,
																			borderRightWidth: 0,
																			borderBottomWidth: 1,
																			textAlign: 'right',
																			height: 30,
																		}}>
																		<div style={{ textAlign: 'left', paddingLeft: 5, paddingRight: 10 }}>
																			{item?.type}
																		</div>
																	</td>
																	<td
																		style={{
																			borderTopWidth: 0,
																			borderRightWidth: 0,
																			borderBottomWidth: 1,
																			textAlign: 'right',
																			height: 30,
																		}}>
																		<div style={{ textAlign: 'right', paddingRight: 10 }}>{item?.qty}</div>
																	</td>
																	<td
																		style={{
																			borderTopWidth: 0,
																			borderRightWidth: 0,
																			borderBottomWidth: 1,
																			textAlign: 'right',
																			height: 30,
																		}}>
																		<div style={{ textAlign: 'right', paddingRight: 10 }}>{item?.unitRate}</div>
																	</td>
																	<td
																		style={{
																			borderTopWidth: 0,
																			borderBottomWidth: 1,
																			textAlign: 'right',
																			height: 30,
																		}}>
																		<div style={{ textAlign: 'right', paddingRight: 10 }}>
																			{item?.totalAmount}
																		</div>
																	</td>
																</tr>
															))}
															<tr
																style={{
																	backgroundColor: '#feffcd',
																}}>
																<td
																	colSpan={2}
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		textAlign: 'center',
																	}}>
																	Total
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}>
																	<div style={{ paddingRight: 10 }}>{bill.items.length}</div>
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}></td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}>
																	<div style={{ paddingRight: 10 }}>
																		{bill.items.reduce((acc, item) => acc + item.qty, 0)}
																	</div>
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}>
																	<div style={{ paddingRight: 10 }}></div>
																</td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		textAlign: 'right',
																	}}>
																	<div style={{ paddingRight: 10 }}>
																		{bill.items.reduce((acc, item) => acc + item.totalAmount, 0)}
																	</div>
																</td>
															</tr>
															<tr
																style={{
																	backgroundColor: '#feffcd',
																}}>
																<td
																	colSpan={2}
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		textAlign: 'center',
																	}}></td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}></td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}></td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}></td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		borderRightWidth: 0,
																		textAlign: 'right',
																	}}></td>
																<td
																	style={{
																		fontWeight: 'bold',
																		borderTopWidth: 0,
																		borderBottomWidth: 1,
																		textAlign: 'right',
																		opacity: 0,
																	}}>
																	{'test '}
																</td>
															</tr>
														</>
													))}
												</>
											</>
										))}
										<tr
											style={{
												backgroundColor: '#c6f0cd',
												color: '#517c40',
											}}>
											<td
												colSpan="2"
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 0,
													textAlign: 'center',
													borderLeftWidth: 1,
												}}>
												Net Total
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 0,
													borderRightWidth: 0,
													textAlign: 'right',
												}}>
												<div style={{ paddingRight: 10 }}>{netTotal}</div>
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 0,
													borderRightWidth: 0,
													textAlign: 'right',
												}}>
												<div style={{ paddingRight: 10 }}></div>
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 0,
													borderRightWidth: 0,
													textAlign: 'right',
												}}>
												<div style={{ paddingRight: 10 }}>{netQty}</div>
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 0,
													borderRightWidth: 0,
													textAlign: 'right',
												}}>
												<div style={{ paddingRight: 10 }}></div>
											</td>
											<td
												style={{
													fontWeight: 'bold',
													borderTopWidth: 0,
													borderBottomWidth: 0,
													textAlign: 'right',
												}}>
												<div style={{ paddingRight: 10 }}>{netTotalAmount}</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default AdditionalReportNewToPrint;
