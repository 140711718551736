import React, { useRef } from "react";
import { Button, Col, Layout, Row } from "antd";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getApi } from "redux/sagas/getApiDataSaga";
import { SERVER_IP } from "assets/Config";
import LottieFile from "assets/lottie-files";
import Sidebar from "./side-bar";
import PageHeader from "./page-header";
import LottieComponent from "./lottie-component";

const { Content } = Layout;

const PageLayout = () => {
	// const [isLoading, setIsLoading] = useState(true);
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(
			getApi(
				"GET_ACCOUNT_BOOKS",
				`${SERVER_IP}accbook/?orgId=${globalRedux?.selectedOrganization?._id}`
			)
		);
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setIsLoading(false);
	// 	}, 2000);
	// }, []);

	const buttonRef = useRef();
	const handle = useFullScreenHandle();
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		buttonRef && buttonRef?.current && buttonRef?.current?.click();
	// 	}, 100);
	// }, [buttonRef]);

	return (
		<div className="app_container">
			<FullScreen handle={handle}>
				<Button
					style={{
						height: 100,
						width: 100,
						position: "absolute",
						// zIndex: -10
						opacity: 0,
					}}
					ref={buttonRef}
					onClick={handle?.enter}
				>
					Enter
				</Button>
				<Layout style={{ height: "100vh" }}>
					<Row className={`loader`} style={{}} align="middle">
						<Col span={24}>
							<LottieComponent
								file={LottieFile.Loading}
								width="15%"
								height="15%"
							/>
						</Col>
					</Row>
					<Sidebar />
					<Layout
						style={{
							backgroundColor: "#fff",
							overflow: "auto",
							height: "100vh",
						}}
					>
						<Content>
							<PageHeader />
							<div className="overflow-scroll">
								<Outlet />
							</div>
							{/* <FloatButton.Group trigger="click" type="primary" style={{ right: 24 }} icon={<CustomerServiceOutlined />}>
						<FloatButton />
						<FloatButton icon={<CommentOutlined />} />
					</FloatButton.Group> */}
						</Content>
					</Layout>
				</Layout>
			</FullScreen>
		</div>
	);
};

export default PageLayout;
