import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Modal, Row, Col, Divider, Select, Input, Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, EditOutlined, PercentageOutlined } from '@ant-design/icons';
import TableComponent from 'components/table-component';
import { postApi } from 'redux/sagas/postApiDataSaga';
import { API_STATUS } from 'constants/app-constants';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { AiOutlineSearch } from 'react-icons/ai';
import { putApi } from 'redux/sagas/putApiSaga';
import { deleteApi } from 'redux/sagas/deleteApiSaga';
import './category-model.scss';

const layer1FormCol = {
	labelCol: {
		span: 24,
	},
	wrapperCol: {
		span: 24,
	},
};

const CategoryModal = ({ value = null, onChange }) => {
	const [categoryModal, setCategoryModal] = useState(false);
	const [searchKey, setSearchKey] = useState('');
	const [addFormVisible, setAddFormVisible] = useState(false);
	const [editData, setEditData] = useState(null);
	const [tableData, setTableData] = useState([]);
	const selectRef = useRef(null);
	const globalRedux = useSelector((state) => state.globalRedux);
	const categories = useSelector((state) => state?.globalRedux.categories);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const column = [
		{
			title: 'CATEGORY NAME',
			dataIndex: 'categoryName',
			key: 'categoryName',
			width: '20%',
			align: 'left',
		},
		{
			title: 'MARGIN %',
			dataIndex: 'profitMargin',
			key: 'profitMargin',
			width: '10%',
			align: 'left',
		},
		{
			title: 'Delete',
			dataIndex: 'categoryName',
			key: 'categoryName',
			width: '10%',
			align: 'center',
			render: (value, record) => (
				<Row className="show-on-hover" justify="center">
					<Col span={6} onClick={() => handleEdit(record)}>
						<EditOutlined />
					</Col>
					<Col span={6} className="delete" onClick={() => handleRemove(record?._id)}>
						<DeleteOutlined />
					</Col>
				</Row>
			),
		},
	];

	const handleInputKeyDown = () => {
		if (selectRef.current) {
			selectRef.current.blur();
			setCategoryModal(true);
		}
	};

	const handleRemove = (id) => {
		let url = `${SERVER_IP}category/${id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(deleteApi('DELETE_CATEGORY', url));
	};

	const handleSubmit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
		};
		if (editData) {
			let url = `${SERVER_IP}category/${editData._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
			dispatch(putApi(data, 'EDIT_CATEGORY', url));
		} else {
			dispatch(postApi(data, 'ADD_CATEGORY'));
		}
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			orgId: globalRedux?.selectedOrganization?._id,
			categoryName: values?.categoryName || '',
			profitMargin: values?.profitMargin || '',
		};
		setEditData(data);
	};

	const getCategories = useCallback(() => {
		let url = `${SERVER_IP}category/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_CATEGORIES', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	// useEffect(() => {
	// 	getCategories();
	// }, [getCategories]);

	useEffect(() => {
		if ((categories || [])?.length > 0) {
			setTableData(categories);
		}
	}, [categories]);

	useEffect(() => {
		if (editData) {
			form.setFieldsValue({
				categoryName: editData?.categoryName || '',
				profitMargin: editData?.profitMargin || '',
			});
			setAddFormVisible(true);
		}
	}, [editData, form]);

	useEffect(() => {
		if (!addFormVisible) {
			form.resetFields();
			setEditData(null);
		}
	}, [addFormVisible, form]);

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_CATEGORY === 'SUCCESS' || globalRedux.apiStatus.EDIT_CATEGORY === 'SUCCESS') {
			dispatch(resetApiStatus(editData ? 'EDIT_CATEGORY' : 'ADD_CATEGORY'));
			setAddFormVisible(false);
			getCategories();
		}
	}, [globalRedux.apiStatus, editData, setCategoryModal, dispatch, getCategories]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_CATEGORY === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_CATEGORY'));
			doIt = true;
		}
		if (doIt) {
			getCategories();
		}
	}, [globalRedux.apiStatus, dispatch, getCategories]);

	const loading = globalRedux.apiStatus.GET_CATEGORIES === API_STATUS.PENDING;
	const buttonLoading = globalRedux.apiStatus.ADD_CATEGORY === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CATEGORY === API_STATUS.PENDING;

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((category) => {
			return (
				(category?.categoryName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(category?.outstandingBalance || '')?.toString().toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	return (
		<>
			<Select
				onChange={onChange}
				ref={selectRef}
				{...{
					...(value && { value }),
				}}
				placeholder="Select category"
				dropdownRender={(menu) => (
					<div>
						<div
							style={{ display: 'flex', flexWrap: 'nowrap', cursor: 'pointer',  background: "#6ebaff" }}
							onClick={() => {
								handleInputKeyDown();
							}}>
							<a href style={{ flex: 'none', color: '#fff', padding: '8px', display: 'block' }}>
								Add Category
							</a>
						</div>
						<Divider />
						{menu}
					</div>
				)}>
				{categories.map((category) => (
					<Select.Option key={category?._id} value={category?._id}>
						{category?.categoryName}
					</Select.Option>
				))}
			</Select>

			<Modal
				title={
					<Row>
						<Col span={24}>
							<Row>
								<Col span={24}>Categories</Col>
							</Row>
						</Col>
					</Row>
				}
				footer={null}
				onCancel={() => setCategoryModal(false)}
				cancelButtonProps={{ onPress: () => setCategoryModal(false) }}
				okText="Save"
				visible={categoryModal}
				style={{ top: 0 }}
				width={'40%'}>
				<Row className="add-category">
					<Col span={24}>
						{addFormVisible && (
							<Row className="add-category-form">
								<Col span={24}>
									<Form
										name="add-staff"
										style={{}}
										requiredMark={false}
										colon={false}
										labelAlign="left"
										form={form}
										onFinish={handleSubmit}
										{...layer1FormCol}>
										<Row gutter={[10, 0]}>
											<Col span={15}>
												<Form.Item
													label="Category Name"
													name="categoryName"
													initialValue={editData?.categoryName}
													rules={[
														{
															required: true,
															message: 'This Field is Required!',
														},
													]}>
													<Input autoFocus placeholder="Category name" />
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													label="Margin %"
													name="profitMargin"
													initialValue={editData?.profitMargin || 0}
													rules={[
														{
															required: true,
															message: 'This Field is Required!',
														},
													]}>
													<Input
														pattern="^-?[0-9]\d*\.?\d*$"
														placeholder="Margin"
														type="number"
														addonAfter={<PercentageOutlined />}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[10, 0]}>
											<Col span={12}>
												<Form.Item
													style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
													wrapperCol={{
														span: 24,
													}}>
													<Row>
														<Button loading={buttonLoading} type="primary" style={{ marginRight: 5 }} htmlType="submit">
															{editData ? 'Update' : 'Save'}
														</Button>
														<Button onClick={() => setAddFormVisible(false)} style={{}} danger>
															Cancel
														</Button>
													</Row>
												</Form.Item>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						)}
						<TableComponent
							className="add-table"
							style={{ width: '100%' }}
							rowKey={(record) => record._id}
							dataSource={filteredData}
							columns={column}
							pagination={false}
							loading={loading}
							title={() =>
								addFormVisible ? null : (
									<Row gutter={[10, 0]} style={{ justifyContent: 'space-between' }}>
										<Col span={15}>
											<Input
												placeholder="Search Category"
												suffix={<AiOutlineSearch />}
												onChange={({ target: { value } }) => setSearchKey(value)}
											/>
										</Col>
										<Col span={9} style={{ textAlign: 'right' }}>
											<Button type="primary" onClick={() => setAddFormVisible(true)}>
												Create New
											</Button>
										</Col>
									</Row>
								)
							}
						/>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default CategoryModal;
