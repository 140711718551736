import React, { } from 'react';
// import { Row, Col, Input } from 'antd';
// import { AiOutlineSearch } from 'react-icons/ai';
// import TableComponent from 'components/table-component';

const StockTransaction = ({ itemId }) => {
	// const [
	// 	loadingDetails,
	// 	 setLoadingDetails
	// ] = useState(false);
	// const [searchKey, setSearchKey] = useState('');

	// const column = [
	// 	{
	// 		title: 'Transaction Id',
	// 		dataIndex: 'itemName',
	// 		key: 'itemName',
	// 		sorter: (a, b) => a?.itemName?.localeCompare(b?.itemName),
	// 		width: '70%',
	// 	},
	// ];

	return <span>Coming soon...</span>;

	// return (
	// 	<TableComponent
	// 		loading={loadingDetails}
	// 		className="custom-table"
	// 		style={{ width: '100%' }}
	// 		rowKey={(record) => record._id}
	// 		dataSource={[]}
	// 		// title={() => (
	// 		// 	<Row align={'middle'} justify={'space-between'} gutter={[10, 10]}>
	// 		// 		<Col span={24}>
	// 		// 			<h5 style={{ textAlign: 'center' }}>Item Transactions</h5>
	// 		// 			<Input placeholder="Search" suffix={<AiOutlineSearch />} onChange={({ target: { value } }) => setSearchKey(value)} />
	// 		// 		</Col>
	// 		// 	</Row>
	// 		// )}
	// 		{...{
	// 			columns: column,
	// 		}}
	// 	/>
	// );
};

export default StockTransaction;
