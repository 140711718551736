import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Row, Col, Card } from "antd";
import { useReactToPrint } from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import { SERVER_IP } from "assets/Config";
import { getApi } from "redux/sagas/getApiDataSaga";
import { ACTIONS, API_STATUS } from "constants/app-constants";
import ComponentToPrint from "components/component-to-print";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import StocksListPresentational from "./stocks-list-presenatational";
import HighlightComponent from "components/HighlightComponent";

const initialPageSize = 20;
const intialPageSizeOptions = [10, 15, 20];

const StocksListFunctional = React.memo(() => {
	const stocks = useSelector((state) => state?.stocksRedux?.stocks);
	const globalRedux = useSelector((state) => state.globalRedux);
	const [state, setState] = useState({
		visible: false,
	});
	const componentRef = React.useRef();
	const [searchKey, setSearchKey] = useState("");
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedViewRow, setSelectedViewRow] = useState(null);
	const [tableData, setTableData] = useState(stocks);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [stockType, setStockType] = useState("STOCK");
	const dispatch = useDispatch();

	const isStock = stockType === "STOCK";

	const getStocks = useCallback(() => {
		dispatch(
			getApi(
				ACTIONS.GET_STOCKS,
				isStock
					? `${SERVER_IP}item?orgId=${globalRedux?.selectedOrganization?._id}`
					: `${SERVER_IP}stock?orgId=${globalRedux?.selectedOrganization?._id}`
			)
		);
	}, [dispatch, globalRedux?.selectedOrganization?._id, isStock]);

	const filteredData = useMemo(() => {
		if (searchKey === "") {
			return tableData;
		}
		return tableData.filter((item) => {
			return (
				(item?.itemId?.itemName || item?.itemName || "")
					?.toLowerCase()
					?.includes(searchKey.toLowerCase()) ||
				(item?.itemId?.barCode || item?.barCode || "")
					?.toLowerCase()
					?.includes(searchKey.toLowerCase())
			);
			// return (
			// 	(item?.itemId?.itemNumber || item?.itemNumber || '')?.toString()?.toLowerCase()?.includes(searchKey.toLowerCase()) ||
			// 	(item?.itemId?.itemName || item?.itemName || '')?.toLowerCase()?.includes(searchKey.toLowerCase()) ||
			// 	(item?.itemId?.itemCode || item?.itemCode || '')?.toLowerCase()?.includes(searchKey.toLowerCase()) ||
			// 	(item?.itemId?.hsnSac || item?.hsnSac || '')?.toLowerCase()?.includes(searchKey.toLowerCase()) ||
			// 	(item?.itemId?.unitId || item?.unitId?.unitName || '')
			// 		?.toLowerCase()
			// 		.includes(searchKey.toLowerCase())(item?.status || '')
			// 		?.toLowerCase()
			// 		.includes(searchKey.toLowerCase())
			// );
		});
	}, [tableData, searchKey]);

	const stockColumn = [
		{
			title: "Item Name",
			dataIndex: "itemName",
			key: "itemName",
			sorter: (a, b) => a?.itemName?.localeCompare(b?.itemName),
			render: (value, record) => {
				return {
					props: {
						style: {
							background:
								selectedRow?._id === record?._id ? "rgb(227 239 255)" : "",
						},
					},
					children: (
						<Row
							onClick={() => handleSelectRow(record)}
							style={{
								cursor: "pointer",
							}}
						>
							<Col span={24}>
								<div
									style={{
										// fontSize: '1rem',
										fontWeight: "bold",
										color: "#66a4f7",
									}}
								>
									<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
								</div>
								{record?.itemCode && (
									<div
										style={{
											fontSize: 12,
											fontWeight: "bold",
										}}
									>
										{record?.itemCode || ""}
									</div>
								)}
							</Col>
						</Row>
					),
				};
			},
			width: "70%",
		},
		{
			title: 'Barcode',
			dataIndex: 'barCode',
			sorter: (a, b) => a.barCode?.localeCompare(b.barCode),
			align: 'left',
			width: '20%',
			render: (value, record) => {
				return {
					props: {
						style: {
							background:
								selectedRow?._id === record?._id ? "rgb(227 239 255)" : "",
						},
					},
					children: <HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />,
				};
			},
		},
		{
			title: "Stock",
			dataIndex: "currentStock",
			width: "30%",
			sorter: (a, b) => a?.currentStock - b?.currentStock,
			align: "center",
			render: (value, record) => {
				return {
					props: {
						style: {
							background:
								selectedRow?._id === record?._id ? "rgb(227 239 255)" : "",
						},
					},
					children: value,
				};
			},
		},
	];

	const column = useMemo(
		() => [
			{
				title: '#',
				dataIndex: 'itemNumber',
				sorter: (a, b) => a?.itemNumber - b?.itemNumber,
				align: 'center',
				fixed: 'left',
			},
			{
				title: 'Barcode',
				dataIndex: 'barCode',
				sorter: (a, b) => a.barCode?.localeCompare(b.barCode),
				align: 'left',
				render: (value) => (
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={value?.toString()}
					/>
				),
			},
			{
				title: 'Item Name',
				dataIndex: 'itemName',
				align: 'left',
				width: '20%',
				sorter: (a, b) => a.itemName?.localeCompare(b.itemName),
				render: (value) => (
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={value?.toString()}
					/>
				),
			},
			{
				title: 'Category',
				dataIndex: 'categoryId',
				align: 'left',
				width: '20%',
				sorter: (a, b) => a.categoryId?.categoryName?.localeCompare(b.categoryId?.categoryName),
				render: (value) => (
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={value?.categoryName?.toString()}
					/>
				),
			},
			{
				title: 'HsnSac',
				dataIndex: 'hsnSac',
				align: 'left',
				render: (value) => (
					<HighlightComponent
						highlightClassName="highlightClass"
						searchWords={[searchKey]}
						autoEscape={true}
						textToHighlight={value?.toString()}
					/>
				),
			},
			{
				title: 'MRP',
				dataIndex: 'mrp',
				align: 'right',
				width: '10%',
				sorter: (a, b) => a?.mrp - b?.mrp,
				render: (value) => parseFloat(value || 0).toFixed(2),
			},
			{
				title: 'Rate',
				dataIndex: 'rate',
				align: 'right',
				sorter: (a, b) => a?.rate - b?.rate,
				render: (value) => parseFloat(value || 0).toFixed(2),
			},
			{
				title: 'Tax %',
				dataIndex: 'taxRate',
				sorter: (a, b) => a?.rate - b?.rate,
				align: 'right',
			},
			{
				title: 'Stock',
				dataIndex: 'currentStock',
				sorter: (a, b) => a?.rate - b?.rate,
				align: 'right',
			},
		],
		[dispatch, globalRedux.selectedOrganization._id, searchKey]
	);

	const exportData = useMemo(() => {
		const data = {
			headers: column?.slice(0, column.length - 1)?.map((col) => col?.title) || [],
			data: [
				...(filteredData.map((item, index) => ({
					[column[0]?.title]: index + 1,
					[column[1]?.title]: item?.barCode || '-',
					[column[2]?.title]: item?.itemName || '-',
					[column[3]?.title]: item?.categoryId?.categoryName || '-',
					[column[4]?.title]: item?.hsnSac || '-',
					[column[5]?.title]: parseFloat(item?.mrp).toFixed(2) || '-',
					[column[6]?.title]: parseFloat(item?.rate).toFixed(2) || '-',
					[column[7]?.title]: item?.taxRate || '-',
					[column[8]?.title]: item?.currentStock || '-',
				})) || []),
			],
		};
		return data;
	}, [filteredData, column]);

	const serialColumn = [
		{
			title: "Item Name",
			dataIndex: "itemId",
			key: "itemId",
			sorter: (a, b) => a?.itemId?.itemName?.localeCompare(b?.itemId?.itemName),
			// render: (value) => (
			// 	<HighlightComponent
			// 		highlightClassName="highlightClass"
			// 		searchWords={[searchKey]}
			// 		autoEscape={true}
			// 		textToHighlight={value?.itemName || ''}
			// 	/>
			// ),
			render: (itemId, record) => (
				<Row>
					<Col>
						<Card title={itemId?.itemName}>{itemId?.itemName}</Card>
					</Col>
				</Row>
			),
			width: "20%",
		},
		// {
		// 	title: 'Barcode',
		// 	dataIndex: 'itemId',
		// 	sorter: (a, b) => a.itemId?.itemCode?.localeCompare(b.itemId?.itemCode),
		// 	align: 'left',
		// 	width: '15%',
		// 	render: (value) => (
		// 		<HighlightComponent
		// 			highlightClassName="highlightClass"
		// 			searchWords={[searchKey]}
		// 			autoEscape={true}
		// 			textToHighlight={value?.itemCode || ''}
		// 		/>
		// 	),
		// },
		// {
		// 	title: 'HsnSac',
		// 	dataIndex: 'itemId',
		// 	align: 'left',
		// 	width: '10%',
		// 	render: (value) => (
		// 		<HighlightComponent
		// 			highlightClassName="highlightClass"
		// 			searchWords={[searchKey]}
		// 			autoEscape={true}
		// 			textToHighlight={value?.hsnSac || ''}
		// 		/>
		// 	),
		// },
		// {
		// 	title: 'Purchase Bill',
		// 	dataIndex: 'purchaseId',
		// 	width: '10%',
		// 	render: (value) => (
		// 		<HighlightComponent
		// 			highlightClassName="highlightClass"
		// 			searchWords={[searchKey]}
		// 			autoEscape={true}
		// 			textToHighlight={value?.billNumber || ''}
		// 		/>
		// 	),
		// },
		// {
		// 	title: 'Serial',
		// 	dataIndex: 'serial',
		// 	width: '10%',
		// 	render: (value) => (
		// 		<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
		// 	),
		// },
		// {
		// 	title: 'Status',
		// 	dataIndex: 'status',
		// 	width: '10%',
		// 	render: (value) => (value ? <Tag color={STATUS[value]}>{value}</Tag> : null),
		// },
	];

	// eslint-disable-next-line
	const handleSelectRow = (row) => {
		setSelectedRow(row);
	};

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1)
			return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnWidth: "5%",
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	useEffect(() => {
		getStocks();
	}, [getStocks, stockType]);

	useEffect(() => {
		setTableData(stocks);
	}, [stocks]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "Receipt",
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	useEffect(() => {
		selectedRow &&
			setState((state) => ({
				...state,
				visible: true,
			}));
	}, [selectedRow]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_ORDER === "SUCCESS") {
			dispatch(resetApiStatus("DELETE_ORDER"));
			doIt = true;
		}
		if (doIt) {
			getStocks();
		}
	}, [globalRedux.apiStatus, getStocks, dispatch]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const tableLoading = useMemo(
		() => globalRedux.apiStatus.GET_STOCKS === API_STATUS.PENDING,
		[globalRedux.apiStatus]
	);

	return (
		<>
			<div style={{ display: "none" }}>
				<ComponentToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<StocksListPresentational
				{...{
					column: isStock ? stockColumn : serialColumn,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					refreshList: getStocks,
					tableLoading,
					state,
					setState,
					selectedRow: selectedRow,
					setSearchKey,
					rowSelection,
					selectedRowKeys,
					selectedViewRow,
					setSelectedViewRow,
					stockType,
					setStockType,
					exportData
				}}
			/>
		</>
	);
});

export default StocksListFunctional;
