
function setPayments(data){
    return{
        type : "SET_PAYMENTS",
        payload : data
    }
}

export {  
    setPayments,
}