import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { SERVER_IP } from 'assets/Config';
import { getApi } from 'redux/sagas/getApiDataSaga';
import HighlightComponent from 'components/HighlightComponent';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { ACTIONS, API_STATUS, DATE_FORMAT } from 'constants/app-constants';
import InvoiceListPresentational from './invoice-list-presentational';
import InvoiceToPrint from './invoice-to-print';

const initialPageSize = 10;
const intialPageSizeOptions = [10, 15, 20];

const InvoiceListFunctional = React.memo(() => {
	const globalRedux = useSelector((state) => state?.globalRedux);
	const [selectedFile, setSelectedFile] = useState(null);
	const invoices = useSelector((state) => state?.invoiceRedux?.invoices);
	const [searchKey, setSearchKey] = useState('');
	const [tableData, setTableData] = useState(invoices);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const componentRef = React.useRef();
	const dispatch = useDispatch();

	const getInvoices = useCallback(() => {
		dispatch(getApi(ACTIONS.GET_INVOICES, `${SERVER_IP}invoice?orgId=${globalRedux.selectedOrganization._id}`));
	}, [dispatch, globalRedux.selectedOrganization._id]);

	useEffect(() => {
		getInvoices();
	}, [getInvoices]);

	useEffect(() => {
		let doIt = false;
		if (globalRedux.apiStatus.DELETE_INVOICE === 'SUCCESS') {
			dispatch(resetApiStatus('DELETE_INVOICE'));
			setSelectedRowKeys([]);
			doIt = true;
		}
		if (doIt) {
			getInvoices();
		}
	}, [globalRedux.apiStatus, dispatch, getInvoices]);

	const filteredData = useMemo(() => {
		if (searchKey === '') {
			return tableData;
		}
		return tableData.filter((record) => {
			return (
				(record?.customerId?.displayName || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.invoiceDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase()) ||
				(moment(record?.dueDate).format(DATE_FORMAT.DD_MM_YYYY)?.toString() || '')?.toLowerCase().includes(searchKey.toLowerCase())
			);
		});
	}, [tableData, searchKey]);

	const column = [
		{
			title: '#',
			dataIndex: 'invoiceNumber',
			key: 'invoiceNumber',
			width: '5%',
			align: 'center',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.toString()}
				/>
			),
		},
		{
			title: 'Customer Name',
			dataIndex: 'customerId',
			key: 'customerId',
			width: '10%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={value?.displayName || ''}
				/>
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoiceDate',
			key: 'invoiceDate',
			width: '8%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
				/>
			),
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			width: '8%',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={moment(value).format(DATE_FORMAT.DD_MM_YYYY)}
				/>
			),
		},
		{
			title: 'Shipping Charges',
			dataIndex: 'shippingCharges',
			key: 'shippingCharges',
			width: '10%',
			align: 'right',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={parseFloat(value)?.toFixed(2) || ''} />
			),
		},
		{
			title: 'Inv Amount',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: '8%',
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={parseFloat(value)?.toFixed(2) || ''}
				/>
			),
		},
		{
			title: 'Balance Due',
			dataIndex: 'totalAmount',
			key: 'totalAmount',
			width: '8%',
			align: 'right',
			render: (value) => (
				<HighlightComponent
					highlightClassName="highlightClass"
					searchWords={[searchKey]}
					autoEscape={true}
					textToHighlight={parseFloat(value)?.toFixed(2) || ''}
				/>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
			align: 'center',
			render: (value) => (
				<HighlightComponent highlightClassName="highlightClass" searchWords={[searchKey]} autoEscape={true} textToHighlight={value || ''} />
			),
		},
		{
			title: 'File',
			dataIndex: 'pdfLink',
			key: 'pdfLink',
			width: '8%',
			align: 'center',
			render: (value, record) =>
				value ? (
					<Row justify="center">
						<Col
							className="edit_icon"
							onClick={(e) => {
								e.stopPropagation();
								setSelectedFile(SERVER_IP + value);
							}}>
							<EyeOutlined />
						</Col>
						<Col>
							<a
								onClick={(e) => {
									e.stopPropagation();
								}}
								href={`${SERVER_IP + value}`}
								download={`invoice_${moment().format(`${DATE_FORMAT.DD_MM_YYYY} h:mm A`)}_${moment().valueOf()}.pdf`}
								target="_blank"
								rel="noreferrer">
								<Col className="edit_icon">
									<DownloadOutlined />
								</Col>
							</a>
						</Col>
					</Row>
				) : null,
			// render: (value, row) => {
			// 	return (
			// 		<a
			// 			href={`${SERVER_IP + value}`}
			// 			download={`invoice_${moment().format(`${DATE_FORMAT.DD_MM_YYYY} h:mm A`)}_${moment().valueOf()}.pdf`}
			// 			target="_blank"
			// 			rel="noreferrer">
			// 			<Col className="edit_icon">
			// 				<DownloadOutlined />
			// 			</Col>
			// 		</a>
			// 	);
			// },
			// render: (value, row) => {
			// 	return (
			// 		<Row gutter={[10, 10]} style={{ paddingLeft: 10 }}>
			// 			<Col
			// 				onClick={() => {
			// 					const link = document.createElement('a');
			// 					link.download = `invoice_${moment().format(`${DATE_FORMAT.DD_MM_YYYY} h:mm A`)}_${moment().valueOf()}.pdf`;
			// 					link.href = SERVER_IP + value;
			// 					link.click();
			// 				}}
			// 				className="edit_icon">
			// 				<DownloadOutlined />
			// 			</Col>
			// 		</Row>
			// 	);
			// },
			// render: (value, row) => {
			// 	return (
			// 		<Row gutter={[10, 10]} style={{ paddingLeft: 10 }}>
			// 			<Col onClick={() => downloadFile(value)} className="edit_icon">
			// 				<DownloadOutlined />
			// 			</Col>
			// 		</Row>
			// 	);
			// },
		},
	];

	const handleTableChange = (currentPage, pageSize) => {
		setCurrentPage(currentPage === 0 ? 1 : currentPage);
		setPageSize(pageSize);
	};

	const getStartingValue = () => {
		if (currentPage === 1) return 1;
		else {
			return (currentPage - 1) * pageSize + 1;
		}
	};

	const getEndingValue = () => {
		if (currentPage === 1) return tableData.length < pageSize ? tableData.length : pageSize;
		else {
			let end = currentPage * pageSize;
			return end > tableData.length ? tableData.length : end;
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		columnWidth: '5%',
	};

	useEffect(() => {
		setTableData(invoices);
	}, [invoices]);

	const tableLoading = useMemo(() => globalRedux.apiStatus.GET_INVOICES === API_STATUS.PENDING, [globalRedux.apiStatus]);

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Voucher',
		removeAfterPrint: true,
		onAfterPrint: handleAfterPrint,
		// onBeforeGetContent: handleOnBeforeGetContent,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	return (
		<>
			<div style={{ display: 'none' }}>
				<InvoiceToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<InvoiceListPresentational
				{...{
					column,
					filteredData,
					handleTableChange,
					getStartingValue,
					getEndingValue,
					pageSize,
					intialPageSizeOptions,
					initialPageSize,
					currentPage,
					setSearchKey,
					refreshList: getInvoices,
					tableLoading,
					rowSelection,
					selectedInvoice,
					setSelectedInvoice,
					setSelectedRecordToPrint,
					selectedRowKeys,
					selectedFile,
					setSelectedFile,
				}}
			/>
		</>
	);
});

export default InvoiceListFunctional;
