import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getDateFormat } from "services/Utils";
import { DATE_FORMAT } from "constants/app-constants";
import "./sales-by-item-report-to-print.scss";

const SalesByItemReportReportToPrint = forwardRef((props, ref) => {
	const loginRedux = useSelector((state) => state.loginRedux);
	const { data } = props?.data || {};
	const globalRedux = useSelector((state) => state.globalRedux);
	// console.log("🚀 ~ file: sales-by-item-report-to-print.js:11 ~ SalesByItemReportReportToPrint ~ props?.data:", props?.data?.fromDate, props?.data?.toDate)
	// const { voucherHeads = [] } = globalRedux;
	// const voucherHead = voucherHeads?.find((voucherHead) => voucherHead?._id === voucherHeadId);

	return (
		<div className="sales-by-item-pdf-container" ref={ref}>
			<div
				style={{
					fontWeight: "bold",
					textAlign: "center",
					fontSize: "1rem",
					paddingBottom: 5,
				}}
			>
				{globalRedux?.selectedOrganization?.orgName || ""}
			</div>
			<div
				style={{
					fontWeight: "bold",
					textAlign: "center",
					fontSize: "1rem",
					paddingBottom: 5,
				}}
			>
				Sales By Items Report
			</div>
			<div
				style={{
					textAlign: "center",
					fontSize: "1rem",
					paddingBottom: 10,
				}}
			>
				From {moment(props?.data?.fromDate).format(DATE_FORMAT.DD_MM_YYYY)} to{" "}
				{moment(props?.data?.toDate).format(DATE_FORMAT.DD_MM_YYYY)}
			</div>
			<table cellPadding="0" cellSpacing="0">
				<tr>
					<td colSpan="3">
						<table border="1" className="top-heading">
							<tr
								style={{
									fontSize: "0.8rem",
									fontWeight: "bold",
								}}
							>
								<td colSpan="3" style={{ padding: 0 }}>
									<table>
										<tr>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderLeftWidth: 0,
													textAlign: "center",
													width: "10%",
												}}
											>
												S.No
											</td>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													textAlign: "left",
													paddingLeft: 10,
													width: "35%",
												}}
											>
												Item Name
											</td>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													textAlign: "center",
													width: "15%",
												}}
											>
												Barcode
											</td>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderRightWidth: 0,
													textAlign: "center",
													width: "11%",
												}}
											>
												<div style={{ paddingRight: 10 }}>Qty Sold</div>
											</td>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderRightWidth: 0,
													width: "12%",
												}}
											>
												<div
													style={{
														textAlign: "right",
														paddingRight: 5,
													}}
												>
													Avg. Price
												</div>
											</td>
											<td
												style={{
													fontWeight: "bold",
													borderTopWidth: 0,
													borderBottomWidth: 1,
													borderRightWidth: 0,
													width: "12%",
												}}
											>
												<div
													style={{
														textAlign: "right",
														paddingRight: 5,
													}}
												>
													Total Amt
												</div>
											</td>
										</tr>
										{(data || [])?.map((data, index) => (
											<tr>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														borderLeftWidth: 0,
														textAlign: "center",
														width: "10%",
													}}
												>
													{index + 1}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														textAlign: "left",
														paddingLeft: 10,
														width: "35%",
													}}
												>
													{data?.itemName || ""}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														textAlign: "center",
														width: "15%",
													}}
												>
													{data?.barCode || ""}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														borderRightWidth: 0,
														textAlign: "center",
														width: "11%",
													}}
												>
													{data?.totalQty || ""}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														borderRightWidth: 0,
														width: "12%",
														textAlign: "right",
														paddingRight: 5,
													}}
												>
													₹{parseFloat(data?.avgRate).toFixed(2) || ""}
												</td>
												<td
													style={{
														borderTopWidth: 0,
														borderBottomWidth: 0,
														borderRightWidth: 0,
														width: "12%",
														textAlign: "right",
														paddingRight: 5,
													}}
												>
													₹{parseFloat(data?.totalAmount).toFixed(2) || ""}
												</td>
											</tr>
										))}
										<tr>
											<td
												style={{
													borderBottomWidth: 0,
													borderLeftWidth: 0,
													textAlign: "right",
													paddingRight: 10,
												}}
												colSpan={4}
											>
												Total Qty
											</td>
											<td
												style={{
													borderBottomWidth: 0,
													borderRightWidth: 0,
													width: "15%",
													textAlign: "right",
													paddingRight: 5,
												}}
												colSpan={2}
											>
												{data?.reduce((acc, data) => acc + data?.totalQty, 0)}
											</td>
										</tr>
										<tr>
											<td
												style={{
													borderBottomWidth: 0,
													borderLeftWidth: 0,
													textAlign: "right",
													paddingRight: 10,
												}}
												colSpan={4}
											>
												Total Amount
											</td>
											<td
												style={{
													borderBottomWidth: 0,
													borderRightWidth: 0,
													width: "15%",
													textAlign: "right",
													paddingRight: 5,
												}}
												colSpan={2}
											>
												₹
												{parseFloat(
													data?.reduce(
														(acc, data) => acc + data?.totalAmount,
														0
													)
												).toFixed(2)}
											</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr
								style={{
									fontSize: "0.8rem",
									fontWeight: "bold",
								}}
							>
								<td style={{ width: "50%" }}>
									<div className="content" style={{}}>
										<div>
											Printed by {loginRedux?.firstName} {loginRedux?.lastName}
										</div>
									</div>
									{/* <div className="content" style={{}}>
										<div>Printed on {getDateFormat(moment())}</div>
									</div> */}
								</td>
								<td colSpan={"2"} style={{ width: "50%" }}>
									{/* <div
										className="content"
										style={{
											display: 'flex',
											fontSize: '1rem',
											justifyContent: 'space-between',
										}}>
										<div className="title" style={{ textAlign: 'right' }}>
											Total Credit
										</div>
										<div className="title" style={{ textAlign: 'right' }}>
											{totalCredit}
										</div>
									</div>
									<div
										className="content"
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											fontSize: '1rem',
										}}>
										<div className="title" style={{ textAlign: 'right' }}>
											Total Debit
										</div>
										<div className="title" style={{ textAlign: 'right' }}>
											{totalDebit}
										</div>
									</div> */}
									<div className="content" style={{}}>
										<div>Printed on {getDateFormat(moment())}</div>
									</div>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
});

export default SalesByItemReportReportToPrint;
