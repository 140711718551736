import React from "react"; // useState
import { AiOutlineSearch } from "react-icons/ai";
// import {
// 	Br,
// 	Cut,
// 	Line,
// 	Printer,
// 	render,
// 	Row,
// 	Text,
// } from 'react-thermal-printer';
import { Pagination, Row as AntdRow, Col, Button, Input } from "antd";
import moment from "moment";
import TableComponent from "components/table-component";
import AddBilling from "pages/billings/add-billing";
import DatePicker from "components/date-picker";
import ItemsModal from "./items-modal";
import { DATE_FORMAT } from "constants/app-constants";

const { RangePicker } = DatePicker;

const BillingsListPresentational = ({
	column,
	filteredData,
	handleTableChange,
	getStartingValue,
	getEndingValue,
	pageSize,
	intialPageSizeOptions,
	initialPageSize,
	currentPage,
	refreshList,
	tableLoading,
	state,
	setState,
	editData,
	setSearchKey,
	// rowSelection,
	selectedRowKeys,
	selectedViewRow,
	setSelectedViewRow,
	showPropsConfirm,
	buttonRef,
	handle,
	setDates,
}) => {
	// const receipt = (
	// 	<Printer type="epson" width={42} characterSet="korea" debug={true}>
	// 		<Text size={{ width: 2, height: 2 }}>9,500원</Text>
	// 		<Text bold={true}>결제 완료</Text>
	// 		<Br />
	// 		<Line />
	// 		<Row left="결제방법" right="체크카드" />
	// 		<Row left="카드번호" right="123456**********" />
	// 		<Row left="할부기간" right="일시불" />
	// 		<Row left="결제금액" right="9,500" />
	// 		<Row left="부가세액" right="863" />
	// 		<Row left="공급가액" right="8,637" />
	// 		<Line />
	// 		<Row left={<Text bold={true}>맛있는 옥수수수염차 X 2</Text>} right="11,000" />
	// 		<Text> 옵션1(500)/옵션2/"메모"</Text>
	// 		<Row left=" (-) 할인" right="- 500" />
	// 		<Br />
	// 		<Line />
	// 		<Row left={<Text bold={true}>합계</Text>} right={<Text underline="1dot-thick">9,500</Text>} />
	// 		<Row left="(-) 할인 2%" right="- 1,000" />
	// 		<Line />
	// 		<Row left="대표" right="김대표" />
	// 		<Row left="사업자등록번호" right="000-00-00000" />
	// 		<Row left="대표번호" right="0000-0000" />
	// 		<Row left="주소" right="어디시 어디구 어디동 몇동몇호" />
	// 		<Row
	// 			gap={1}
	// 			left={<Text size={{ width: 2, height: 2 }}>포</Text>}
	// 			center={<Text size={{ width: 2, height: 2 }}>알로하 포케 맛있는 거</Text>}
	// 			right="X 15"
	// 		/>
	// 		<Line />
	// 		<Br />
	// 		<Text align="center">Wifi: some-wifi / PW: 123123</Text>
	// 		<Cut />
	// 	</Printer>
	// );

	// const [port, setPort] = useState();

	// const print = async () => {
	// 	let _port = port;
	// 	if (_port == null) {
	// 		_port = await window.navigator.serial.requestPort();
	// 		console.log("🚀 ~ file: billings-list-presenatational.js:74 ~ print ~ _port:", _port)
	// 		await _port.open({ baudRate: 9600 });
	// 		setPort(_port);
	// 	}

	// 	const writer = _port.writable?.getWriter();
	// 	if (writer != null) {
	// 		const data = await render(receipt);

	// 		await writer.write(data);
	// 		writer.releaseLock();
	// 	}
	// };

	return (
		<AntdRow>
			{/* <Button onClick={print}>Print</Button> */}
			<Col span={24} style={{ padding: 20 }}>
				<TableComponent
					loading={tableLoading}
					// rowSelection={rowSelection}
					className="custom-table"
					style={{ width: "100%" }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<AntdRow justify="space-between">
							<Col span={12}>
								<AntdRow gutter={[10, 10]}>
									<Col span={24}>
										<AntdRow gutter={[10, 10]} align="middle">
											<Col span={8}>
												<Input
													placeholder="Search"
													suffix={<AiOutlineSearch />}
													onChange={({ target: { value } }) =>
														setSearchKey(value)
													}
												/>
											</Col>
											<Col span={16}>
												<RangePicker
													allowClear={false}
													format={[
														DATE_FORMAT.DD_MM_YYYY,
														DATE_FORMAT.DD_MM_YYYY,
													]}
													defaultValue={[moment(), moment()]}
													onChange={(dates) => {
														setDates({
															startDate: moment(
																dates[0],
																DATE_FORMAT.DD_MM_YYYY
															),
															endDate: moment(dates[1], DATE_FORMAT.DD_MM_YYYY),
														});
													}}
												/>
											</Col>
										</AntdRow>
									</Col>
									{selectedRowKeys?.length > 0 && (
										<>
											{/* <Col span={8}>
												<Select
													style={{ width: '100%' }}
													allowClear
													placeholder="Status"
													onChange={(value) => setSelectedStatus(value)}>
													{STATUS_DROPDOWN?.map((status) => (
														<Select.Option value={status}>{status}</Select.Option>
													))}
												</Select>
											</Col> */}
										</>
									)}
								</AntdRow>
							</Col>

							<Col>
								<Button
									ref={buttonRef}
									type="primary"
									// onClick={() =>
									// 	setState({
									// 		...state,
									// 		visible: true,
									// 	})
									// }
									onClick={() => {
										handle?.enter();
										setState({
											...state,
											visible: true,
										});
									}}
								>
									Create Billing
								</Button>
							</Col>
						</AntdRow>
					)}
					{...{
						columns: column,
						scroll: { x: "auto" },
						pagination: {
							current: currentPage,
							pageSize: pageSize,
							position: ["none", "none"],
						},
						...(!!filteredData?.length && {
							footer: () => (
								<AntdRow justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${
												filteredData?.length
											} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: "right" }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</AntdRow>
							),
						}),
					}}
				/>
			</Col>
			<AddBilling {...{ state, setState, refreshList, editData }} />
			<ItemsModal {...{ setSelectedViewRow, selectedViewRow }} />
		</AntdRow>
	);
};

export default BillingsListPresentational;
