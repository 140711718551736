import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button } from 'antd';
import moment from 'moment';
import useSound from 'use-sound';
import { useSelector } from 'react-redux';
import { FilePdfOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from 'constants/app-constants';
import boopSfx from '../assets/sounds/boop.mp3';

const SPACING = 10;

export const exportPDF = (data = [], headers = [], globalRedux, play, columnStyles = {}, headStyles = {}) => {
	const unit = 'pt';
	const size = 'A4'; // Use A1, A2, A3 or A4
	const orientation = 'portrait'; // portrait or landscape
	const marginLeft = SPACING;
	const doc = new jsPDF(orientation, unit, size);
	doc.setFontSize(15);
	const title = globalRedux?.selectedOrganization?.orgName || '';
	const head = [headers];
	const body = data.map((data) => headers?.map((header) => data?.[header]));
	// const body = data.map((data) => [
	// 	data?.[headers[0]],
	// 	data?.[headers[1]],
	// 	data?.[headers[2]],
	// 	data?.[headers[3]],
	// 	data?.[headers[4]],
	// 	data?.[headers[5]],
	// 	data?.[headers[6]],
	// 	data?.[headers[7]],
	// ]);
	let content = {
		startY: 50,
		head: head,
		body: body,
		styles: { fontSize: 9 },
		margin: { left: SPACING, right: SPACING },
		columnStyles: columnStyles,
		headStyles,
	};
	doc.text(title, marginLeft, 40);
	doc.autoTable(content);
	play?.()
	doc.save(`pdf_report_${moment().format(`${DATE_FORMAT.DD_MM_YYYY} h:mm A`)}_${moment().valueOf()}.pdf`);
};

const PdfDownload = ({ data = [], headers = [], columnStyles = {}, headStyles = {} }) => {
	const [play] = useSound(boopSfx);
	const globalRedux = useSelector((state) => state.globalRedux);

	if (data?.length > 0)
		return (
			<Button
				type="primary"
				style={{ width: '100%', background: '#25b395', borderColor: '#25b395' }}
				icon={<FilePdfOutlined />}
				onClick={() => exportPDF(data, headers, globalRedux, play, columnStyles, headStyles)}>
				Export as PDF
			</Button>
		);
	return null;
};

export default PdfDownload;
