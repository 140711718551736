import React, { useEffect } from 'react';
import { Input, Button, Divider, Form, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { putApi } from 'redux/sagas/putApiSaga';
import { SERVER_IP } from 'assets/Config';
import { API_STATUS } from 'constants/app-constants';
import { resetApiStatus } from 'redux/reducers/globals/globalActions';
import { postApi } from 'redux/sagas/postApiDataSaga';

const AddCategory = ({ handleClose, editCategories, setCategoryAddModal }) => {
	const [form] = Form.useForm();
	const globalRedux = useSelector((state) => state.globalRedux);
	const dispatch = useDispatch();

	const addCategory = (values) => {
		let data = {
			...values,
			// module: 'Project',
			orgId: globalRedux?.selectedOrganization?._id,
		};
		dispatch(postApi(data, 'ADD_CATEGORY'));
	};

	const handleEdit = (values) => {
		let data = {
			...values,
			// module: 'Project',
			orgId: globalRedux?.selectedOrganization?._id,
		};
		let url = `${SERVER_IP}category/${editCategories._id}?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(putApi(data, 'EDIT_CATEGORY', url));
	};

	useEffect(() => {
		if (globalRedux.apiStatus.ADD_CATEGORY === 'SUCCESS' || globalRedux.apiStatus.EDIT_CATEGORY === 'SUCCESS') {
			dispatch(resetApiStatus(editCategories ? 'EDIT_CATEGORY' : 'ADD_CATEGORY'));
			setCategoryAddModal(false);
			handleClose();
		}
	}, [globalRedux.apiStatus, editCategories, setCategoryAddModal, handleClose, dispatch]);

	const layer1FormCol = {
		labelCol: {
			span: 12,
		},
		wrapperCol: {
			span: 12,
		},
	};

	const loading = globalRedux.apiStatus.ADD_CATEGORY === API_STATUS.PENDING || globalRedux.apiStatus.EDIT_CATEGORY === API_STATUS.PENDING;

	return (
		<Row>
			<Col span={24}>
				<Row style={{ backgroundColor: '#fff', padding: '10px 0px' }}>
					<Col>
						<h6 style={{ marginBottom: '0px' }}>{editCategories ? 'Edit' : 'New'} Category</h6>
					</Col>
				</Row>
				<Divider />
				<Row style={{ paddingTop: 20 }}>
					<Col span={24}>
						<Form
							name="add-staff"
							style={{}}
							requiredMark={false}
							colon={false}
							labelAlign="left"
							form={form}
							onFinish={!editCategories ? addCategory : handleEdit}
							{...layer1FormCol}>
							<Form.Item
								label="Category Name"
								name="categoryName"
								initialValue={editCategories?.categoryName}
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}>
								<Input placeholder="Category Name" />
							</Form.Item>
							<Form.Item
								rules={[
									{
										required: true,
										message: 'This Field is Required!',
									},
								]}
								label="Margin %"
								name="profitMargin"
								initialValue={editCategories?.profitMargin}>
								<Input type="number" placeholder="Margin" />
							</Form.Item>
							<Form.Item
								wrapperCol={{
									span: 24,
								}}>
								<Row justify="space-between">
									<Button onClick={() => setCategoryAddModal(false)} style={{ width: '49%', marginRight: 5 }} danger>
										Cancel
									</Button>
									<Button loading={loading} type="primary" style={{ width: '49%' }} htmlType="submit">
										Create
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AddCategory;
