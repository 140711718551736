import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Form, Input } from "antd";
import { useHotkeys } from "react-hotkeys-hook";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { v4 as uuidv4 } from "uuid";
import { DeleteOutlined } from "@ant-design/icons";
import { SERVER_IP } from "assets/Config";
import { ACTIONS, API_STATUS } from "constants/app-constants";
import { resetApiStatus } from "redux/reducers/globals/globalActions";
import { putApi } from "redux/sagas/putApiSaga";
import moment from "moment";
import { getApi } from "redux/sagas/getApiDataSaga";
import { getTheRoundOffValue } from "helpers";
import AddCustomer from "pages/customers/add-customer";
import { sendGetRequest, sendPostRequest } from "redux/sagas/utils";
import AddItem from "pages/items/add-item";
import NewBillingPresentational from "./new-billing-presentational";
import BillingToPrint from "./retail-billing-print";

const MySwal = withReactContent(Swal);

export const calculatePurchaseValues = (data) => {
	// const discountAmount =
	// 	(data?.discount || 0) > 0 && (data?.discount || 0) !== ''
	// 		? ((data?.rate || 0) * data?.qty || 0) * ((data?.discount || 0) / 100)
	// 		: data?.discountAmount || 0;
	// const actualTotal = (data?.qty || 0) * (data?.rate || 0);
	// const taxableValue = parseFloat(actualTotal - discountAmount).toFixed(2);
	// const taxAmount = parseFloat((actualTotal - discountAmount) * (data?.taxRate / 100) || 0).toFixed(2);
	return {
		// actualTotal,
		// discountAmount: discountAmount,
		// taxableValue,
		// taxAmount,
		totalAmount: +data?.qty * +data?.rate,
	};
};

const NewBillingFunctional = ({ state, setState, refreshList, editData }) => {
	const [form] = Form.useForm();
	const [receivedAmountForm] = Form.useForm();
	const customerIdValue = Form.useWatch("customerId", form);
	const paymentModeValue = Form.useWatch("paymentMode", form);
	const receivedValue = Form.useWatch("received", receivedAmountForm);
	const cashValue = Form.useWatch("cash", receivedAmountForm);
	const gpayValue = Form.useWatch("gpay", receivedAmountForm);
	const cardValue = Form.useWatch("card", receivedAmountForm);
	const [receivedModal, setReceivedModal] = useState(false);
	const [, setIsPaid] = useState(false);
	const [billItemLoading, setBillItemLoading] = useState(false);
	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [discount, setDiscount] = useState("");
	const [selectedData, setSelectedData] = useState(null);
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [addingBilling, setAddingBilling] = useState(false);
	const [customerAddModal, setCustomerAddModal] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const items = useSelector((state) => state.itemRedux?.items || []);
	const [searchKey, setSearchKey] = useState("");
	const customers = useSelector(
		(state) => state.customerRedux?.customers || []
	);
	const componentRef = React.useRef();
	const dispatch = useDispatch();
	let qtyRefs = React.useRef([]);

	const inputRef = React.useRef(null);
	useHotkeys("ctrl + f", () => inputRef?.current?.focus());
	useHotkeys("ctrl + s", () => form.submit());
	useHotkeys("shift + f", () => qtyRefs.current[0]?.focus());
	useHotkeys("f5", () =>
		handleSubmit({
			...form?.getFieldsValue(),
		})
	);

	const showPropsConfirm = () => {
		MySwal.fire({
			title: "Payment Received?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: false,
			showConfirmButton: true,
			confirmButtonColor: "#25b394",
			cancelButtonColor: "#d33",
			confirmButtonText: "Submit",
			cancelButtonText: "Cancel",
			allowOutsideClick: false,
			html: `<input class="swal2-input" autofocus required id="currentPassword" type="text" placeholder="Enter amount" />`,
			inputValidator: (value) => {
				if (!value) {
					return "You need to write something!";
				}
			},
		}).then((result) => {
			if (!result.isConfirmed) {
				setIsPaid(true);
				setTimeout(() => {
					// form.submit();
				}, 100);
			} else {
				// form.submit();
			}
		});
	};

	const generateTableData = useCallback(
		(editData) => {
			if (editData._id) {
				form.setFieldsValue({
					customerId: editData?.customerId?._id,
					billDate: moment(editData?.billDate),
					deliveryDate: moment(editData?.deliveryDate),
					totalAmount: editData?.totalAmount,
					remarks: editData?.remarks,
				});
				setDiscount(editData?.discount);
				if (editData?.items?.length > 0) {
					const data = editData.items.map((item) => ({
						itemId: item?.itemId?._id || "",
						hsnSac: item?.hsnSac || "",
						qty: item?.qty || "",
						rate: item?.rate || "",
						totalAmount: item?.totalAmount || "",
						...calculatePurchaseValues(item),
						id: uuidv4(),
					}));
					setTableData(data);
				}
			}
		},
		[setTableData, form]
	);

	const getItems = useCallback(() => {
		let url = `${SERVER_IP}item?orgId=${globalRedux.selectedOrganization._id}`;
		dispatch(getApi("GET_ITEMS", url));
	}, [dispatch, globalRedux.selectedOrganization._id]);

	useEffect(() => {
		getItems();
	}, [getItems]);

	// useEffect(() => {
	// 	tableData?.length > 0 && qtyRefs.current[0]?.focus();
	// }, [qtyRefs, tableData?.length]);

	const handleArrow = (position, currentIndex) => {
		switch (position) {
			case "ArrowUp": {
				return qtyRefs.current[currentIndex - 1]?.focus();
			}
			case "ArrowDown": {
				return qtyRefs.current[currentIndex + 1]?.focus();
			}
			default:
				return state;
		}
	};

	const getCustomers = useCallback(() => {
		let url = `${SERVER_IP}customer?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi("GET_CUSTOMERS", url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getCustomers();
	}, [getCustomers]);

	const getItemsByBillId = useCallback(
		async (editData) => {
			await setBillItemLoading(true);
			const res = await sendGetRequest(
				null,
				`${SERVER_IP}bill/billitem?orgId=${globalRedux?.selectedOrganization?._id}&billId=${editData?._id}`
			);
			await generateTableData({
				...editData,
				items: res?.data?.data || [],
			});
			await setBillItemLoading(false);
		},
		[
			setBillItemLoading,
			generateTableData,
			globalRedux?.selectedOrganization?._id,
		]
	);

	useEffect(() => {
		if (!state?.visible) {
			form.resetFields();
			setTableData([]);
			setDiscount("");
		}
	}, [state?.visible, form]);

	useEffect(() => {
		if (
			globalRedux.apiStatus.ADD_BILLING === API_STATUS.SUCCESS ||
			globalRedux.apiStatus.EDIT_BILLING === API_STATUS.SUCCESS
		) {
			setState((state) => ({ ...state, visible: false }));
			form.resetFields();
			receivedAmountForm.resetFields();
			setIsPaid(false);
			dispatch(resetApiStatus(editData ? "EDIT_BILLING" : "ADD_BILLING"));
		}
		if (
			globalRedux.apiStatus.ADD_BILLING === API_STATUS.FAILED ||
			globalRedux.apiStatus.EDIT_BILLING === API_STATUS.FAILED
		) {
			setIsPaid(false);
		}
		!editData && setTableData([]);
	}, [
		globalRedux.apiStatus,
		editData,
		dispatch,
		setState,
		form,
		receivedAmountForm,
	]);

	useEffect(() => {
		if (editData) {
			// generateTableData(editData);
			getItemsByBillId(editData);
		}
	}, [editData, getItemsByBillId]);

	const loading =
		globalRedux.apiStatus.ADD_BILLING === API_STATUS.PENDING ||
		globalRedux.apiStatus.EDIT_BILLING === API_STATUS.PENDING ||
		addingBilling;

	const subTotal = useMemo(
		() =>
			tableData.reduce((accum, item) => accum + (item?.totalAmount || 0), 0) ||
			0,
		[tableData]
	);
	const totalQty = useMemo(
		() => tableData.reduce((accum, item) => accum + (item?.qty || 0), 0) || 0,
		[tableData]
	);
	const totalItem = useMemo(() => tableData?.length || 0, [tableData]);

	useEffect(() => {
		form.setFieldsValue({
			totalItem,
			totalQty,
		});
	}, [totalItem, totalQty, form]);
	const totalAmount = useMemo(
		() => tableData.reduce((accum, item) => +accum + +item.totalAmount, 0) || 0,
		[tableData]
	);
	const roundOff = getTheRoundOffValue(subTotal - discount);

	const getBillings = useCallback(() => {
		dispatch(
			getApi(
				ACTIONS.GET_BILLINGS,
				`${SERVER_IP}bill?orgId=${globalRedux?.selectedOrganization?._id}`
			)
		);
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	const handleSubmit = async (values) => {
		// console.log(
		// 	"🚀 ~ file: add-billing-functional.js:259 ~ handleSubmit ~ values:",
		// 	values
		// );
		const request = {
			orgId: globalRedux?.selectedOrganization?._id,
			customerId: customerIdValue,
			billDate: moment(),
			paymentStatus: "Paid",
			totalItems: totalItem,
			totalQty,
			subTotal,
			discount: discount || 0,
			// ...(cashValue && { cash: cashValue,}),
			// ...(gpayValue && { gpay: gpayValue,}),
			// ...(cardValue && { card: cardValue,}),
			cash: cashValue || 0,
			upi: gpayValue || 0,
			card: cardValue || 0,
			returned:
				parseFloat(
					Math.abs(
						(totalAmount || 0) -
							(eval(parseFloat(cashValue || 0).toFixed(2) || 0) +
								eval(parseFloat(gpayValue || 0).toFixed(2) || 0) +
								eval(parseFloat(cardValue || 0).toFixed(2) || 0))
					)
				).toFixed(2) || 0,
			balance: 0,
			totalAmount: parseFloat(roundOff.value).toFixed(2),
			paymentMode: values?.paymentMode,
			received: values?.received
				? parseInt(values?.received)
				: parseFloat(roundOff.value).toFixed(2),
			balance: Math.abs(
				values?.received ? roundOff.value - parseInt(values?.received) : 0
			),
			// ...(isPaid && {
			// 	paymentReceivedDate: moment(),
			// }),
			// roundOff: roundOff?.remain || 0,
			items: tableData
				.filter((data) => data._id)
				.map(({ _id, hsnSac, rate, qty, totalAmount }) => ({
					hsnSac,
					qty,
					rate: rate || 0,
					totalAmount,
					itemId: _id,
				})),
		};
		if (editData) {
			dispatch(
				putApi(request, "EDIT_BILLING", `${SERVER_IP}bill/${editData?._id}`)
			);
		} else {
			try {
				await setAddingBilling(true);
				const res = await sendPostRequest({
					url: `${SERVER_IP}bill`,
					body: request,
				});
				if (res?.data) {
					// setState((state) => ({ ...state, visible: false }));
					refreshList();
					getBillings();
					form.resetFields();
					receivedAmountForm.resetFields();
					setIsPaid(false);
					if (res?.data?.data?.billDetails?.[0]?.itemData) {
						setSelectedRecordToPrint({
							...res?.data?.data?.billDetails?.[0],
							items: res?.data?.data?.billDetails?.[0]?.itemData || [],
						});
						handlePrint();
					}
				}
				await setAddingBilling(false);
			} catch (err) {
				await setAddingBilling(false);
			}
		}
	};

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "Receipt",
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	const handleItemAdd = useCallback(
		(itemId) => {
			let item = null;
			let qty = 0;
			const alreadyAdded = tableData.find((data) => data?._id === itemId);
			if (alreadyAdded) {
				const updatedData = tableData?.map((data) => ({
					...data,
					...(data?._id === itemId && {
						qty: data?.qty + 1,
						totalAmount: data?.rate * (data?.qty + 1),
					}),
				}));

				setTableData([...updatedData]);
			} else {
				item = items.find((item) => item._id === itemId);
				let updatedObj = item;
				qty = 1;
				updatedObj["itemName"] = item?.itemName;
				updatedObj["hsnSac"] = item?.hsnSac;
				updatedObj["rate"] = item?.rate;
				updatedObj["qty"] = qty;
				updatedObj["totalAmount"] = item?.rate * qty;
				updatedObj["totalAmount"] = item?.rate * qty;
				// qtyRefs.current[tableData?.length] = React.createRef()
				setTableData([updatedObj, ...tableData]);
				qtyRefs.current[tableData?.length]?.focus();
			}
		},
		[tableData, items, qtyRefs]
	);

	const handleInputChange = useCallback(
		(label, value, rowId) => {
			// console.log(
			// 	"🚀 ~ file: add-billing-functional.js:336 ~ NewBillingFunctional ~ label, value, rowId:",
			// 	label,
			// 	value,
			// 	rowId
			// );
			let item = null;
			let qty = 0;
			if (label === "itemId") {
				item = items.find((item) => item._id === value);
				qty = 1;
			}
			const data = tableData.map((data) => {
				if (data._id === rowId) {
					// const taxAmount = item?.sellingPrice * qty * (item?.taxRate / 100) || 0;
					let updatedObj = data;
					updatedObj[label] = value;
					if (item) {
						updatedObj["hsnSac"] = item?.hsnSac;
						updatedObj["rate"] = item?.rate;
						updatedObj["qty"] = qty;
						updatedObj["totalAmount"] = item?.rate * qty;
					}
					updatedObj = {
						...updatedObj,
						...calculatePurchaseValues(updatedObj),
					};
					return updatedObj;
				} else {
					return data;
				}
			});
			setTableData([...data]);
		},
		[tableData, items]
	);

	const handleManualAdd = (e) => {
		if (e.key === "Enter") {
			const item = items.find((item) => item?.barCode === searchKey);
			const itemBasedOnName = items.find((item) =>
				(item?.itemName || "")?.toLowerCase().includes(searchKey.toLowerCase())
			);
			if (item) {
				handleItemAdd(item?._id);
				setSearchKey("");
			} else if (itemBasedOnName) {
				handleItemAdd(itemBasedOnName?._id);
				setSearchKey("");
			} else {
				alert("Item not found");
			}
		}
	};

	const groupData = useMemo(
		() =>
			_.chain(tableData?.filter((data) => data?.itemId))
				// Group the elements of Array based on `tax` property
				.groupBy("taxRate")
				// `key` is group's name (tax), `value` is the array of objects
				.map((value, key) => ({
					taxRate: key,
					data: value,
					taxAmount: value.reduce(function (acc, obj) {
						return +acc + +obj?.taxAmount;
					}, "0.00"),
					hsnSac: value[0]?.hsnSac,
					cgstPer: parseInt(key) / 2,
					cgstValue: parseFloat(
						value.reduce(function (acc, obj) {
							return +acc + +obj?.taxAmount;
						}, "0.00") / 2
					).toFixed(2),
					sgstPer: parseInt(key) / 2,
					sgstValue: parseFloat(
						value.reduce(function (acc, obj) {
							return +acc + +obj?.taxAmount;
						}, "0.00") / 2
					).toFixed(2),
					igstPer: "",
					igstValue: 0,
				}))
				.value(),
		[tableData]
	);

	const columns = [
		{
			title: "S No",
			dataIndex: "sno",
			key: "sno",
			width: 50,
			align: "center",
			render: (value, record, index) => <div>{index + 1}</div>,
		},
		{
			title: "Barcode",
			dataIndex: "barCode",
			key: "barCode",
			align: "left",
			width: 150,
		},
		{
			title: "Particulars",
			dataIndex: "itemName",
			key: "itemName",
			align: "left",
			width: 200,
		},
		{
			title: "Quantity",
			dataIndex: "qty",
			key: "qty",
			align: "right",
			width: 80,
			render: (value, record, index) =>
				record?.itemName ? (
					<Input
						name={`qty${index}`}
						pattern="^-?[0-9]\d*\.?\d*$"
						value={record?.qty}
						ref={(element) => {
							qtyRefs.current[index] = element;
						}}
						placeholder="qty"
						disabled={!record?.itemName}
						style={{ textAlign: "right", width: "100%" }}
						className={`quantity_input ${
							record?.itemId && !value ? "error" : ""
						}`}
						onChange={({ target: { value } }) => {
							const regex = /^-?\d*\.?\d*$/;
							if (!regex.test(value)) {
								// If the input does not match the pattern, clear the input field
								value = "";
							}
							handleInputChange("qty", parseFloat(value || 0), record?._id);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								inputRef?.current?.focus();
							}
							if (e.key === "ArrowUp") {
								handleArrow("ArrowUp", index);
							}
							if (e.key === "ArrowDown") {
								handleArrow("ArrowDown", index);
							}
						}}
					/>
				) : null,
		},
		{
			title: "Rate",
			dataIndex: "rate",
			key: "rate",
			align: "right",
			width: 100,
			render: (value, record) => parseFloat(value || 0).toFixed(2),
		},
		{
			title: "Total",
			dataIndex: "totalAmount",
			key: "totalAmount",
			align: "right",
			width: 100,
			render: (value, record) => parseFloat(value || 0).toFixed(2),
		},
		{
			title: "",
			dataIndex: "item",
			key: "item",
			align: "center",
			width: 50,
			render: (value, record) =>
				record?._id ? (
					<DeleteOutlined
						style={{ color: "red" }}
						onClick={() => handleRemove(record._id)}
					/>
				) : null,
		},
	];

	const handleRemove = (id) => {
		const data = tableData.filter((data) => data._id !== id);
		setTableData([...data]);
	};

	const { isValid } = useMemo(() => {
		const filledList = tableData
			?.map((data) => data.itemId)
			.filter((data) => data);
		if (tableData?.length === filledList?.length) {
			// handleAddTableData();
		}
		return {
			isValid: filledList?.length > 0,
		};
	}, [tableData]);

	return (
		<>
			<div style={{ display: "none" }}>
				<BillingToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<NewBillingPresentational
				{...{
					state,
					setState,
					handleSubmit,
					columns,
					loading,
					editData,
					form,
					customers,
					isValid,
					roundOff,
					setCustomerAddModal,
					handleInputChange,
					handleItemAdd,
					selectedData,
					setSelectedData,
					tableData,
					setTableData,
					groupData,
					discount,
					setDiscount,
					subTotal,
					totalQty,
					totalItem,
					billItemLoading,
					showPropsConfirm,
					items,
					inputRef,
					totalAmount,
					receivedAmountForm,
					receivedModal,
					setReceivedModal,
					receivedValue,
					paymentModeValue,
					handleManualAdd,
					searchKey,
					setSearchKey,
				}}
			/>
			<AddItem {...{ showAddItemModal, setShowAddItemModal }} />
			<AddCustomer
				{...{
					customerAddModal,
					setCustomerAddModal,
					refreshList: getCustomers,
					handleClose: () => setCustomerAddModal(false),
				}}
			/>
		</>
	);
};

export default NewBillingFunctional;
