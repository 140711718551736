import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col, Button, Form, Input, Select, Modal } from 'antd';
// import TableComponent from 'components/table-component';
import { DATE_FORMAT } from 'constants/app-constants';
import DatePicker from 'components/date-picker';

const layer1FormCol = {
	labelCol: {
		span: 9,
	},
	wrapperCol: {
		span: 15,
	},
};

const AdditionalReportNewPresentational = ({ getBillingItems, form, fetchingData, handleClear, categories, type, items, downloadURL }) => {
	const [modalVisible, setModalVisible] = useState(false);
	// const { voucherHeads = [] } = globalRedux;
	return (
		<Row>
			<Col span={24} style={{ padding: 20 }}>
				<Modal width={'90%'} title="Sales Report Preview" open={modalVisible} footer={null} onCancel={() => setModalVisible(false)}>
					<iframe src={downloadURL ? downloadURL : ''} style={{ width: '100%', height: '70vh' }} />
				</Modal>

				<Row gutter={[10, 10]}>
					<Col xl={12} md={12}>
						<Form
							labelAlign="left"
							name="unit-sections"
							className="required_in_right"
							form={form}
							onFinish={getBillingItems}
							{...layer1FormCol}>
							<Row gutter={[20, 20]}>
								{/* <Col xl={24} md={24}>
									<Radio.Group onChange={({ target: { value } }) => setType(value)} defaultValue="DATE" buttonStyle="solid">
										<Radio.Button value="DATE">Bill Date Wise</Radio.Button>
										<Radio.Button value="BILL">Bill Wise</Radio.Button>
									</Radio.Group>
								</Col> */}
								{type === 'BILL' ? (
									<Col xl={14} md={14}>
										<Row>
											<Col span={24}>
												<Form.Item
													label="Bill From"
													name="fromBill"
													rules={[
														{
															required: true,
															message: 'This Field is required!',
														},
													]}>
													<Input placeholder="Bill From" style={{ width: '100%' }} />
												</Form.Item>
											</Col>
											<Col span={24}>
												<Form.Item
													label="Bill To"
													name="toBill"
													rules={[
														{
															required: true,
															message: 'This Field is required!',
														},
													]}>
													<Input placeholder="Bill To" style={{ width: '100%' }} />
												</Form.Item>
											</Col>
										</Row>
									</Col>
								) : (
									<Col xl={14} md={14}>
										<Row>
											<Col span={24}>
												<Form.Item
													label="Category"
													name="categoryId"
													rules={[
														{
															required: false,
															message: 'This Field is required!',
														},
													]}>
													<Select allowClear placeholder="Select Category" style={{ width: '100%' }}>
														{categories?.map((category) => (
															<Select.Option key={category?._id} value={category?._id}>
																{category?.categoryName}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Form.Item
													label="Item"
													name="itemId"
													rules={[
														{
															required: false,
															message: 'This Field is required!',
														},
													]}>
													<Select
														allowClear
														showSearch
														placeholder="Select Item"
														optionFilterProp="children"
														filterOption={(input, option) =>
															option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
														}>
														{items.map((item) => (
															<Select.Option key={item._id} value={item._id}>
																{item.itemName}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Form.Item
													label="From Date"
													name="fromDate"
													initialValue={moment()}
													rules={[
														{
															required: true,
															message: 'This Field is required!',
														},
													]}>
													<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
												</Form.Item>
											</Col>
											<Col span={24}>
												<Form.Item
													label="To Date"
													name="toDate"
													initialValue={moment()}
													rules={[
														{
															required: true,
															message: 'This Field is required!',
														},
													]}>
													<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
												</Form.Item>
											</Col>
										</Row>
									</Col>
								)}
								<Col xl={24} md={24}>
									<Row gutter={[10, 0]}>
										<Col span={24}>
											<Row gutter={[10, 10]}>
												<Col span={8}>
													<Button style={{ width: '100%' }} loading={fetchingData} danger onClick={handleClear}>
														Clear
													</Button>
												</Col>
												<Col span={8}>
													<Button style={{ width: '100%' }} loading={fetchingData} type="primary" htmlType="submit">
														Submit
													</Button>
												</Col>
												{/* <Col span={8}>
													<Button
														onClick={() => setModalVisible(true)}
														style={{ width: '100%' }}
														loading={fetchingData}
														type="primary">
														View PDF
													</Button>
												</Col> */}
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				{/* <TableComponent
					loading={fetchingData}
					className="custom-table"
					style={{ width: '100%' }}
					rowKey={(record) => record._id}
					dataSource={filteredData}
					title={() => (
						<Row gutter={[10, 10]}>
							<Col xl={12} md={12}>
								<Form
									labelAlign="left"
									name="unit-sections"
									className="required_in_right"
									form={form}
									onFinish={getBillingItems}
									{...layer1FormCol}>
									<Row gutter={[20, 20]}>
										<Col xl={24} md={24}>
											<Radio.Group onChange={({ target: { value } }) => setType(value)} defaultValue="DATE" buttonStyle="solid">
												<Radio.Button value="DATE">Bill Date Wise</Radio.Button>
												<Radio.Button value="BILL">Bill Wise</Radio.Button>
											</Radio.Group>
										</Col>
										{type === 'BILL' ? (
											<Col xl={14} md={14}>
												<Row>
													<Col span={24}>
														<Form.Item
															label="Bill From"
															name="fromBill"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Input placeholder="Bill From" style={{ width: '100%' }} />
														</Form.Item>
													</Col>
													<Col span={24}>
														<Form.Item
															label="Bill To"
															name="toBill"
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<Input placeholder="Bill To" style={{ width: '100%' }} />
														</Form.Item>
													</Col>
												</Row>
											</Col>
										) : (
											<Col xl={14} md={14}>
												<Row>
													<Col span={24}>
														<Form.Item
															label="From Date"
															name="fromDate"
															initialValue={moment()}
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
														</Form.Item>
													</Col>
													<Col span={24}>
														<Form.Item
															label="To Date"
															name="toDate"
															initialValue={moment()}
															rules={[
																{
																	required: true,
																	message: 'This Field is required!',
																},
															]}>
															<DatePicker format={DATE_FORMAT.DD_MM_YYYY} style={{ width: '100%' }} />
														</Form.Item>
													</Col>
												</Row>
											</Col>
										)}
										<Col xl={24} md={24}>
											<Row gutter={[10, 0]}>
												<Col span={24}>
													<Row gutter={[10, 10]}>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} danger onClick={handleClear}>
																Clear
															</Button>
														</Col>
														<Col span={8}>
															<Button style={{ width: '100%' }} loading={fetchingData} type="primary" htmlType="submit">
																Submit
															</Button>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					)}
					{...{
						columns: column,
						pagination: { current: currentPage, pageSize: pageSize, position: ['none', 'none'] },
						...(!!filteredData?.length && {
							footer: () => (
								<Row justify="space-between">
									<Col md={16}>
										{!!filteredData?.length &&
											`Showing ${getStartingValue()} - ${getEndingValue()} of ${filteredData?.length} Data`}
									</Col>
									<Col md={8}>
										<div style={{ textAlign: 'right' }}>
											<Pagination
												pageSizeOptions={intialPageSizeOptions}
												defaultPageSize={initialPageSize}
												showSizeChanger={true}
												total={filteredData?.length}
												onChange={handleTableChange}
												responsive
											/>
										</div>
									</Col>
								</Row>
							),
						}),
					}}
				/> */}
			</Col>
		</Row>
	);
};

export default AdditionalReportNewPresentational;
