import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { SERVER_IP } from 'assets/Config';
import HighlightComponent from 'components/HighlightComponent';
import { API_STATUS, DATE_FORMAT, NOTIFICATION_STATUS_TYPES } from 'constants/app-constants';
import { sendGetRequest, sendPostRequest } from 'redux/sagas/utils';
import { downloadFileFromURL, formQueryStringFromObject, objToQs, showToast } from 'helpers';
import { getDateFormat } from 'services/Utils';
import { getApi } from 'redux/sagas/getApiDataSaga';
import AdditionalReportNewPresentational from './additional-report-new-list-presentational';
import AdditionalReportNewToPrint from './additional-report-new-to-print';

const DATA = {
	data: [
		{
			bills: [
				{
					billNumber: 2744,
					billDate: '2023-11-23',
					items: [
						{
							itemName: '7up 2.25 ltrs',
							qty: 1,
							unitRate: 99,
							totalAmount: 99,
							type: 'juice',
						},
					],
				},
				{
					billNumber: 2845,
					billDate: '2023-11-23',
					items: [
						{
							itemName: 'Slice 600ml',
							qty: 1,
							unitRate: 40,
							totalAmount: 40,
							type: 'juice',
						},
					],
				},
			],
			date: '2023-11-23',
		},
		{
			bills: [
				{
					billNumber: 2864,
					billDate: '2023-11-24',
					items: [
						{
							itemName: 'Pepsi 2.25ltrs',
							qty: 1,
							unitRate: 99,
							totalAmount: 99,
							type: 'juice',
						},
						{
							itemName: 'Mirinda 2.25ltrs',
							qty: 1,
							unitRate: 99,
							totalAmount: 99,
							type: 'juice',
						},
					],
				},
			],
			date: '2023-11-24',
		},
		{
			bills: [
				{
					billNumber: 3215,
					billDate: '2023-11-25',
					items: [
						{
							itemName: 'Marie gold 36.8G 168PK',
							qty: 2,
							unitRate: 5,
							totalAmount: 10,
							type: 'Biscuits',
						},
					],
				},
			],
			date: '2023-11-25',
		},
		{
			bills: [
				{
					billNumber: 3414,
					billDate: '2023-11-26',
					items: [
						{
							itemName: 'Marie gold 36.8G 168PK',
							qty: 1,
							unitRate: 5,
							totalAmount: 5,
							type: 'Biscuits',
						},
					],
				},
			],
			date: '2023-11-26',
		},
		{
			bills: [
				{
					billNumber: 3514,
					billDate: '2023-11-27',
					items: [
						{
							itemName: 'Slice 600ml',
							qty: 1,
							unitRate: 40,
							totalAmount: 40,
							type: 'juice',
						},
					],
				},
			],
			date: '2023-11-27',
		},
		{
			bills: [
				{
					billNumber: 3917,
					billDate: '2023-11-29',
					items: [
						{
							itemName: 'Slice 600ml',
							qty: 1,
							unitRate: 40,
							totalAmount: 40,
							type: 'juice',
						},
					],
				},
				{
					billNumber: 3918,
					billDate: '2023-11-29',
					items: [
						{
							itemName: 'Slice 600ml',
							qty: 1,
							unitRate: 40,
							totalAmount: 40,
							type: 'juice',
						},
					],
				},
			],
			date: '2023-11-29',
		},
		{
			bills: [
				{
					billNumber: 4284,
					billDate: '2023-12-01',
					items: [
						{
							itemName: 'Mirinda 2.25ltrs',
							qty: 1,
							unitRate: 99,
							totalAmount: 99,
							type: 'juice',
						},
					],
				},
			],
			date: '2023-12-01',
		},
		{
			bills: [
				{
					billNumber: 4837,
					billDate: '2023-12-04',
					items: [
						{
							itemName: 'Marie gold 36.8G 168PK',
							qty: 1,
							unitRate: 5,
							totalAmount: 5,
							type: 'Biscuits',
						},
					],
				},
			],
			date: '2023-12-04',
		},
		{
			bills: [
				{
					billNumber: 5725,
					billDate: '2023-12-08',
					items: [
						{
							itemName: 'Slice 600ml',
							qty: 1,
							unitRate: 40,
							totalAmount: 40,
							type: 'juice',
						},
					],
				},
				{
					billNumber: 5533,
					billDate: '2023-12-08',
					items: [
						{
							itemName: 'Mirinda 2.25ltrs',
							qty: 1,
							unitRate: 99,
							totalAmount: 99,
							type: 'juice',
						},
						{
							itemName: 'Pepsi 2.25ltrs',
							qty: 1,
							unitRate: 99,
							totalAmount: 99,
							type: 'juice',
						},
					],
				},
			],
			date: '2023-12-08',
		},
		{
			bills: [
				{
					billNumber: 5801,
					billDate: '2023-12-09',
					items: [
						{
							itemName: 'Marie gold 36.8G 168PK',
							qty: 1,
							unitRate: 5,
							totalAmount: 5,
							type: 'Biscuits',
						},
					],
				},
			],
			date: '2023-12-09',
		},
		{
			bills: [
				{
					billNumber: 6040,
					billDate: '2023-12-10',
					items: [
						{
							itemName: 'Slice 600ml',
							qty: 1,
							unitRate: 40,
							totalAmount: 40,
							type: 'juice',
						},
					],
				},
			],
			date: '2023-12-10',
		},
		{
			bills: [
				{
					billNumber: 6281,
					billDate: '2023-12-11',
					items: [
						{
							itemName: 'Marie gold 36.8G 168PK',
							qty: 1,
							unitRate: 5,
							totalAmount: 5,
							type: 'Biscuits',
						},
					],
				},
			],
			date: '2023-12-11',
		},
		{
			bills: [
				{
					billNumber: 6647,
					billDate: '2023-12-13',
					items: [
						{
							itemName: 'Slice 600ml',
							qty: 1,
							unitRate: 40,
							totalAmount: 40,
							type: 'juice',
						},
					],
				},
			],
			date: '2023-12-13',
		},
		{
			bills: [
				{
					billNumber: 6695,
					billDate: '2023-12-14',
					items: [
						{
							itemName: 'Marie gold 36.8G 168PK',
							qty: 2,
							unitRate: 5,
							totalAmount: 10,
							type: 'Biscuits',
						},
					],
				},
			],
			date: '2023-12-14',
		},
	],
};

const AdditionalReportNewFunctional = React.memo(() => {
	const [form] = Form.useForm();
	const [type, setType] = useState('DATE');
	const [fetchingData, setFetchingData] = useState(false);
	const globalRedux = useSelector((state) => state.globalRedux);
	const items = useSelector((state) => state.itemRedux?.items || []);
	const [state, setState] = useState({
		visible: false,
	});
	const componentRef = React.useRef();
	const [selectedRecordToPrint, setSelectedRecordToPrint] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const categories = useSelector((state) => state?.globalRedux.categories);
	const fromDateValue = Form.useWatch('fromDate', form);
	const toDateValue = Form.useWatch('toDate', form);
	const categoryIdValue = Form.useWatch('categoryId', form);
	const dispatch = useDispatch();

	const handleAfterPrint = () => {
		setSelectedRecordToPrint(null);
	};

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, []);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: 'Receipt',
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true,
	});

	useEffect(() => {
		selectedRecordToPrint && handlePrint();
	}, [selectedRecordToPrint, handlePrint]);

	useEffect(() => {
		selectedRow &&
			setState((state) => ({
				...state,
				visible: true,
			}));
	}, [selectedRow]);

	// useEffect(() => {
	// 	let doIt = false;
	// 	if (globalRedux.apiStatus.DELETE_BILLING === 'SUCCESS') {
	// 		dispatch(resetApiStatus('DELETE_BILLING'));
	// 		doIt = true;
	// 	}
	// 	if (doIt) {
	// 		getOrders();
	// 	}
	// }, [globalRedux.apiStatus, getOrders, dispatch]);

	useEffect(() => {
		!state?.visible && setSelectedRow(null);
	}, [state?.visible]);

	const downloadURL = useMemo(() => {
		const string = formQueryStringFromObject({
			fromDate: fromDateValue,
			toDate: toDateValue,
			categoryId: categoryIdValue,
		});
		return `${SERVER_IP}report/getsalesreport?orgId=${globalRedux?.selectedOrganization?._id}&${string}`;
	}, [fromDateValue, toDateValue, categoryIdValue]);

	const getBillingItems = useCallback(
		async (values) => {
			const string = formQueryStringFromObject({
				...(type === 'BILL'
					? {
							fromBill: values?.fromBill,
							toBill: values?.toBill,
					  }
					: {
							fromDate: values?.fromDate.format(DATE_FORMAT.YYYY_MM_DD),
							toDate: values?.toDate.format(DATE_FORMAT.YYYY_MM_DD),
							categoryId: values?.categoryId,
							itemId: values?.itemId,
					  }),
			});
			try {
				// await setFetchingData(true);
				// const res = await sendGetRequest(null, `${SERVER_IP}report/getsalesreport?orgId=${globalRedux?.selectedOrganization?._id}&${string}`);
				downloadFileFromURL(`${SERVER_IP}report/getsalesreport?orgId=${globalRedux?.selectedOrganization?._id}&${string}`);
				// handleDownload(res?.data);
				// console.log('🚀 ~ file: additional-report-list-functional.js:241 ~ res?.data:', res?.data);
				// download as excel
				// downloadBase64File(res?.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'report.xlsx');
				// download as xml
				// downloadBase64File(res?.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'report.xml');
				// downloadBase64File(res?.data, 'application/pdf', 'report.pdf');

				// await setTableData(res?.data || []);
				// await setFetchingData(false);
				// (await res?.data?.length) > 0
				// 	? setSelectedRecordToPrint({ ...form.getFieldsValue(), data: res?.data })
				// 	: showToast('Sorry!', 'No data found', NOTIFICATION_STATUS_TYPES.ERROR, 'top-center');
			} catch (error) {
				showToast('Sorry!', "Something went wrong. Couldn't fetch data.", NOTIFICATION_STATUS_TYPES.ERROR, 'top-center');
				// await setFetchingData(false);
			}
		},
		[setFetchingData, globalRedux?.selectedOrganization?._id, type, form]
	);

	const getItems = useCallback(() => {
		const string = objToQs({
			orgId: globalRedux.selectedOrganization._id,
			categoryId: categoryIdValue,
		});
		let url = `${SERVER_IP}item?${string}`;
		dispatch(getApi('GET_ITEMS', url));
	}, [dispatch, globalRedux.selectedOrganization._id, categoryIdValue]);

	useEffect(() => {
		getItems();
	}, [getItems, categoryIdValue]);

	const getCategories = useCallback(() => {
		let url = `${SERVER_IP}category/?orgId=${globalRedux?.selectedOrganization?._id}`;
		dispatch(getApi('GET_CATEGORIES', url));
	}, [dispatch, globalRedux?.selectedOrganization?._id]);

	useEffect(() => {
		getCategories();
	}, [getCategories]);

	const handleClear = () => {
		form.resetFields();
	};

	return (
		<>
			<div style={{ display: 'none' }}>
				<AdditionalReportNewToPrint ref={componentRef} data={selectedRecordToPrint} />
			</div>
			<AdditionalReportNewPresentational
				{...{
					getBillingItems,
					form,
					fetchingData,
					handleClear,
					categories,
					type,
					items,
					downloadURL,
				}}
			/>
		</>
	);
});

export default AdditionalReportNewFunctional;
